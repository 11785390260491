import React, { useRef, useState, useEffect } from "react";
import axios from "axios";
import { message, Form, Input, Button, Alert } from "antd";
import { Link, useNavigate } from "react-router-dom";
import Modal from "react-modal";
import { Headeronboarding } from "./Headeronboarding";
import { Footer } from "./Footer";
export const Onboarding = () => {
  const currentYear = new Date().getFullYear();
  const navigate = useNavigate();

  const [_OnboardDoc, set_OnboardDoc] = useState([]);
  const [valueUserDetail, setValuegetuser] = useState([]);
  const [onboarddoc, setonboarddoc] = useState(false);
  var appUrl = "https://jlmining.app/uploads";
  const handleLogout = () => {
    sessionStorage.removeItem("users");
    window.location.href = "/login";
  };
  const changeFavicon = (url) => {
    const favicon = document.querySelector('link[rel="icon"]');
    if (favicon) {
      favicon.href = url;
    }
  };

  useEffect(() => {
    changeFavicon(require("./assets/admin/images/f-icon.png"));
    const userStr = JSON.parse(localStorage.getItem("users"));
    if (userStr === null) {
      window.location.href = "/login";
    }
    document.title = "Send File Page";
    let formdata = {
      user_id: userStr[0].id,
    };
    axios
      .post("https://jlmining.app/admin/getuserdetails", formdata, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        var alldata = res.data.row[0];

        setValuegetuser(alldata);
      })
      .catch((err) => {});
    axios
      .post("https://jlmining.app/getonboardingDoc", formdata, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        var alldata = res.data.results;

        set_OnboardDoc(alldata);
      })
      .catch((err) => {});
  }, []);

  const opentab = () => {
    setonboarddoc(true);
  };
  const handleClick = (url, name) => {
    // Create a temporary anchor element
    const link = document.createElement("a");
    link.href = url;
    link.target = "_blank"; // Open in a new tab
    link.rel = "noopener noreferrer"; // Security best practice
    link.setAttribute("download", name);

    // Simulate a click on the anchor element
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  return (
    <>
      <Headeronboarding />
      <div className="fullscreen-onboarding-file h-100">
        <div className="container-lg">
          <div className="d-flex align-items-center justify-content-center mt-4 gap-4">
            <div className="d-block folder-onboarding">
              <Link
                className="d-flex flex-column justify-content-center align-items-center w-fit-content gap-2"
                to="javascript:void(0)"
                onClick={opentab}
              >
                <img
                  src={require("./assets/images/open-folder_5994725.png")}
                  width="100"
                  alt="Image"
                />
                <p className="text-dark">Onboarding Documents</p>
              </Link>
            </div>
            <div className="d-block folder-onboarding">
              <Link
                className="d-flex flex-column justify-content-center align-items-center w-fit-content gap-2"
                to="/onboardingfiles"
              >
                <img
                  src={require("./assets/images/12344676.png")}
                  width="100"
                  alt="Image"
                />
                <p className="text-dark">Upload Documents</p>
              </Link>
            </div>
          </div>
          {onboarddoc && (
            <div className="form-check d-flex flex-column gap-3 align-items-center border-bottom py-2">
              <h4>Onboarding Document</h4>
              {Array.isArray(_OnboardDoc) && _OnboardDoc.length > 0 ? (
                _OnboardDoc.map((item, index) => (
                  <Link
                    to="javascript:void(0)"
                    className="text-start"
                    onClick={() =>
                      handleClick(
                        `${appUrl}/${item.fname}_${item.folder_id}/${item.name}`,
                        `${item.name}`
                      )
                    }
                  >
                    <img
                      src={require("./assets/images/4347587.png")}
                      width="20"
                      alt="Image"
                    />{" "}
                    {item.name}
                    <img
                      src={require("./assets/images/4757733.png")}
                      width="20"
                      alt="Image"
                    />{" "}
                  </Link>
                ))
              ) : (
                <div>
                  <p>No data available</p>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      <Footer />
    </>
  );
};
