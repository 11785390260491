import React, { useRef, useState, useEffect } from "react";
import axios from "axios";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Header } from "../Header";
import { Footer } from "../Footer";
import { Leftmenu } from "./Leftmenu";
import "../assets/ohs/admin.css";
export const Lesson3 = () => {
  const stepTwo = useRef();
  const stepThree = useRef();
  const currentYear = new Date().getFullYear();
  const navigate = useNavigate();
  const [unicode, setunicode] = useState("");
  const [isCourse, setCourse] = useState("");
  const [step3btn_1, setstep3btn_1] = useState(true);
  const [step3btn_2, setstep3btn_2] = useState(false);
  const [step3btn_3, setstep3btn_3] = useState(false);
  const [step3section2, setstep3section2] = useState(false);
  const [step3section3, setstep3section3] = useState(false);
  const [step3section4, setstep3section4] = useState(false);
  const [istabSection, setistabSection] = useState([]);
  const { id } = useParams();
  const changeFavicon = (url) => {
    const favicon = document.querySelector('link[rel="icon"]');
    if (favicon) {
      favicon.href = url;
    }
  };

  useEffect(() => {
    const userStr = JSON.parse(localStorage.getItem("users"));

    if (userStr === null) {
      window.location.href = "/login";
    }
    changeFavicon(require("../assets/admin/images/f-icon.png"));

    document.title = "Start Course Lesson 3";
    if (id === undefined) {
      setunicode("");
      window.location.href = "/login";
    } else {
      setunicode(id);
    }
    let formdata = {
      code: id,
    };
    axios
      .post("https://jlmining.app/getcourseDetail", formdata, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        console.log(res.data);
        console.log("cor");
        if (res.data.row.length > 0) {
          setCourse(res.data.row[0]);
        }
      })
      .catch((err) => {});
    console.log(istabSection.length);
    if (istabSection.length === 3) {
      setstep3btn_3(true);
    }
  }, [id, istabSection]);
  const stepformlesson3 = (event) => {
    if (event === "Step1") {
      setstep3btn_1(false);
      setstep3btn_2(true);
      setstep3section2(true);
    }
    if (event === "Step2") {
      setstep3section3(true);
      setstep3btn_2(false);
    }
    if (event === "Step3") {
      setstep3section4(true);
      setstep3btn_3(false);
    }
    if (event === "Step4") {
      var lsteps = "Lesson_Step3";
      localStorage.setItem("LessonStep", JSON.stringify(lsteps));
      navigate(`/lesson4/${isCourse.slug}/${unicode}`);
    }
  };
  const tabSection = (event) => {
    if (event === "1") {
      if (!istabSection.includes("1")) {
        setistabSection((prevState) => [...prevState, "1"]);
      }
    }
    if (event === "2") {
      if (!istabSection.includes("2")) {
        setistabSection((prevState) => [...prevState, "2"]);
      }
    }
    if (event === "3") {
      if (!istabSection.includes("3")) {
        setistabSection((prevState) => [...prevState, "3"]);
      }
    }
  };
  return (
    <>
      <Header />
      <div className="d-flex main-admin-block align-items-stretch">
        <Leftmenu />
        <div className="flex-grow-1 rightpart">
          <section id="hum" className="human w-100">
            <div className="d-flex flex-column">
              <Link
                to="#legig"
                className="backpage text-center d-flex flex-column"
              >
                <i className="far fa-angle-up"></i>
                <h5>Lesson 2 Laws</h5>
              </Link>
            </div>
            <div className="container-xxl px-0">
              <div className="row">
                <div className="col-md-10 offset-md-1">
                  <div className="d-flex flex-column gap-3 padding py-5 lesson-block intro">
                    <h4>Lesson 3 of 9</h4>
                    <h1>Human Aspects</h1>
                    <div className="line"></div>
                  </div>
                </div>
                <div className="col-12">
                  <div className="border-line">
                    <div className="line-per"></div>
                  </div>
                </div>
                <div className="col-md-10 offset-md-1 padding">
                  <div className="d-flex flex-column gap-5 py-3 py-md-5">
                    <p>
                      The next section will examine the elements that influence
                      health and safety in the workplace:
                    </p>
                    <div className="d-flex flex-column gap-3">
                      <div className="d-flex align-items-center gap-5">
                        <div className="no">
                          <p>1</p>
                        </div>
                        <p>Personal factors.</p>
                      </div>
                      <div className="d-flex align-items-center gap-5">
                        <div className="no">
                          <p>2</p>
                        </div>
                        <p>Job-related factors.</p>
                      </div>
                      <div className="d-flex align-items-center gap-5">
                        <div className="no">
                          <p>3</p>
                        </div>
                        <p>Organizational factors.</p>
                      </div>
                    </div>
                  </div>
                  {step3btn_1 && (
                    <div
                      id="conti-btn"
                      onClick={() => stepformlesson3("Step1")}
                      className="d-flex py-4"
                    >
                      <button>CONTINUE</button>
                    </div>
                  )}
                </div>

                {step3section2 && (
                  <div className="col-md-10 offset-md-1 padding py-5 Enfo">
                    <div className="d-flex flex-column gap-2">
                      <h2>What Is The Difference?</h2>
                    </div>
                  </div>
                )}
                {step3section2 && (
                  <div id="humn" className="d-flex align-items-center px-5">
                    <div className="row">
                      <div className="col-md-6 py-4">
                        <img
                          className="object-fit-cover w-100 h-100"
                          src={require("../assets/ohs/images/se7.jpg")}
                          alt="img"
                        />
                      </div>
                      <div className="col-md-6 py-4">
                        <img
                          className="object-fit-cover w-100 h-100"
                          src={require("../assets/ohs/images/se8.jpg")}
                          alt="img"
                        />
                      </div>
                    </div>
                  </div>
                )}
                {step3section2 && (
                  <div
                    id="humn"
                    className="col-md-10 offset-md-1 padding py-5 enfo"
                  >
                    <div className="d-flex flex-column gap-3">
                      <h2>People Don't Always Follow Rules</h2>
                      <p>
                        Merely having rules, expectations, attitudes, or beliefs
                        doesn't ensure making the "safe choice."
                      </p>
                      <p>
                        For instance, numerous drivers might acknowledge driving
                        above the speed limit, but their behavior shifts when
                        they realize there are consequences for their actions.
                      </p>
                    </div>
                    {step3btn_2 && (
                      <div
                        id="conti-btn"
                        onClick={() => stepformlesson3("Step2")}
                        className="d-flex py-4"
                      >
                        <button>CONTINUE</button>
                      </div>
                    )}
                  </div>
                )}

                <div className="col-md-10 offset-md-1 padding py-5 Enfo">
                  {step3section3 && (
                    <div className="d-flex justify-content-center align-items-center bg-light">
                      <div
                        className="card card-1 p-0 shadow"
                        style={{ maxWidth: "100%" }}
                      >
                        <nav>
                          <div
                            className="nav nav-tabs mb-3 nav-fill"
                            id="nav-tab"
                            role="tablist"
                          >
                            <button
                              className="nav-link active"
                              id="nav-home-tab"
                              data-bs-toggle="tab"
                              data-bs-target="#nav-home"
                              type="button"
                              role="tab"
                              aria-controls="nav-home"
                              aria-selected="true"
                              onClick={() => tabSection("1")}
                            >
                              INDIVIDUAL
                            </button>
                            <button
                              className="nav-link"
                              id="nav-profile-tab"
                              data-bs-toggle="tab"
                              data-bs-target="#nav-profile"
                              type="button"
                              role="tab"
                              aria-controls="nav-profile"
                              aria-selected="false"
                              onClick={() => tabSection("2")}
                            >
                              JOB
                            </button>
                            <button
                              className="nav-link"
                              id="nav-contact-tab"
                              data-bs-toggle="tab"
                              data-bs-target="#nav-contact"
                              type="button"
                              role="tab"
                              aria-controls="nav-contact"
                              aria-selected="false"
                              onClick={() => tabSection("3")}
                            >
                              ORGANISATIONAL
                            </button>
                          </div>
                        </nav>
                        <div
                          className="tab-content p-4 bg-light"
                          id="nav-tabContent"
                        >
                          <div
                            className="tab-pane fade active show"
                            id="nav-home"
                            role="tabpanel"
                            aria-labelledby="nav-home-tab"
                          >
                            <p>
                              An individual's personality, skills, attitude, and
                              perception of risk all influence how they approach
                              a task.
                            </p>
                            <p>
                              Young workers (employees in Victoria), due to
                              their level of maturity and experience, are at
                              higher risk, necessitating additional precautions
                              compared to those for experienced and older
                              workers.
                            </p>
                            <div className="acc-img py-3">
                              <img
                                className="w-100"
                                src={require("../assets/ohs/images/young.jpg")}
                                alt="img"
                              />
                            </div>
                          </div>
                          <div
                            className="tab-pane fade"
                            id="nav-profile"
                            role="tabpanel"
                            aria-labelledby="nav-profile-tab"
                          >
                            <p>
                              The safety is also significantly influenced by the
                              nature of the task and the environment in which it
                              takes place. This encompasses factors such as the
                              time needed to complete the task, the workload of
                              the worker (an employee in Victoria), and the
                              utilization of machinery or controls.
                            </p>
                            <div className="acc-img py-3">
                              <img
                                className="w-100"
                                src={require("../assets/ohs/images/job.jpg")}
                                alt="img"
                              />
                            </div>
                          </div>
                          <div
                            className="tab-pane fade"
                            id="nav-contact"
                            role="tabpanel"
                            aria-labelledby="nav-contact-tab"
                          >
                            <p>
                              The safety culture within an organization is one
                              of the most influential factors affecting
                              workplace health and safety. Effective leadership,
                              clear communication, and a shared commitment to
                              safety from management to employees greatly
                              influence how the workforce will act and perform.
                            </p>
                            <div className="acc-img py-3">
                              <img
                                className="w-100"
                                src={require("../assets/ohs/images/org.jpg")}
                                alt="img"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {step3btn_3 && (
                    <div
                      id="conti-btn"
                      onClick={() => stepformlesson3("Step3")}
                      className="d-flex py-4"
                    >
                      <button>CONTINUE</button>
                    </div>
                  )}
                  {step3section4 && (
                    <div id="carouselExample" className="carousel slide py-5">
                      <div className="carousel-inner">
                        <div className="carousel-item active">
                          <div className="silder-box1 d-flex flex-column gap-2 justify-content-center align-items-center text-center gap-1">
                            <h5>Environmental Conditions</h5>
                            <p>
                              The surroundings where work takes place
                              significantly impact safety.
                            </p>
                            <p>
                              Explore the following sections to delve
                              deeperimage
                            </p>
                            <div className="ss1-btn">
                              <Link to="#">Start</Link>
                            </div>
                          </div>
                        </div>
                        <div className="carousel-item">
                          <div className="silder-box2">
                            <h1 className="py-2">Brightness Levels</h1>
                            <div className="d-flex justify-content-center">
                              <div className="caro-img">
                                <img
                                  src={require("../assets/ohs/images/low-brightness.jpg")}
                                  className="d-block w-100"
                                  alt="image"
                                />
                              </div>
                            </div>
                            <p className="py-2">
                              Lighting conditions can have a significant impact
                              on tasks being carried out. This could involve
                              working in dimly lit areas or during nighttime,
                              operating in excessively bright or glaring light,
                              driving towards the sun on a road, or continuously
                              viewing reflections on a control panel for
                              extended periods.
                            </p>
                          </div>
                        </div>
                        <div className="carousel-item">
                          <div className="silder-box2">
                            <h1 className="py-2">Temperature Conditions</h1>
                            <div className="d-flex justify-content-center">
                              <div className="caro-img">
                                <img
                                  src={require("../assets/ohs/images/temp.jpg")}
                                  className="d-block w-100"
                                  alt="image"
                                />
                              </div>
                            </div>
                            <p className="py-2">
                              Workplace temperatures can significantly influence
                              the health and safety of workers and affect work
                              performance.
                            </p>
                            <p className="pb-2">
                              High temperatures, whether indoors or outdoors,
                              can lead to heat stress and dehydration. On the
                              other hand, prolonged exposure to low temperatures
                              can cause hypothermia.
                            </p>
                          </div>
                        </div>
                        <div className="carousel-item">
                          <div className="silder-box2">
                            <h1 className="py-2">Noisy Conditions</h1>
                            <div className="d-flex justify-content-center">
                              <div className="caro-img">
                                <img
                                  src={require("../assets/ohs/images/noise.jpg")}
                                  className="d-block w-100"
                                  alt="image"
                                />
                              </div>
                            </div>
                            <p className="py-2">
                              Loud environments can be distracting, diverting
                              attention from nearby hazards. They may hinder
                              communication, making it difficult to exchange
                              important information and interfering with
                              emergency warnings.
                            </p>
                            <h1 className="py-2">Recap</h1>
                            <p>
                              Making sure you recognize and have pinpointed the
                              environmental factors that could impact your
                              ability to perform a task correctly is crucial for
                              ensuring safety.
                            </p>
                            <p className="py-2">
                              It's easy to overlook this aspect when completing
                              a task, but it's essential to identify all
                              hazards, assess them as needed, and implement
                              appropriate control measures to minimize risks.
                            </p>
                          </div>
                        </div>
                      </div>
                      <button
                        className="carousel-control-prev"
                        type="button"
                        data-bs-target="#carouselExample"
                        data-bs-slide="prev"
                      >
                        <span
                          className="carousel-control-prev-icon"
                          aria-hidden="true"
                        ></span>
                        <span className="visually-hidden">Previous</span>
                      </button>
                      <button
                        className="carousel-control-next"
                        type="button"
                        data-bs-target="#carouselExample"
                        data-bs-slide="next"
                      >
                        <span
                          className="carousel-control-next-icon"
                          aria-hidden="true"
                        ></span>
                        <span className="visually-hidden">Next</span>
                      </button>
                      <div id="conti-btn" className="d-flex py-4">
                        <button
                          type="button"
                          onClick={() => stepformlesson3("Step4")}
                        >
                          CONTINUE
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
      <Footer />
    </>
  );
};
