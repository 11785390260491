import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Header } from "../Header";
import { Footer } from "../Footer";
import "../assets/ohs/admin.css";
export const Leftmenu = ({ updateStrokeDashArray }) => {
  const currentYear = new Date().getFullYear();
  const navigate = useNavigate();
  const [strokeDashArray_less1, setStrokeDashArray_less1] = useState(
    updateStrokeDashArray
  );
  const [istruefalse_1, setistruefalse_1] = useState(false);
  const [unicode, setunicode] = useState("");
  const [isCourse, setCourse] = useState("");
  const { id } = useParams();
  const changeFavicon = (url) => {
    const favicon = document.querySelector('link[rel="icon"]');
    if (favicon) {
      favicon.href = url;
    }
  };

  useEffect(() => {
    const userStr = JSON.parse(localStorage.getItem("users"));

    if (userStr === null) {
      window.location.href = "/login";
    }
    changeFavicon(require("../assets/admin/images/f-icon.png"));

    document.title = "Start Course Lesson 1";
    if (id === undefined) {
      setunicode("");
      window.location.href = "/login";
    } else {
      setunicode(id);
    }
    let formdata = {
      code: id,
    };
    axios
      .post("https://jlmining.app/getcourseDetail", formdata, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        if (res.data.row.length > 0) {
          setCourse(res.data.row[0]);
        }
      })
      .catch((err) => {});
    setStrokeDashArray_less1(updateStrokeDashArray);
    const items = JSON.parse(localStorage.getItem("LessonStep"));
    console.log(items);
    if (items !== "" && items !== undefined) {
      var check_less = items;
      if (check_less === "Lesson_Step1") {
        //setistruefalse_1(true);
      }
    }
  }, [id, updateStrokeDashArray]);

  return (
    <>
      <div className="flex-shrink-0 side-layer">
        <div className="scroll-height">
          <div className="d-flex flex-column">
            <div className="top-title d-flex flex-column gap-5">
              <h1>
                Health & Safety
                <br />
                Awareness
              </h1>
              <div className="d-flex flex-column gap-1">
                <div className="progress-bar-full">
                  <div style={{ width: "30%" }}></div>
                </div>
                <h4 className="pro-number">
                  <span>30%</span> COMPLETE
                </h4>
              </div>
            </div>

            <div className="d-flex flex-column side-navbar mt-4">
              <Link
                to="admin-block.html"
                className="d-flex align-items-center gap-3 list-link anilink active"
              >
                <div className="flex-shrink-0">
                  <i className="far fa-align-left"></i>
                </div>
                <div className="flex-grow-1">
                  <h4>Inroduction</h4>
                </div>
                <div className="flex-shrink-0">
                  <div className="circle-small">
                    {istruefalse_1 && <i className="fas fa-check-circle"></i>}
                    {!istruefalse_1 && (
                      <svg>
                        <circle className="bg" cx="40" cy="40" r="37"></circle>
                        <circle
                          style={{ strokeDasharray: strokeDashArray_less1 }}
                          className="progress two lessonNum_1"
                          cx="40"
                          cy="40"
                          r="37"
                        ></circle>
                      </svg>
                    )}
                  </div>
                </div>
              </Link>
              <Link
                to="#!"
                className="d-flex align-items-center gap-3 list-link disable-box rel"
                data-bs-toggle="tooltip"
                data-bs-title="Lessons must be completed in order"
              >
                <div className="flex-shrink-0">
                  <i className="far fa-align-left"></i>
                </div>
                <div className="flex-grow-1">
                  <h4>Laws</h4>
                </div>
                <div className="flex-shrink-0">
                  <div className="circle-small">
                    {/* <i className="fas fa-check-circle"></i> */}
                    <svg>
                      <circle className="bg" cx="40" cy="40" r="37"></circle>
                      <circle
                        className="progress two"
                        cx="40"
                        cy="40"
                        r="37"
                      ></circle>
                    </svg>
                  </div>
                </div>
              </Link>
              <Link
                to="#!"
                className="d-flex align-items-center gap-3 list-link disable-box rel"
                data-bs-toggle="tooltip"
                data-bs-title="Lessons must be completed in order"
              >
                <div className="flex-shrink-0">
                  <i className="far fa-align-left"></i>
                </div>
                <div className="flex-grow-1">
                  <h4>Human Aspects</h4>
                </div>
                <div className="flex-shrink-0">
                  <div className="circle-small">
                    <svg>
                      <circle className="bg" cx="40" cy="40" r="37"></circle>
                      <circle
                        className="progress two"
                        cx="40"
                        cy="40"
                        r="37"
                      ></circle>
                    </svg>
                  </div>
                </div>
              </Link>
              <Link
                to="#!"
                className="d-flex align-items-center gap-3 list-link disable-box rel"
                data-bs-toggle="tooltip"
                data-bs-title="Lessons must be completed in order"
              >
                <div className="flex-shrink-0">
                  <i className="far fa-align-left"></i>
                </div>
                <div className="flex-grow-1">
                  <h4>Safety Signs</h4>
                </div>
                <div className="flex-shrink-0">
                  <div className="circle-small">
                    <svg>
                      <circle className="bg" cx="40" cy="40" r="37"></circle>
                      <circle
                        className="progress two"
                        cx="40"
                        cy="40"
                        r="37"
                      ></circle>
                    </svg>
                  </div>
                </div>
              </Link>
              <Link
                to="#!"
                className="d-flex align-items-center gap-3 list-link disable-box rel"
                data-bs-toggle="tooltip"
                data-bs-title="Lessons must be completed in order"
              >
                <div className="flex-shrink-0">
                  <i className="far fa-align-left"></i>
                </div>
                <div className="flex-grow-1">
                  <h4>Quiz at Mid-Point</h4>
                </div>
                <div className="flex-shrink-0">
                  <div className="circle-small">
                    <svg>
                      <circle className="bg" cx="40" cy="40" r="37"></circle>
                      <circle
                        className="progress two"
                        cx="40"
                        cy="40"
                        r="37"
                      ></circle>
                    </svg>
                  </div>
                </div>
              </Link>
              <Link
                to="#!"
                className="d-flex align-items-center gap-3 list-link disable-box rel"
                data-bs-toggle="tooltip"
                data-bs-title="Lessons must be completed in order"
              >
                <div className="flex-shrink-0">
                  <i className="far fa-align-left"></i>
                </div>
                <div className="flex-grow-1">
                  <h4>Workplace Incidents</h4>
                </div>
                <div className="flex-shrink-0">
                  <div className="circle-small">
                    <svg>
                      <circle className="bg" cx="40" cy="40" r="37"></circle>
                      <circle
                        className="progress two"
                        cx="40"
                        cy="40"
                        r="37"
                      ></circle>
                    </svg>
                  </div>
                </div>
              </Link>
              <Link
                to="#!"
                className="d-flex align-items-center gap-3 list-link disable-box rel"
                data-bs-toggle="tooltip"
                data-bs-title="Lessons must be completed in order"
              >
                <div className="flex-shrink-0">
                  <i className="far fa-align-left"></i>
                </div>
                <div className="flex-grow-1">
                  <h4>How to Report an Injury</h4>
                </div>
                <div className="flex-shrink-0">
                  <div className="circle-small">
                    <svg>
                      <circle className="bg" cx="40" cy="40" r="37"></circle>
                      <circle
                        className="progress two"
                        cx="40"
                        cy="40"
                        r="37"
                      ></circle>
                    </svg>
                  </div>
                </div>
              </Link>
              <Link
                to="#!"
                className="d-flex align-items-center gap-3 list-link disable-box rel"
                data-bs-toggle="tooltip"
                data-bs-title="Lessons must be completed in order"
              >
                <div className="flex-shrink-0">
                  <i className="far fa-align-left"></i>
                </div>
                <div className="flex-grow-1">
                  <h4>Some Common Workplace Hazards</h4>
                </div>
                <div className="flex-shrink-0">
                  <div className="circle-small">
                    <svg>
                      <circle className="bg" cx="40" cy="40" r="37"></circle>
                      <circle
                        className="progress two"
                        cx="40"
                        cy="40"
                        r="37"
                      ></circle>
                    </svg>
                  </div>
                </div>
              </Link>
              <Link
                to="#!"
                className="d-flex align-items-center gap-3 list-link disable-box rel"
                data-bs-toggle="tooltip"
                data-bs-title="Lessons must be completed in order"
              >
                <div className="flex-shrink-0">
                  <i className="far fa-align-left"></i>
                </div>
                <div className="flex-grow-1">
                  <h4>Final Assessment</h4>
                </div>
                <div className="flex-shrink-0">
                  <div className="circle-small">
                    <svg>
                      <circle className="bg" cx="40" cy="40" r="37"></circle>
                      <circle
                        className="progress two"
                        cx="40"
                        cy="40"
                        r="37"
                      ></circle>
                    </svg>
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
