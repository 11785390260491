import React, { useState, useEffect } from "react";
import axios from "axios";
import { message, Form, Input, Button, Alert } from "antd";
import { useNavigate, Link } from "react-router-dom";
import TextArea from "antd/es/input/TextArea";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import { FaTrash } from "react-icons/fa";
// import '@fullcalendar/core/main.css';
// import '@fullcalendar/daygrid/main.css';
import AdminHeader from "./AdminHeader";
import AdminFooter from "./AdminFooter";

export const AdminAddEquipmentwork = () => {
  const [isActive, setIsClicked] = useState(false);
  const [isActiveno, setIsClickedno] = useState(false);
  const [indx, setindx] = useState(null);
  const [emData, setData] = useState([]);
  const [timesheet, setDataTimesheet] = useState([]);
  const [empnamevalue, empname] = useState("");
  const [empstartdatevalue, empstartdate] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [isClickedbtn, setisClickedbtn] = useState(false);
  const [activeIndex, setActiveIndex] = useState(null);
  const [showAlert_r, setShowAlert_r] = useState(false);
  const [showPass, setShowPass] = useState(false);
  const navigate = useNavigate();
  const changeFavicon = (url) => {
    const favicon = document.querySelector('link[rel="icon"]');
    if (favicon) {
      favicon.href = url;
    }
  };
  const [values, setValues] = useState({});
  const [val, setVal] = useState("");
  const handleInput = (event) => {
    setVal((v) => (event.target.validity.valid ? event.target.value : v));
    setValues((prev) => ({
      ...prev,
      [event.target.name]: [event.target.value],
    }));
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    var vll = event.target;

    let formdata = {
      skills: vll.skills.value,
      tablename: "equipment_worked",
    };
    axios
      .post("https://jlmining.app/admin/addresults", formdata, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        console.log(res.data);
        if (res.data.status == "2") {
          setShowAlert(true);
          setShowAlert({ type: "error" });
        } else {
          setShowAlert(true);
          setShowAlert({ type: "success" });
          axios
            .get("https://jlmining.app/admin/getequpmentwork", {
              headers: {
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Methods": "POST,PUT",
                Accept: "application/json",
                "Content-Type": "application/json",
              },
            })
            .then((res) => {
              console.log(res.data.results);
              setData(res.data.results);
            })
            .catch((err) => {});
          event.target.reset();
          event.target.text();
        }
        setTimeout(() => {
          setShowAlert(false);
        }, 4500);
      })
      .catch((err) => {
        //console.log(err.response.data);
      });
  };
  useEffect(() => {
    changeFavicon(require("../assets/admin/images/f-icon.png"));
    document.title = "Equipment Work Page";
    const userStr = JSON.parse(localStorage.getItem("admin"));
    console.log(userStr);
    if (userStr == null) {
      navigate("/admin/login");
    }
    axios
      .get("https://jlmining.app/admin/getequpmentwork", {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        console.log(res.data.results);
        setData(res.data.results);
      })
      .catch((err) => {});
  }, []);

  //Get User Time Sheet

  const showhide = (e, index) => {
    setActiveIndex(index);
  };
  const removeskills = (e, index) => {
    let skilldata = {
      skill: e,
      fi: "equipment_worked",
      tablename: "equipment_worked",
    };

    axios
      .post("https://jlmining.app/admin/removedata", skilldata, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        if (res.data.status == 1) {
          setShowAlert_r(true);
          setShowAlert_r({ type: "success" });
          axios
            .get("https://jlmining.app/admin/getequpmentwork", {
              headers: {
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Methods": "POST,PUT",
                Accept: "application/json",
                "Content-Type": "application/json",
              },
            })
            .then((res) => {
              console.log(res.data.results);
              setData(res.data.results);
            })
            .catch((err) => {});
        } else {
          setShowAlert_r(true);
          setShowAlert_r({ type: "error" });
        }
      })
      .catch((err) => {});
  };
  return (
    <>
      <div>
        <AdminHeader />

        <div className="main-right-body">
          <div className="block-full">
            <div className="row gy-4">
              <div className="col-md-4">
                <div className="d-block red-heading bg-white shadow p-2">
                  {showAlert_r?.type === "error" && (
                    <Alert
                      type="error"
                      description="You don't have permission to delete this equipment work as it is currently in use by another user"
                      closable
                    />
                  )}
                  {showAlert_r?.type === "success" && (
                    <Alert
                      type="success"
                      description="Successfully deleted"
                      closable
                    />
                  )}
                  <h3 className="mb-3">Mention All Equipment Work skill</h3>
                  <div className="over-empl">
                    <div className="d-flex flex-column h-100 clientselectmain">
                      {Array.isArray(emData) ? (
                        emData.map((item, index) => (
                          <React.Fragment key={index}>
                            <div className="d-flex justify-content-between">
                              <Link
                                onClick={() => showhide(item.value, index)}
                                to="javascript:void(0)"
                              >
                                {item.value}
                              </Link>
                              {/* {isClickedbtn && ( */}
                              <button
                                type="button"
                                title="Delete"
                                onClick={() => removeskills(item.value, index)}
                                className={`removeskill text-danger ${
                                  activeIndex === index ? "" : "rem"
                                }`}
                              >
                                <FaTrash />
                              </button>
                              {/* )} */}
                            </div>
                          </React.Fragment>
                        ))
                      ) : (
                        <Link> No data available </Link>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-8">
                {showAlert?.type === "error" && (
                  <Alert
                    type="error"
                    description="Email is already exist"
                    closable
                  />
                )}
                {showAlert?.type === "success" && (
                  <Alert
                    type="success"
                    description="Successfully added"
                    closable
                  />
                )}
                <div className="right-form-box bg-white shadow p-2">
                  <form action="" onSubmit={handleSubmit} method="post">
                    <div className="row gy-2">
                      <div className="col-12 col-lg-4">
                        <div className="d-flex flex-column gap-2">
                          <label for="">
                            Add Equipment Work skill{" "}
                            <span className="text-danger fs-14">*</span>
                          </label>
                          <input
                            type="text"
                            required
                            onChange={handleInput}
                            placeholder="type here"
                            name="skills"
                          />
                        </div>
                      </div>

                      <div className="col-12">
                        <div className="d-flex align-items-center gap-3 justify-content-end">
                          <button className="sub-btn">add</button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <AdminFooter />
      </div>
    </>
  );
};
