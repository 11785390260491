import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import useKeyboardShortcutBlocker from "./KeyboardShortcutBlocker";
import { useNavigate, Link } from "react-router-dom";
import Slider from "react-slick";
import "./assets/css/banner.css";
export const Banner = () => {
  const currentYear = new Date().getFullYear();
  const navigate = useNavigate();

  const changeFavicon = (url) => {
    const favicon = document.querySelector('link[rel="icon"]');
    if (favicon) {
      favicon.href = url;
    }
  };
  useKeyboardShortcutBlocker();

  const audioRef = useRef(null); // Ref for the audio element

  // Function to handle user input changes

  // Function to start recording audio

  // Function to stop recording audio
  const [checklogin, setchecklogin] = useState(false);
  useEffect(() => {
    changeFavicon(require("./assets/admin/images/f-icon.png"));
    const userStr = JSON.parse(localStorage.getItem("users"));
    document.title = "Jlmining Page";
    if (userStr !== null) {
      setchecklogin(true);
      navigate("/home");
    }
  }, [checklogin]);
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true, // Enable auto-scrolling
    autoplaySpeed: 3000,
    arrows: false,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          arrows: false,
          centerMode: true,
          centerPadding: "0px",
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 480,
        settings: {
          arrows: false,
          centerMode: true,
          centerPadding: "0px",
          slidesToShow: 1,
        },
      },
    ],
  };
  var settings_2 = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    autoplay: true, // Enable auto-scrolling
    autoplaySpeed: 3000,
    arrows: false,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          arrows: false,
          centerMode: true,
          centerPadding: "0px",
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 480,
        settings: {
          arrows: false,
          centerMode: true,
          centerPadding: "0px",
          slidesToShow: 1,
        },
      },
    ],
  };
  return (
    <>
      <header style={{ width: "100%", left: "0", right: "0" }}>
        <style>
          {`
            ul.slick-dots {
              position: absolute;
              bottom: 4%;
            }
            .slick-dots li button:before {
              color: white !important;
              
            }
            .innertext {
                  display: flex;
                  flex-direction: column;
                  gap: 10px;
              }
              .overtext {
                  position: absolute;
                  inset: 0;
                  background: rgba(0,0,0,0.3);
                  display: grid;
                  place-items: center;
              }
              .overtext h1{
                text-align:center;
                  font-size: 30px;
                  color: #fff;
                  font-weight: 800;
              }
              
          
          `}
        </style>
        <div className="container-lg">
          <div className="row gy-4 align-items-center justify-content-between">
            <div className="col-3 col-md-2">
              <Link to="/" className="logo-top">
                <img src={require("./assets/images/logo2.png")} alt="logo" />
              </Link>
            </div>

            <div className="col-9 col-md-4">
              <div className="navbar-list align-items-center  d-flex gap-3 justify-content-end">
                {/* <Link to="/home">Home</Link> */}
                {!checklogin && (
                  <Link to="/login" className="">
                    Login
                  </Link>
                )}
                {!checklogin && <Link to="/register">Register</Link>}
                {checklogin && (
                  <Link to="/logout" className="btn btn-danger text-white">
                    Logout
                  </Link>
                )}
              </div>
            </div>
          </div>
        </div>
      </header>
      <section className="banner b-bg-white d-block px-md-0 px-3 ">
        <div className="container-fluid p-0 ">
          <Slider {...settings}>
            <div className="d-block herrobanner">
              <div className="overtext">
                <div className="innertext">
                  <h1>
                    “Discover the perfect skilled labor <br />{" "}
                    Service,instantly”
                  </h1>
                </div>
              </div>
              <img
                className="w-100 h-100 object-fit-cover"
                src={require("./assets/images/s1.JPG")}
                alt="image"
              />
            </div>
            <div className="d-block herrobanner">
              <div className="overtext">
                <div className="innertext">
                  <h1>
                    “Discover the perfect skilled labor <br />{" "}
                    Service,instantly”
                  </h1>
                </div>
              </div>
              <img
                className="w-100 h-100 object-fit-cover"
                src={require("./assets/images/s2.jpg")}
                alt="image"
              />
            </div>
            <div className="d-block herrobanner">
              <div className="overtext">
                <div className="innertext">
                  <h1>
                    “Discover the perfect skilled labor <br />{" "}
                    Service,instantly”
                  </h1>
                </div>
              </div>
              <img
                className="w-100 h-100 object-fit-cover"
                src={require("./assets/images/WhatsApp Image 2024-05-22 at 2.16.32 PM.jpeg")}
                alt="image"
              />
            </div>
            <div className="d-block herrobanner">
              <div className="overtext">
                <div className="innertext">
                  <h1>
                    “Discover the perfect skilled labor <br />{" "}
                    Service,instantly”
                  </h1>
                </div>
              </div>
              <img
                className="w-100 h-100 object-fit-cover"
                src={require("./assets/images/s4.JPG")}
                alt="image"
              />
            </div>
            <div className="d-block herrobanner">
              <div className="overtext">
                <div className="innertext">
                  <h1>
                    “Discover the perfect skilled labor <br />{" "}
                    Service,instantly”
                  </h1>
                </div>
              </div>
              <img
                className="w-100 h-100 object-fit-cover"
                src={require("./assets/images/s5.jpg")}
                alt="image"
              />
              {/* <span>
                “Discover the perfect skilled labor <br /> Service,instantly”{" "}
              </span> */}
            </div>
            <div className="d-block herrobanner">
              <div className="overtext">
                <div className="innertext">
                  <h1>
                    “Discover the perfect skilled labor <br />{" "}
                    Service,instantly”
                  </h1>
                </div>
              </div>
              <img
                className="w-100 h-100 object-fit-cover"
                src={require("./assets/images/s6.jpg")}
                alt="image"
              />
            </div>
            <div className="d-block herrobanner">
              <div className="overtext">
                <div className="innertext">
                  <h1>
                    “Discover the perfect skilled labor <br />{" "}
                    Service,instantly”
                  </h1>
                </div>
              </div>
              <img
                className="w-100 h-100 object-fit-cover"
                src={require("./assets/images/WhatsApp Image 2024-05-22 at 2.16.22 PM.jpeg")}
                alt="image"
              />
            </div>
            <div className="d-block herrobanner">
              <div className="overtext">
                <div className="innertext">
                  <h1>
                    “Discover the perfect skilled labor <br />{" "}
                    Service,instantly”
                  </h1>
                </div>
              </div>
              <img
                className="w-100 h-100 object-fit-cover"
                src={require("./assets/images/s8.jpg")}
                alt="image"
              />
            </div>
            <div className="d-block herrobanner">
              <div className="overtext">
                <div className="innertext">
                  <h1>
                    “Discover the perfect skilled labor <br />{" "}
                    Service,instantly”
                  </h1>
                </div>
              </div>
              <img
                className="w-100 h-100 object-fit-cover"
                src={require("./assets/images/WhatsApp Image 2024-05-22 at 2.15.35 PM.jpeg")}
                alt="image"
              />
            </div>
            <div className="d-block herrobanner">
              <div className="overtext">
                <div className="innertext">
                  <h1>
                    “Discover the perfect skilled labor <br />{" "}
                    Service,instantly”
                  </h1>
                </div>
              </div>
              <img
                className="w-100 h-100 object-fit-cover"
                src={require("./assets/images/s10.jpg")}
                alt="image"
              />
            </div>
            <div className="d-block herrobanner">
              <div className="overtext">
                <div className="innertext">
                  <h1>
                    “Discover the perfect skilled labor <br />{" "}
                    Service,instantly”
                  </h1>
                </div>
              </div>
              <img
                className="w-100 h-100 object-fit-cover"
                src={require("./assets/images/s11.jpg")}
                alt="image"
              />
            </div>
            <div className="d-block herrobanner">
              <div className="overtext">
                <div className="innertext">
                  <h1>
                    “Discover the perfect skilled labor <br />{" "}
                    Service,instantly”
                  </h1>
                </div>
              </div>
              <img
                className="w-100 h-100 object-fit-cover"
                src={require("./assets/images/s12.jpg")}
                alt="image"
              />
            </div>
            <div className="d-block herrobanner">
              <div className="overtext">
                <div className="innertext">
                  <h1>
                    “Discover the perfect skilled labor <br />{" "}
                    Service,instantly”
                  </h1>
                </div>
              </div>
              <img
                className="w-100 h-100 object-fit-cover"
                src={require("./assets/images/s13.jpg")}
                alt="image"
              />
            </div>
            <div className="d-block herrobanner">
              <div className="overtext">
                <div className="innertext">
                  <h1>
                    “Discover the perfect skilled labor <br />{" "}
                    Service,instantly”
                  </h1>
                </div>
              </div>
              <img
                className="w-100 h-100 object-fit-cover"
                src={require("./assets/images/s14.JPG")}
                alt="image"
              />
            </div>
            <div className="d-block herrobanner">
              <div className="overtext">
                <div className="innertext">
                  <h1>
                    “Discover the perfect skilled labor <br />{" "}
                    Service,instantly”
                  </h1>
                </div>
              </div>
              <img
                className="w-100 h-100 object-fit-cover"
                src={require("./assets/images/s15.jpeg")}
                alt="image"
              />
            </div>
            <div className="d-block herrobanner">
              <div className="overtext">
                <div className="innertext">
                  <h1>
                    “Discover the perfect skilled labor <br />{" "}
                    Service,instantly”
                  </h1>
                </div>
              </div>
              <img
                className="w-100 h-100 object-fit-cover"
                src={require("./assets/images/s16.jpg")}
                alt="image"
              />
            </div>
            <div className="d-block herrobanner">
              <div className="overtext">
                <div className="innertext">
                  <h1>
                    “Discover the perfect skilled labor <br />{" "}
                    Service,instantly”
                  </h1>
                </div>
              </div>
              <img
                className="w-100 h-100 object-fit-cover"
                src={require("./assets/images/WhatsApp Image 2024-05-22 at 2.18.27 PM.jpeg")}
                alt="image"
              />
            </div>
            <div className="d-block herrobanner">
              <div className="overtext">
                <div className="innertext">
                  <h1>
                    “Discover the perfect skilled labor <br />{" "}
                    Service,instantly”
                  </h1>
                </div>
              </div>
              <img
                className="w-100 h-100 object-fit-cover"
                src={require("./assets/images/WhatsApp Image 2024-05-22 at 2.18.44 PM.jpeg")}
                alt="image"
              />
            </div>
            <div className="d-block herrobanner">
              <div className="overtext">
                <div className="innertext">
                  <h1>
                    “Discover the perfect skilled labor <br />{" "}
                    Service,instantly”
                  </h1>
                </div>
              </div>
              <img
                className="w-100 h-100 object-fit-cover"
                src={require("./assets/images/WhatsApp Image 2024-05-22 at 2.19.14 PM.jpeg")}
                alt="image"
              />
            </div>
          </Slider>
        </div>
      </section>
      <footer className="d-block b-bg-white  px-md-0 px-3">
        <div className="container-fluid pb-3">
          <Slider {...settings_2} className="logo-slider smallimg mt-2">
            <div className="d-block logo-silder-w">
              <img
                className="h-100  object-fit-contain "
                style={{ width: "80%" }}
                src={require("./assets/k5/k1.png")}
                alt="image"
              />
            </div>
            <div className="d-block logo-silder-w">
              <img
                className="h-100 w-100 object-fit-contain"
                src={require("./assets/k5/k5.png")}
                alt="image"
              />
            </div>
            <div className="d-block logo-silder-w">
              <img
                className="h-100 w-100 object-fit-contain"
                src={require("./assets/k5/k2.png")}
                alt="image"
              />
            </div>

            <div className="d-block logo-silder-w">
              <img
                className="h-100 w-100 object-fit-contain"
                src={require("./assets/k5/k4.png")}
                alt="image"
              />
            </div>
            <div className="d-block logo-silder-w">
              <img
                className="h-100 w-100 object-fit-contain"
                src={require("./assets/k5/33a0f7_89e229a9db5b4c4e83f7e45f367be586~mv2.webp")}
                alt="image"
              />
            </div>
            <div className="d-block logo-silder-w">
              <img
                className="h-100 w-100 object-fit-contain"
                src={require("./assets/k5/Adobe_Express_2024-05-22_15.26.10-removebg-preview.png")}
                alt="image"
              />
            </div>
            <div className="d-block logo-silder-w">
              <img
                className="h-100 w-100 object-fit-contain"
                src={require("./assets/k5/minotaurlogo.png")}
                alt="image"
              />
            </div>
          </Slider>
        </div>
        <div className="ft-topbox d-block py-5">
          <div className="container-lg">
            <div className="row gy-4">
              <div className="col-md-3">
                <div className="d-flex flex-column gap-3 ft-boxes">
                  <h3>Contact us</h3>
                  <div className="d-flex flex-column gap-2 ft-links">
                    <p>Address: 8 Sparks Rd, Henderson WA 6166 </p>
                    <p>
                      <Link to="javascript:void(0)">Phone: +61 894375388</Link>{" "}
                    </p>
                    <p>Hours: 7:00am - 5:00pm Monday - Friday </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="d-flex flex-column gap-3 ft-boxes">
                  <h3>Help</h3>
                  <div className="row gy-4">
                    <div className="col-md-6">
                      <div className="d-flex flex-column gap-2 ft-links">
                        <p>
                          <Link to="/contact">Contact Information</Link>{" "}
                        </p>
                        <p>
                          <Link to="javascript:void(0)">How to Use</Link>{" "}
                        </p>
                        <p>
                          <Link to="/experthelp">Get Expert Help</Link>{" "}
                        </p>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="d-flex flex-column gap-2 ft-links">
                        <p>
                          <Link to="/terms&condition">
                            Terms and Conditions
                          </Link>{" "}
                        </p>
                        <p>
                          <Link to="/privacypolicy">Privacy Policy</Link>{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="d-flex flex-column gap-3 ft-boxes">
                  <h3>exprole us</h3>
                  <div className="d-flex flex-column gap-2 ft-links">
                    <p>
                      <Link to="https://www.hssonline.com.au" target="_blank">
                        www.hssonline.com.au
                      </Link>{" "}
                    </p>
                    <p>
                      <Link to="https://www.hssptyltd.com.au" target="_blank">
                        www.hssptyltd.com.au
                      </Link>{" "}
                    </p>
                    <p>
                      <Link to="https://www.jlme.com.au" target="_blank">
                        www.jlme.com.au{" "}
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="copy-ft py-2 text-center">
          <div className="container-lg">
            <p>
              Privacy | Site Terms | &copy; {currentYear}, JL Mining All rights
              reserved
            </p>
          </div>
        </div>
      </footer>
    </>
  );
};
