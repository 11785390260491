import React, { useState, useEffect } from "react";
import axios from "axios";
import { message, Form, Input, Button, Alert } from "antd";
import { Link, useNavigate } from "react-router-dom";
import TextArea from "antd/es/input/TextArea";
import AdminHeader from "./AdminHeader";
import AdminFooter from "./AdminFooter";
export const AdminClient = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [activeIndex, setActiveIndex] = useState(null);
  const [gdata, getData] = useState("");
  const [isClicked, setIsClicked] = useState(false);
  const [isClickedclient, setIsClickedclient] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [showAlerts, setShowAlerts] = useState(false);
  const handleuserRemove = (clientid) => {
    console.log(clientid);
    let client_id = {
      client_id: clientid,
    };
    const del = window.confirm("Are you sure you want to delete this client?");
    if (del) {
      axios
        .post("https://jlmining.app/admin/clientdelete", client_id, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          // setIsstaus(true);
          // setNostaus(false);

          if (res.data.status === 1) {
            setShowAlert(true);
            setShowAlert({ type: "error" });
            setIsClicked(false);
            setActiveIndex(null);
          } else {
            setShowAlerts(true);
            setShowAlerts({ type: "errors" });
          }
          axios
            .get("https://jlmining.app/admin/getclient", {
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
              },
            })
            .then((res) => {
              console.log(res.data.results);
              setData(res.data.results);
            })
            .catch((err) => {});
          setTimeout(() => {
            setShowAlert(false);
          }, 3500);
        })
        .catch((err) => {});
    }
  };
  const handlegetClient = (clientId, index) => {
    let formdata = {
      clientId: clientId,
    };
    setActiveIndex(index);
    axios
      .post("https://jlmining.app/admin/getclient", formdata, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        getData(res.data.row[0]);
        setIsClicked(true);
      })
      .catch((err) => {
        //console.log(err.response.data);
      });
  };
  const changeFavicon = (url) => {
    const favicon = document.querySelector('link[rel="icon"]');
    if (favicon) {
      favicon.href = url;
    }
  };
  useEffect(() => {
    changeFavicon(require("../assets/admin/images/f-icon.png"));
    document.title = "Client Page";
    const userStr = JSON.parse(localStorage.getItem("admin"));
    console.log(userStr);
    if (userStr == null) {
      navigate("/admin/login");
    }
    axios
      .get("https://jlmining.app/admin/getclient", {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        console.log(res.data.results);
        setData(res.data.results);
      })
      .catch((err) => {});
  }, []);

  const handleedit = (event) => {
    setIsClickedclient(!isClickedclient);
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    var vll = event.target;
    let formdata = {
      email: vll.email.value,
      client_id: vll.client_id.value,
      name: vll.name.value,
      position: vll.position.value,
      department: vll.department.value,
      phone_number: vll.phone_number.value,
      mobile_number: vll.mobile_number.value,
      home_phone_number: vll.home_phone_number.value,
      fax_number: vll.fax_number.value,
    };
    //console.log(formdata);
    axios
      .post("https://jlmining.app/admin/editclient", formdata, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        if (res.data.status == "2") {
          setShowAlert(true);
          setShowAlert({ type: "error" });
        } else {
          setShowAlert(true);
          setShowAlert({ type: "success" });
          axios
            .get("https://jlmining.app/admin/getclient", {
              headers: {
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Methods": "POST,PUT",
                Accept: "application/json",
                "Content-Type": "application/json",
              },
            })
            .then((res) => {
              setData(res.data.results);
              let formdataa = {
                clientId: formdata.client_id,
              };
              axios
                .post("https://jlmining.app/admin/getclient", formdataa, {
                  headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Methods": "POST,PUT",
                    Accept: "application/json",
                    "Content-Type": "application/json",
                  },
                })
                .then((res) => {
                  getData(res.data.row[0]);
                })
                .catch((err) => {
                  //console.log(err.response.data);
                });
            })
            .catch((err) => {});
        }
        setTimeout(() => {
          setShowAlert(false);
        }, 4500);
      })
      .catch((err) => {});
  };
  return (
    <>
      <div>
        <AdminHeader />
        <div className="main-right-body">
          <div className="block-full">
            <div className="row">
              <div className="float-end d-flex justify-content-end">
                <Link to="/admin/addclient" className="addclient">
                  Add Client
                </Link>
              </div>
            </div>
            <div className="row gy-4">
              <div className="col-md-4">
                {showAlert?.type === "error" && (
                  <Alert
                    type="error"
                    description="Client deleted successfully!"
                    closable
                  />
                )}
                {showAlerts?.type === "errors" && (
                  <Alert
                    type="error"
                    description="You have no permissions to delete this client, because this client use for roster"
                    closable
                  />
                )}
                <div className="d-block red-heading bg-white shadow p-2">
                  <h3 className="mb-3">Select Client</h3>
                  <div className="over-empl">
                    <div className="d-flex flex-column h-100 clientselectmain">
                      {Array.isArray(data) ? (
                        data.map((item, index) => (
                          <Link
                            to="javascript:void(0)"
                            className={`item ${
                              activeIndex === index ? "active" : ""
                            }`}
                            onClick={() => handlegetClient(item.id, index)}
                          >
                            {item.name}
                          </Link>
                        ))
                      ) : (
                        <Link> No data available </Link>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-8">
                {isClicked && (
                  <div className="right-form-box bg-white shadow p-2">
                    <div className="row gap-2">
                      <div className="col-12 col-lg-6">
                        <div className="d-flex justify-content-between p-2">
                          <label for="">Client Name:-</label>
                          <span className="fs-14">{gdata.name}</span>
                        </div>
                        <div className="d-flex justify-content-between p-2">
                          <label for="">Position:-</label>
                          <span className="fs-14">{gdata.position}</span>
                        </div>
                        <div className="d-flex justify-content-between p-2">
                          <label for="">Department:-</label>
                          <span className="fs-14">{gdata.department}</span>
                        </div>
                        <div className="d-flex justify-content-between p-2">
                          <label for="">Work Phone Number:-</label>
                          <span className="fs-14">{gdata.phone_number}</span>
                        </div>
                        <div className="d-flex justify-content-between p-2">
                          <label for="">Mobile Phone Number:-</label>
                          <span className="fs-14">{gdata.mobile_number}</span>
                        </div>
                        <div className="d-flex justify-content-between p-2">
                          <label for="">Emergency Contact Number:-</label>
                          <span className="fs-14">
                            {gdata.home_phone_number}
                          </span>
                        </div>
                        <div className="d-flex justify-content-between p-2">
                          <label for="">Fax Number:-</label>
                          <span className="fs-14">{gdata.fax_number}</span>
                        </div>
                        <div className="d-flex justify-content-between p-2">
                          <label for="">Email-Id:-</label>
                          <span className="fs-14">{gdata.email}</span>
                        </div>
                        <div className="d-flex justify-content-between p-2">
                          <Link
                            title="Delete"
                            to="javascript:void(0)"
                            className="addclient"
                            onClick={() => handleuserRemove(gdata.id)}
                          >
                            Delete
                          </Link>
                          <Link
                            title="Delete"
                            to="javascript:void(0)"
                            className="editclient"
                            onClick={() => handleedit(gdata.id)}
                          >
                            Edit
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {isClickedclient && (
                  <div className="right-form-box bg-white shadow p-2 mt-4">
                    <h4 className="mb-3">Update Client</h4>
                    {showAlert?.type === "error" && (
                      <Alert
                        type="error"
                        description="Email is already exist"
                        closable
                      />
                    )}
                    {showAlert?.type === "success" && (
                      <Alert
                        type="success"
                        description="Client has been successfully updated!"
                        closable
                      />
                    )}
                    <form action="" onSubmit={handleSubmit}>
                      <input
                        type="hidden"
                        name="client_id"
                        placeholder="type here"
                        Value={gdata.id}
                      />
                      <div className="row gy-2">
                        <div className="col-12 col-lg-6">
                          <div className="d-flex flex-column gap-2">
                            <label for="">Client Name </label>
                            <input
                              type="text"
                              name="name"
                              placeholder="type here"
                              Value={gdata.name}
                            />
                          </div>
                        </div>

                        <div className="col-12 col-lg-6">
                          <div className="d-flex flex-column gap-2">
                            <label for="">Position </label>
                            <input
                              type="text"
                              name="position"
                              placeholder="type here"
                              Value={gdata.position}
                            />
                          </div>
                        </div>
                        <div className="col-12 col-lg-6">
                          <div className="d-flex flex-column gap-2">
                            <label for="">Department </label>
                            <input
                              type="text"
                              name="department"
                              placeholder="type here"
                              Value={gdata.department}
                            />
                          </div>
                        </div>
                        <div className="col-12 col-lg-6">
                          <div className="d-flex flex-column gap-2">
                            <label for="">Work Phone Number </label>
                            <input
                              type="text"
                              pattern="[0-9]*"
                              minlength="10"
                              name="phone_number"
                              placeholder="type here"
                              Value={gdata.phone_number}
                            />
                          </div>
                        </div>
                        <div className="col-12 col-lg-6">
                          <div className="d-flex flex-column gap-2">
                            <label for="">Mobile Phone Number </label>
                            <input
                              type="text"
                              pattern="[0-9]*"
                              minlength="10"
                              name="mobile_number"
                              placeholder="type here"
                              Value={gdata.mobile_number}
                            />
                          </div>
                        </div>
                        <div className="col-12 col-lg-6">
                          <div className="d-flex flex-column gap-2">
                            <label for="">Emergency Contact Number </label>
                            <input
                              type="text"
                              pattern="[0-9]*"
                              minlength="10"
                              name="home_phone_number"
                              placeholder="type here"
                              Value={gdata.home_phone_number}
                            />
                          </div>
                        </div>
                        <div className="col-12 col-lg-6">
                          <div className="d-flex flex-column gap-2">
                            <label for="">Fax Number </label>
                            <input
                              type="text"
                              name="fax_number"
                              placeholder="type here"
                              Value={gdata.fax_number}
                            />
                          </div>
                        </div>
                        <div className="col-12 col-lg-6">
                          <div className="d-flex flex-column gap-2">
                            <label for="">
                              Email-Id{" "}
                              <span className="text-danger fs-14">*</span>
                            </label>
                            <input
                              type="email"
                              required
                              name="email"
                              placeholder="type here"
                              Value={gdata.email}
                            />
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="d-flex align-items-center gap-3 justify-content-end">
                            <button className="sub-btn" type="submit">
                              Update
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <AdminFooter />
      </div>
    </>
  );
};
