import React, { useState, useEffect } from "react";
import axios from "axios";
import { message, Form, Input, Button, Alert } from "antd";
import { Link, useNavigate } from "react-router-dom";
import TextArea from "antd/es/input/TextArea";
import AdminHeader from "./AdminHeader";
import AdminFooter from "./AdminFooter";
export const AdminAddlocation = () => {
  const navigate = useNavigate();
  const [emData, setData] = useState([]);
  const [options, setOptions] = useState([]);
  const changeFavicon = (url) => {
    const favicon = document.querySelector('link[rel="icon"]');
    if (favicon) {
      favicon.href = url;
    }
  };
  useEffect(() => {
    changeFavicon(require("../assets/admin/images/f-icon.png"));
    document.title = "Add Location Page";
    const userStr = JSON.parse(localStorage.getItem("admin"));

    if (userStr == null) {
      navigate("/admin/login");
    }
    axios
      .get("https://jlmining.app/admin/getlocation", {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        console.log("ss");
        console.log(res.data.results);
        setData(res.data.results);
      })
      .catch((err) => {});

    axios
      .get("https://jlmining.app/admin/getclient", {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        console.log(res.data.results);
        setOptions(res.data.results);
      })
      .catch((err) => {});
  }, []);

  const [values, setValues] = useState({
    name: "",
    email: "",
    password: "",
    contact: "",
    address: "",
  });
  const [showAlert, setShowAlert] = useState(false);
  const [showPass, setShowPass] = useState(false);
  const [val, setVal] = useState("");
  const handleInput = (event) => {
    setVal((v) => (event.target.validity.valid ? event.target.value : v));
    setValues((prev) => ({
      ...prev,
      [event.target.name]: [event.target.value],
    }));
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    var vll = event.target;
    let formdata = {
      client_id: vll.client_id.value,
      location_name: vll.location_name.value,
      nearest_town: vll.nearest_town.value,
      commodity: vll.commodity.value,
      contract_type: vll.contract_type.value,
      duration_start: vll.duration_start.value,
      duration_end: vll.duration_end.value,
      scope: vll.scope.value,
    };
    axios
      .post("https://jlmining.app/admin/addlocation", formdata, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        console.log(res.data);
        axios
          .get("https://jlmining.app/admin/getlocation", {
            headers: {
              "Access-Control-Allow-Origin": "*",
              "Access-Control-Allow-Methods": "POST,PUT",
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          })
          .then((res) => {
            // console.log(res.data.results);
            setData(res.data.results);
          })
          .catch((err) => {});
        setShowAlert(true);
        setShowAlert({ type: "success" });
        event.target.reset();
        event.target.text();
        setTimeout(() => {
          setShowAlert(false);
        }, 4500);
      })
      .catch((err) => {
        //console.log(err.response.data);
      });
  };

  return (
    <>
      <div>
        <AdminHeader />
        <div className="main-right-body">
          <div className="block-full">
            <div className="row gy-4">
              <div className="col-md-4">
                <div className="d-block red-heading bg-white shadow p-2">
                  <h3 className="mb-3">Mining Locations</h3>
                  <div className="over-empl">
                    <div className="d-flex flex-column h-100">
                      {Array.isArray(emData) ? (
                        emData.map((item, index) => (
                          <Link to="javascript:void(0)">
                            {item.location_name}
                          </Link>
                        ))
                      ) : (
                        <Link> No data available </Link>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-8">
                {showAlert?.type === "success" && (
                  <Alert
                    type="success"
                    description="Location has been successfully created"
                    closable
                  />
                )}
                <div className="right-form-box bg-white shadow p-2">
                  <form action="" onSubmit={handleSubmit} method="post">
                    <div className="row gy-2">
                      <div className="col-12 col-lg-6">
                        <div className="d-flex flex-column gap-2">
                          <label for="">
                            Location Name(Address){" "}
                            <span className="text-danger fs-14">*</span>
                          </label>
                          <input
                            type="text"
                            onChange={handleInput}
                            placeholder="type here"
                            required
                            name="location_name"
                          />
                        </div>
                      </div>
                      <div className="col-12 col-lg-6">
                        <div className="d-flex flex-column gap-2">
                          <label for="">Nearest Town</label>
                          <input
                            type="text"
                            onChange={handleInput}
                            placeholder="type here"
                            name="nearest_town"
                            required
                          />
                        </div>
                      </div>
                      <div className="col-12 col-lg-6">
                        <div className="d-flex flex-column gap-2">
                          <label for="">
                            Client Name{" "}
                            <span className="text-danger fs-14">*</span>
                          </label>
                          <select name="client_id" id="" required>
                            <option value="">--Select--</option>
                            {options.map((option) => (
                              <option key={option.id} value={option.id}>
                                {option.name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className="col-12 col-lg-6">
                        <div className="d-flex flex-column gap-2">
                          <label for="">Commodity </label>
                          <input
                            type="text"
                            onChange={handleInput}
                            placeholder="type here"
                            name="commodity"
                          />
                        </div>
                      </div>

                      <div className="col-12">
                        <div className="d-flex flex-column gap-2">
                          <label for="">Contract Type </label>
                          <input
                            type="text"
                            onChange={handleInput}
                            placeholder="type here"
                            name="contract_type"
                          />
                        </div>
                      </div>
                      <div className="col-12 col-lg-5">
                        <div className="d-flex flex-column gap-2">
                          <label for="">
                            Start Enter Date{" "}
                            <span className="text-danger fs-14">*</span>
                          </label>
                          <input
                            type="date"
                            name="duration_start"
                            placeholder="enter date here"
                            required
                          />
                        </div>
                      </div>
                      <div className="col-12 col-lg-2">
                        <div className="d-block text-center pt-4">
                          <label>to</label>
                        </div>
                      </div>
                      <div className="col-12 col-lg-5">
                        <div className="d-flex flex-column gap-2">
                          <label for="">
                            End Enter Date{" "}
                            <span className="text-danger fs-14">*</span>
                          </label>
                          <input
                            type="date"
                            name="duration_end"
                            placeholder="enter date here"
                          />
                        </div>
                      </div>

                      <div className="col-12">
                        <div className="d-flex flex-column gap-2">
                          <label for="">Scope</label>
                          <textarea
                            id=""
                            onChange={handleInput}
                            cols="30"
                            rows="4"
                            name="scope"
                          ></textarea>
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="d-flex align-items-center gap-3 justify-content-end">
                          <button className="sub-btn">add</button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <AdminFooter />
      </div>
    </>
  );
};
