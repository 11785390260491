import React, { useState, useEffect } from "react";
import axios from "axios";
//import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import Modal from "react-modal";
import useKeyboardShortcutBlocker from "./KeyboardShortcutBlocker";
import { Alert } from "antd";
import { FaPlus, FaTrash } from "react-icons/fa";
import { GoogleMap, LoadScript, MarkerF } from "@react-google-maps/api";
import { useNavigate, Link } from "react-router-dom";
export const Register = () => {
  //useKeyboardShortcutBlocker();
  const placeholderText = "Type here...";
  const formatCreateLabel = (inputValue) => `"${inputValue}"`;
  const [coordinates, setCoordinates] = useState({ lat: 0, lng: 0 });
  const [address, setAddress] = useState("");
  const [selectedlicenec_File, setSelectedlicenec_File] = useState(null);
  const [selectedtrade_file, setSelectedtrade_file] = useState(null);
  const [selectedmachinery_file, setSelectedmachinery_file] = useState(null);
  const [selectedcertificate_file, setSelectedcertificate_file] = useState(
    null
  );
  const [isButtonDisabled, setButtonDisabled] = useState(false);
  const [textmessage, settextmessage] = useState("");
  const [isActivec, setIsActivec] = useState(false);

  const [selectedOptions, setSelectedOptions] = useState([]);
  const [MentionLic, setSelectedMentionLic] = useState([]);
  const [MentionLCert, setSelectedMentionCert] = useState([]);
  const [trademach, setSelectedTrade] = useState([]);
  const [Vocationtra, setSelectedVocationtra] = useState([]);
  const [machineryget, setSelectedmachinery] = useState([]);
  const [Equipmentwork, setSelectedEquipmentwork] = useState([]);
  const [prevwork, setSelectedprevwork] = useState([]);
  const [sgetreferences, setSelectedreferences] = useState([]);
  const [selectvalue, setselectvalue] = useState([]);
  const [selectvalue_mentionl, setselectvalue_mentionl] = useState([]);
  const [selectvalue_mentionc, setselectvalue_mentionc] = useState([]);
  const [selectvalue_trade, setselectvalue_trade] = useState([]);
  const [selectvalue_machinery, setselectvalue_machinery] = useState([]);
  const [
    selectvalue_vocational_training,
    setselectvalue_vocational_training,
  ] = useState([]);
  const [selectvalue_equipment_work, setselectvalue_equipment_work] = useState(
    []
  );
  const [selectvalue_reference, setselectvalue_reference] = useState([]);
  const [selectvalue_previous_work, setselectvalue_previous_work] = useState(
    []
  );

  const [selectcertmsg, setselect_certmsg] = useState("");
  const [setselect_m_msg, setsetselect_m_msg] = useState("");
  const [setselect_t_msg, setsetselect_t_msg] = useState("");
  const [setselect_l_msg, setsetselect_l_msg] = useState("");
  const [isModalOpenRole, setIsModalOpenRole] = useState(false);
  const [valueaddpreviouswork, setaddpreviouswork] = useState(false);
  const [isModalOpenrol, setIsModalOpenrol] = useState(false);
  const currentYear = new Date().getFullYear();
  const navigate = useNavigate();
  const changeFavicon = (url) => {
    const favicon = document.querySelector('link[rel="icon"]');
    if (favicon) {
      favicon.href = url;
    }
  };
  const handlelicense_File = (event) => {
    // console.log(event.target.files);

    const selectedFiles = event.target.files;

    if (selectedFiles.length > 0) {
      const maxSize = 5 * 1024 * 1024; // 4 MB

      const totalSize = Array.from(selectedFiles).reduce(
        (acc, file) => acc + file.size,
        0
      );

      if (totalSize > maxSize) {
        setsetselect_l_msg(
          "The total size of selected files exceeds the maximum allowed size (5MB). Please choose smaller files."
        );

        event.target.value = "";
        setSelectedlicenec_File(null);
      } else {
        setsetselect_l_msg("");
        setSelectedlicenec_File(selectedFiles);
      }
    }
  };
  const handletrade_File = (event) => {
    // console.log(event.target.files);

    const selectedFiles = event.target.files;

    if (selectedFiles.length > 0) {
      const maxSize = 5 * 1024 * 1024; // 4 MB

      const totalSize = Array.from(selectedFiles).reduce(
        (acc, file) => acc + file.size,
        0
      );
      if (totalSize > maxSize) {
        setsetselect_t_msg(
          "Some selected files exceed the maximum allowed size (4MB). Please choose smaller files."
        );

        event.target.value = "";
        setSelectedtrade_file(null);
      } else {
        setsetselect_t_msg("");
        setSelectedtrade_file(selectedFiles);
      }
    }
  };
  const handlemachinery_File = (event) => {
    // console.log(event.target.files);

    const selectedFiles = event.target.files;
    if (selectedFiles.length > 0) {
      const maxSize = 5 * 1024 * 1024; // 4 MB
      console.log(maxSize);

      const totalSize = Array.from(selectedFiles).reduce(
        (acc, file) => acc + file.size,
        0
      );
      if (totalSize > maxSize) {
        setsetselect_m_msg(
          "Some selected files exceed the maximum allowed size (4MB). Please choose smaller files."
        );

        event.target.value = "";
        setSelectedmachinery_file(null);
      } else {
        setsetselect_m_msg("");
        setSelectedmachinery_file(selectedFiles);
      }
    }
  };
  const handlecertificate_File = (event) => {
    const selectedFiles = event.target.files;

    if (selectedFiles.length > 0) {
      const maxSize = 5 * 1024 * 1024; // 4 MB

      const totalSize = Array.from(selectedFiles).reduce(
        (acc, file) => acc + file.size,
        0
      );
      if (totalSize > maxSize) {
        setselect_certmsg(
          "Some selected files exceed the maximum allowed size (4MB). Please choose smaller files."
        );

        event.target.value = "";
        setSelectedcertificate_file(null);
      } else {
        setselect_certmsg("");
        setSelectedcertificate_file(selectedFiles);
      }
    }
  };
  const addmore = () => {
    setIsModalOpenrol(true);
  };
  useEffect(() => {
    changeFavicon(require("./assets/admin/images/f-icon.png"));
    const userStr = JSON.parse(localStorage.getItem("users"));
    document.title = "Register Page";
    if (userStr !== null) {
      window.location.href = "#/home";
    }
    let formdata = {
      user_id: 1,
    };
    axios
      .post("https://jlmining.app/getskills", formdata, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        setSelectedOptions(res.data.results);
        //const options = res.data.results;
      })
      .catch((err) => {
        //console.log(err.response.data);
      });

    //Mention Licence
    axios
      .post("https://jlmining.app/getmentionlicence", formdata, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        setSelectedMentionLic(res.data.results);
      })
      .catch((err) => {});

    //Mention certificate
    axios
      .post("https://jlmining.app/getmentioncertificate", formdata, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        setSelectedMentionCert(res.data.results);
      })
      .catch((err) => {});
    //Trade
    axios
      .post("https://jlmining.app/gettrade", formdata, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        setSelectedTrade(res.data.results);
      })
      .catch((err) => {});

    //Vocational Traninig
    axios
      .post("https://jlmining.app/getvocationaltra", formdata, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        setSelectedVocationtra(res.data.results);
      })
      .catch((err) => {});
    //Equipment Worked
    axios
      .post("https://jlmining.app/getequipmentwork", formdata, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        setSelectedEquipmentwork(res.data.results);
      })
      .catch((err) => {});

    //Previous Work
    axios
      .post("https://jlmining.app/getprevwork", formdata, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        setSelectedprevwork(res.data.results);
      })
      .catch((err) => {});

    //Machinery
    axios
      .post("https://jlmining.app/getmachinery", formdata, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        setSelectedmachinery(res.data.results);
      })
      .catch((err) => {});

    //References
    axios
      .post("https://jlmining.app/getreferences", formdata, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        console.log("refer");
        console.log(res.data.results);
        setSelectedreferences(res.data.results);
      })
      .catch((err) => {});
  }, [address]);
  const addpreviouswork = () => {
    setaddpreviouswork(!valueaddpreviouswork);
  };
  const [values, setValues] = useState({
    name: "",
    email: "",
    password: "",
    contact: "",
    address: "",
  });
  const [showAlert, setShowAlert] = useState(false);
  const [showAlert_s, setShowAlert_s] = useState(false);
  const [showPass, setShowPass] = useState(false);
  const [val, setVal] = useState("");
  const handleInput = (event) => {
    setVal((v) => (event.target.validity.valid ? event.target.value : v));
    setValues((prev) => ({
      ...prev,
      [event.target.name]: [event.target.value],
    }));
  };
  const [employmentHistorySections, setEmploymentHistorySections] = useState([
    {
      start_date: "",
      end_date: "",
      company: "",
      role: "",
      company_done: "",
    },
  ]);

  const handleInputChange = (index, name, value) => {
    const updatedSections = [...employmentHistorySections];
    updatedSections[index][name] = value;
    setEmploymentHistorySections(updatedSections);
  };

  const addMoreDiv = () => {
    setEmploymentHistorySections([
      ...employmentHistorySections,
      {
        start_date: "",
        end_date: "",
        company: "",
        role: "",
        company_done: "",
      },
    ]);
  };
  const trashdiv = (index) => {
    const updatedSections = [...employmentHistorySections];
    updatedSections.splice(index, 1);
    setEmploymentHistorySections(updatedSections);
  };

  const [employmenteducation, setEmploymenteducation] = useState([
    {
      year: "",
      course: "",
      institution: "",
    },
  ]);
  const handleInputChange_edication = (index, name, value) => {
    const updatedSections = [...employmenteducation];
    updatedSections[index][name] = value;
    setEmploymenteducation(updatedSections);
  };
  const addMoreDivEducation = () => {
    setEmploymenteducation([
      ...employmenteducation,
      {
        year: "",
        course: "",
        institution: "",
      },
    ]);
  };
  const trashdiveducation = (index) => {
    const updatedSections = [...employmenteducation];
    updatedSections.splice(index, 1);
    setEmploymenteducation(updatedSections);
  };

  const [references, setreferences] = useState([
    {
      reference: "",
    },
  ]);

  const trashdivrefr = (index) => {
    const updatedSectionsr = [...references];
    updatedSectionsr.splice(index, 1);
    setreferences(updatedSectionsr);
  };
  const handleAttendrole = (event) => {
    var vll = event.target;
    let fdata = {
      skills_user: vll.skills_user.value,
    };
    axios
      .post("https://jlmining.app/adduserskill", fdata, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        var alldata = res.data;

        if (alldata.status === "2") {
          setShowAlert_s(true);
          setShowAlert_s({ type: "error" });
        } else {
          setShowAlert_s(true);
          setShowAlert_s({ type: "success" });
          let formdata = {
            user_id: 1,
          };
          axios
            .post("https://jlmining.app/getskills", formdata, {
              headers: {
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Methods": "POST,PUT",
                Accept: "application/json",
                "Content-Type": "application/json",
              },
            })
            .then((res) => {
              setSelectedOptions(res.data.results);
            })
            .catch((err) => {});

          setTimeout(() => {
            setIsModalOpenrol(false);
          }, 4500);
        }
      })
      .catch((err) => {});
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    var vll = event.target;
    const formData = new FormData();

    const skidata = [];
    if (selectvalue.length > 0) {
      for (let i = 0; i < selectvalue.length; i++) {
        skidata.push(selectvalue[i].value);
      }
    }

    if (selectedlicenec_File !== null) {
      if (selectedlicenec_File.length > 0) {
        for (let i = 0; i < selectedlicenec_File.length; i++) {
          formData.append("licence_file", selectedlicenec_File[i]);
        }
      }
    }
    if (selectedtrade_file !== null) {
      if (selectedtrade_file.length > 0) {
        for (let i = 0; i < selectedtrade_file.length; i++) {
          formData.append("trade_file", selectedtrade_file[i]);
        }
      }
    }
    if (selectedmachinery_file !== null) {
      if (selectedmachinery_file.length > 0) {
        for (let i = 0; i < selectedmachinery_file.length; i++) {
          formData.append("machinery_file", selectedmachinery_file[i]);
        }
      }
    }
    if (selectedcertificate_file !== null) {
      if (selectedcertificate_file.length > 0) {
        for (let i = 0; i < selectedcertificate_file.length; i++) {
          console.log(selectedcertificate_file[i]);
          formData.append("certificate_file", selectedcertificate_file[i]);
        }
      }
    }

    const selectvaluementionl = [];
    if (selectvalue_mentionl !== null) {
      if (selectvalue_mentionl.length > 0) {
        for (let i = 0; i < selectvalue_mentionl.length; i++) {
          selectvaluementionl.push(selectvalue_mentionl[i].value);
        }
      }
    }

    const selectvaluementionc = [];
    if (selectvalue_mentionc !== null) {
      if (selectvalue_mentionc.length > 0) {
        for (let i = 0; i < selectvalue_mentionc.length; i++) {
          selectvaluementionc.push(selectvalue_mentionc[i].value);
        }
      }
    }

    const selectvaluetrade = [];
    if (selectvalue_trade !== null) {
      if (selectvalue_trade.length > 0) {
        for (let i = 0; i < selectvalue_trade.length; i++) {
          selectvaluetrade.push(selectvalue_trade[i].value);
        }
      }
    }

    const selectvaluemachinery = [];
    if (selectvalue_machinery !== null) {
      if (selectvalue_machinery.length > 0) {
        for (let i = 0; i < selectvalue_machinery.length; i++) {
          selectvaluemachinery.push(selectvalue_machinery[i].value);
        }
      }
    }

    const selectvalue_vocationaltraining = [];
    if (selectvalue_vocational_training !== null) {
      if (selectvalue_vocational_training.length > 0) {
        for (let i = 0; i < selectvalue_vocational_training.length; i++) {
          selectvalue_vocationaltraining.push(
            selectvalue_vocational_training[i].value
          );
        }
      }
    }

    const selectvalue_equipmentwork = [];
    if (selectvalue_equipment_work !== null) {
      if (selectvalue_equipment_work.length > 0) {
        for (let i = 0; i < selectvalue_equipment_work.length; i++) {
          selectvalue_equipmentwork.push(selectvalue_equipment_work[i].value);
        }
      }
    }

    const selectvalue_ref = [];
    if (selectvalue_reference !== null) {
      if (selectvalue_reference.length > 0) {
        for (let i = 0; i < selectvalue_reference.length; i++) {
          selectvalue_ref.push(selectvalue_reference[i].value);
        }
      }
    }

    const selectvalue_previouswork = [];
    if (selectvalue_previous_work !== null) {
      if (selectvalue_previous_work.length > 0) {
        for (let i = 0; i < selectvalue_previous_work.length; i++) {
          selectvalue_previouswork.push(selectvalue_previous_work[i].value);
        }
      }
    }
    console.log("ss");
    formData.append("first_name", vll.first_name.value);
    formData.append("middle_name", vll.middle_name.value);
    formData.append("last_name", vll.last_name.value);
    formData.append("email", vll.email.value);
    formData.append("password", vll.password.value);
    formData.append("contact", vll.contact.value);
    formData.append("address", vll.address.value);
    formData.append("skills", skidata);
    formData.append("years", vll.years.value);
    formData.append("licence", selectvaluementionl);
    formData.append("certificate", selectvaluementionc);
    formData.append("trade", selectvaluetrade);
    formData.append("machinery", selectvaluemachinery);
    formData.append("vocational_training", selectvalue_vocationaltraining);
    formData.append("equipment_work", selectvalue_equipmentwork);
    formData.append("previous_work", selectvalue_previouswork);

    formData.append(
      "employmentHistorySections",
      JSON.stringify(employmentHistorySections)
    );
    formData.append("references", selectvalue_ref);
    formData.append("education", JSON.stringify(employmenteducation));
    console.log(employmentHistorySections);

    if (vll.password.value != vll.confirm_password.value) {
      setShowPass(true);
      setShowPass({ type: "error" });
    } else {
      setButtonDisabled(true);
      setIsActivec(true);
      settextmessage("Don't refresh the page.Please wait...");
      //console.log(formdata);
      axios
        .post("https://jlmining.app/register", formData, {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "POST,PUT",
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          console.log(res.data);
          setButtonDisabled(false);
          settextmessage("");
          setIsActivec(false);
          if (res.data.status == "2") {
            setShowAlert(true);
            setShowAlert({ type: "error" });
          } else {
            setShowAlert(true);
            setShowAlert({ type: "success" });
            setTimeout(() => {
              console.log("refresh");
              window.location.href = "/register";
            }, 3500);
            setEmploymentHistorySections([
              {
                start_date: "",
                end_date: "",
                company: "",
                role: "",
                company_done: "",
              },
            ]);
            setEmploymenteducation([
              {
                year: "",
                course: "",
                institution: "",
              },
            ]);
            setreferences([
              {
                reference: "",
              },
            ]);
            event.target.reset();
            event.target.text();
            event.target.select();
            setSelectedOptions([]);
            setselectvalue([]);
            setselectvalue_mentionl([]);
            setselectvalue_mentionc([]);
            setselectvalue_trade([]);
            setselectvalue_machinery([]);
            setselectvalue_vocational_training([]);
            setselectvalue_equipment_work([]);
            setselectvalue_reference([]);
          }
          setTimeout(() => {
            setShowAlert(false);
          }, 4500);
        })
        .catch((err) => {
          //console.log(err.response.data);
        });
    }
  };
  const options = selectedOptions;

  const MentionLicc = MentionLic;
  const MentionCertt = MentionLCert;
  const TradeM = trademach;
  const Vocationtraa = Vocationtra;
  const Equipmentworkk = Equipmentwork;
  const Previouswork = prevwork;
  const machinerygett = machineryget;

  const get_references = sgetreferences;

  const handleSelectChange = (selectedOptions) => {
    console.log(selectedOptions);
    setselectvalue(selectedOptions);
  };

  const handleSelectChange_mentionl = (selectedOptions) => {
    setselectvalue_mentionl(selectedOptions);
  };

  const handleSelectChange_mentionc = (selectedOptions) => {
    setselectvalue_mentionc(selectedOptions);
  };
  const handleSelectChange_trade = (selectedOptions) => {
    setselectvalue_trade(selectedOptions);
  };
  const handleSelectChange_machinery = (selectedOptions) => {
    setselectvalue_machinery(selectedOptions);
  };
  const handleSelectChange_vocational_training = (selectedOptions) => {
    setselectvalue_vocational_training(selectedOptions);
  };
  const handleSelectChange_equipment_work = (selectedOptions) => {
    setselectvalue_equipment_work(selectedOptions);
  };

  const handleInputChange_reference = (selectedOptions) => {
    setselectvalue_reference(selectedOptions);
  };
  const handleInputChange_previous_work = (selectedOptions) => {
    setselectvalue_previous_work(selectedOptions);
  };

  const handleRemoveOption = (removedOption) => {
    setSelectedOptions((prevSelected) =>
      prevSelected.filter((option) => option.value !== removedOption.value)
    );
  };
  const getaddress = () => {
    if (address != "") {
      setIsModalOpenRole(true);
    }
  };
  const selectedOptionLabels = selectedOptions.map(
    (selectedOption) => selectedOption.value
  );
  return (
    <>
      <header>
        <div className="container-lg">
          <div className="row gy-4 align-items-center justify-content-between">
            <div className="col-3 col-md-2">
              <Link to="/" className="logo-top">
                <img src={require("./assets/images/logo2.png")} alt="logo" />
              </Link>
            </div>
            <div className="col-md-2 d-none d-md-block">
              <Link to="/" className="logo-top">
                <img src={require("./assets/images/hss.png")} alt="logo" />
              </Link>
            </div>
            <div className="col-9 col-md-2 col-lg-2">
              <div className="navbar-list d-flex gap-3 justify-content-end">
                <Link to="/login">Login</Link>
                <Link to="/register" className="active">
                  Register
                </Link>
              </div>
            </div>
          </div>
        </div>
      </header>

      <div className="fullscreenre d-flex justify-content-center align-items-center mt-4">
        <div className="d-flex flex-column gap-3">
          <div className="reg-box d-flex flex-column gap-3 px-3 px-md-5 pt-40 pb-60 w-300 w-md-600 mt-4">
            <div className="d-flex justify-content-center align-items-center">
              <div className="flex flex-column gap-2">
                <h3 className="fs-30 fw-bold text-center mytext-primary">
                  Register
                </h3>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-md-8">
                {showAlert?.type === "error" && (
                  <Alert
                    type="error"
                    description="Email is already exist"
                    closable
                  />
                )}
                {showAlert?.type === "success" && (
                  <Alert
                    type="success"
                    className="text-success "
                    description="Register successfully"
                    closable
                  />
                )}
                <form action="" onSubmit={handleSubmit} method="post">
                  <div className="d-flex flex-column gap-3">
                    <div className="mt-2">
                      <label className="mb-2 text-white">
                        First Name <span className="text-danger fs-16">*</span>
                      </label>
                      <div className="form-box rel">
                        <i className="fal fa-user top-icon"></i>
                        <input
                          type="text"
                          onChange={handleInput}
                          name="first_name"
                          required
                          placeholder="First Name"
                        />
                      </div>
                    </div>
                    <div className="mt-2">
                      <label className="mb-2 text-white">
                        Middle Name(Optional)
                      </label>
                      <div className="form-box rel">
                        <i className="fal fa-user top-icon"></i>
                        <input
                          type="text"
                          name="middle_name"
                          onChange={handleInput}
                          placeholder="Middle Name"
                        />
                      </div>
                    </div>
                    <div className="mt-2">
                      <label className="mb-2  text-white">
                        Last Name <span className="text-danger fs-16">*</span>
                      </label>
                      <div className="form-box rel">
                        <i className="fal fa-user top-icon"></i>
                        <input
                          type="text"
                          onChange={handleInput}
                          name="last_name"
                          required
                          placeholder="Last Name"
                        />
                      </div>
                    </div>
                    <div className="mt-2">
                      <label className="mb-2  text-white">
                        Phone Number{" "}
                        <span className="text-danger fs-16">*</span>
                      </label>
                      <div className="form-box rel">
                        <i className="fal fa-phone-alt top-icon"></i>
                        <input
                          type="text"
                          name="contact"
                          onChange={handleInput}
                          required
                          pattern="[0-9]*"
                          minlength="10"
                          placeholder="Phone Number"
                        />
                      </div>
                    </div>

                    <div className="mt-2">
                      <label className="mb-2  text-white">
                        Email-Id <span className="text-danger fs-16">*</span>
                      </label>
                      <div className="form-box rel">
                        <i className="fal fa-envelope top-icon"></i>
                        <input
                          type="email"
                          name="email"
                          onChange={handleInput}
                          required
                          placeholder="Email Address"
                        />
                      </div>
                    </div>
                    <div className="mt-2">
                      <label className="mb-2  text-white">
                        Password <span className="text-danger fs-16">*</span>
                      </label>
                      <div className="form-box rel">
                        <i className="fal fa-lock top-icon"></i>
                        <input
                          type="password"
                          name="password"
                          onChange={handleInput}
                          required
                          placeholder="Password"
                        />
                      </div>
                    </div>

                    <div className="mt-2">
                      <label className="mb-2  text-white">
                        Re-Enter Password{" "}
                        <span className="text-danger fs-16">*</span>
                      </label>
                      <div className="form-box rel">
                        <i className="fal fa-lock top-icon"></i>
                        <input
                          type="password"
                          name="confirm_password"
                          onChange={handleInput}
                          required
                          placeholder="Re-Enter Password"
                        />
                        <div className="mt-2">
                          {showPass?.type === "error" && (
                            <Alert
                              type="error"
                              description="Password does not match"
                              closable
                            />
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="mt-2">
                      <label className="mb-2  text-white">
                        Address <span className="text-danger fs-16">*</span>
                      </label>
                      <div className="form-box rel">
                        <Link
                          to="javascript:void(0)"
                          onClick={() => getaddress()}
                        >
                          <i className="fal fa-location top-icon"></i>
                        </Link>
                        <input
                          type="text"
                          name="address"
                          onChange={(e) => setAddress(e.target.value)}
                          required
                          placeholder="Address"
                        />
                      </div>
                    </div>
                    <h5 class="mt-2  text-white">
                      Education, year of completion, course, institution.
                    </h5>
                    {/*********** */}
                    {employmenteducation.map((section, index) => (
                      <div key={index} className="mt-2">
                        <label className="mb-3 text-white">
                          Education
                          <span className="text-danger fs-16">*</span>
                        </label>
                        {index !== 0 && (
                          <Link
                            to="javascript:void(0)"
                            className="text-danger float-end"
                            onClick={() => trashdiveducation(index)}
                          >
                            <FaTrash />
                          </Link>
                        )}
                        <div className="form-box">
                          <div className="row gy-4">
                            <div className="col-12">
                              <div className="d-flex flex-column gap-2">
                                <label htmlFor="" className="text-white">
                                  Year of Completion
                                </label>
                                <input
                                  name={`year_${index}`}
                                  style={{ paddingLeft: "10px" }}
                                  type="text"
                                  placeholder="Year of completion"
                                  value={section.year}
                                  onChange={(e) =>
                                    handleInputChange_edication(
                                      index,
                                      "year",
                                      e.target.value
                                    )
                                  }
                                  required
                                />
                              </div>
                            </div>
                            <div className="col-12">
                              <div className="d-flex flex-column gap-2">
                                <label htmlFor="" className="text-white">
                                  Course
                                </label>
                                <input
                                  name={`course_${index}`}
                                  style={{ paddingLeft: "10px" }}
                                  type="text"
                                  placeholder="Course"
                                  value={section.course}
                                  onChange={(e) =>
                                    handleInputChange_edication(
                                      index,
                                      "course",
                                      e.target.value
                                    )
                                  }
                                  required
                                />
                              </div>
                            </div>
                            <div className="col-12">
                              <div className="d-flex flex-column gap-2">
                                <label htmlFor="" className="text-white">
                                  Institution
                                </label>
                                <input
                                  name={`institution_${index}`}
                                  style={{ paddingLeft: "10px" }}
                                  type="text"
                                  placeholder="institution"
                                  value={section.institution}
                                  onChange={(e) =>
                                    handleInputChange_edication(
                                      index,
                                      "institution",
                                      e.target.value
                                    )
                                  }
                                  required
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                    <div className="d-flex justify-content-center">
                      <button
                        type="button"
                        style={{
                          paddingLeft: "10px",
                          border: "none",
                          borderRadius: "4px",
                          fontSize: "14px",
                        }}
                        onClick={addMoreDivEducation}
                      >
                        Add More <i className="fal fa-plus"></i>:
                      </button>
                    </div>
                    {/*********** */}
                    <h5 class="mt-2  text-white">
                      Skill and Extra Informations
                    </h5>
                    <div className="mt-2">
                      <label className="mb-2  text-white">
                        Mention All Skills{" "}
                        <span className="text-danger fs-16">*</span>
                      </label>

                      <div className="form-box rel">
                        <CreatableSelect
                          isMulti
                          formatCreateLabel={formatCreateLabel}
                          placeholder={placeholderText}
                          options={options}
                          defaultValue={options}
                          name="skills"
                          required
                          onChange={handleSelectChange}
                        />
                        <div></div>
                      </div>
                    </div>

                    <div className="mt-2">
                      <label className="mb-2  text-white">
                        Years of Experience{" "}
                        <span className="text-danger fs-16">*</span>
                      </label>
                      <div className="form-box rel">
                        <input
                          style={{ paddingLeft: "10px" }}
                          type="number"
                          pattern="[0-9]*"
                          name="years"
                          onChange={handleInput}
                          required
                        />
                      </div>
                    </div>
                    <div className="mt-2">
                      <label className="mb-2  text-white">
                        Mention Licences
                      </label>
                      <div className="form-box d-flex flex-column gap-2">
                        <div className="flex-shrink-0">
                          <CreatableSelect
                            isMulti
                            formatCreateLabel={formatCreateLabel}
                            placeholder={placeholderText}
                            options={MentionLicc}
                            defaultValue={MentionLicc}
                            name="licence"
                            onChange={handleSelectChange_mentionl}
                          />
                        </div>
                        <div className="flex-shrink-0">
                          <input
                            type="file"
                            multiple
                            accept="image/*"
                            onChange={handlelicense_File}
                            name="licence_file"
                            style={{ color: "#fff" }}
                          />
                          <br />
                          <span className="text-danger">{setselect_l_msg}</span>
                        </div>
                      </div>
                    </div>
                    <div className="mt-2">
                      <label className="mb-2  text-white">
                        Mention Other Certification
                      </label>
                      <div className="form-box d-flex flex-column gap-2">
                        <div className="flex-shrink-0">
                          <CreatableSelect
                            isMulti
                            formatCreateLabel={formatCreateLabel}
                            placeholder={placeholderText}
                            options={MentionCertt}
                            defaultValue={MentionCertt}
                            name="certificate"
                            onChange={handleSelectChange_mentionc}
                          />
                        </div>
                        <div className="flex-shrink-0">
                          <input
                            type="file"
                            onChange={handlecertificate_File}
                            multiple
                            accept="image/*"
                            name="certificate_file"
                            style={{ color: "#fff" }}
                          />
                          <br />
                          <span className="text-danger">{selectcertmsg}</span>
                        </div>
                      </div>
                    </div>
                    <div className="mt-2">
                      <label className="mb-2  text-white">
                        Trade Certifications
                      </label>
                      <div className="form-box d-flex flex-column gap-2">
                        <div className="flex-shrink-0">
                          <CreatableSelect
                            isMulti
                            formatCreateLabel={formatCreateLabel}
                            placeholder={placeholderText}
                            name="trade"
                            options={TradeM}
                            defaultValue={TradeM}
                            onChange={handleSelectChange_trade}
                          />
                        </div>
                        <div className="flex-shrink-0">
                          <input
                            type="file"
                            multiple
                            accept="image/*"
                            onChange={handletrade_File}
                            name="trade_file"
                            style={{ color: "#fff" }}
                          />
                          <br />
                          <span className="text-danger">{setselect_t_msg}</span>
                        </div>
                      </div>
                    </div>
                    <div className="mt-2">
                      <label className="mb-2  text-white">Machinery</label>
                      <div className="form-box d-flex flex-column gap-2">
                        <div className="flex-shrink-0">
                          <CreatableSelect
                            isMulti
                            formatCreateLabel={formatCreateLabel}
                            placeholder={placeholderText}
                            options={machinerygett}
                            defaultValue={machinerygett}
                            name="machinery"
                            onChange={handleSelectChange_machinery}
                          />
                        </div>
                        <div className="flex-shrink-0">
                          <input
                            type="file"
                            multiple
                            accept="image/*"
                            name="machinery_file"
                            onChange={handlemachinery_File}
                            style={{ color: "#fff" }}
                          />
                          <br />
                          <span className="text-danger">{setselect_m_msg}</span>
                        </div>
                      </div>
                    </div>
                    <div className="mt-2">
                      <label className="mb-2  text-white">
                        Vocational training
                      </label>
                      <div className="form-box">
                        <div className="flex-shrink-0">
                          <CreatableSelect
                            isMulti
                            formatCreateLabel={formatCreateLabel}
                            placeholder={placeholderText}
                            options={Vocationtraa}
                            defaultValue={Vocationtraa}
                            name="vocational_training"
                            onChange={handleSelectChange_vocational_training}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="mt-2">
                      <label className="mb-2  text-white">
                        Equipment worked
                      </label>
                      <div className="form-box">
                        <div className="flex-shrink-0">
                          <CreatableSelect
                            isMulti
                            formatCreateLabel={formatCreateLabel}
                            placeholder={placeholderText}
                            options={Equipmentworkk}
                            defaultValue={Equipmentworkk}
                            name="equipment_work"
                            onChange={handleSelectChange_equipment_work}
                          />
                        </div>
                      </div>
                    </div>
                    {/* <div className="mt-2">
                      <label className="mb-3  text-white">
                        Employment History
                        <span className="text-danger fs-16">*</span>
                      </label>
                      <div className="form-box">
                        <div className="row gy-4">
                          <div className="col-6">
                            <div className="d-flex flex-column gap-2">
                              <label htmlFor="" className="text-white">
                                Start date
                              </label>
                              <input
                                name="start_date"
                                style={{ paddingLeft: "10px" }}
                                type="date"
                                required
                              />
                            </div>
                          </div>
                          <div className="col-6">
                            <div className="d-flex flex-column gap-2">
                              <label htmlFor="" className="text-white">
                                End date
                              </label>
                              <input
                                name="end_date"
                                style={{ paddingLeft: "10px" }}
                                type="date"
                                required
                              />
                            </div>
                          </div>
                          <div className="col-12">
                            <input
                              style={{ paddingLeft: "10px" }}
                              type="text"
                              name="company"
                              placeholder="Enter Company"
                              required
                            />
                          </div>
                          <div className="col-12">
                            <input
                              name="role"
                              style={{ paddingLeft: "10px" }}
                              type="text"
                              placeholder="Enter Your Roll"
                              required
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="mt-2">
                      <label className="mb-2  text-white">
                        Enter What you done in this company
                        <span className="text-danger fs-16">*</span>
                      </label>
                      <div className="form-box">
                        <div className="flex-shrink-0">
                          <textarea
                            name="company_done"
                            required
                            style={{ paddingLeft: "10px", borderRadius: "4px" }}
                            placeholder="type here..."
                            rows="4"
                          ></textarea>
                        </div>
                      </div>
                    </div> */}
                    {employmentHistorySections.map((section, index) => (
                      <div key={index} className="mt-2">
                        <Link
                          className="mb-4 text-white "
                          to="javascript:void(0)"
                        >
                          Employement History
                          <span className="text-danger fs-16">*</span>
                        </Link>
                        {index !== 0 && (
                          <Link
                            to="javascript:void(0)"
                            className="text-danger float-end"
                            onClick={() => trashdiv(index)}
                          >
                            <FaTrash />
                          </Link>
                        )}

                        <div className="form-box mt-3">
                          <div className="row gy-4">
                            <div className="col-6">
                              <div className="d-flex flex-column gap-2">
                                <label htmlFor="" className="text-white">
                                  Start date
                                </label>
                                <input
                                  name={`start_date_${index}`}
                                  style={{ paddingLeft: "10px" }}
                                  type="date"
                                  value={section.start_date}
                                  onChange={(e) =>
                                    handleInputChange(
                                      index,
                                      "start_date",
                                      e.target.value
                                    )
                                  }
                                  required
                                />
                              </div>
                            </div>
                            <div className="col-6">
                              <div className="d-flex flex-column gap-2">
                                <label htmlFor="" className="text-white">
                                  End date
                                </label>
                                <input
                                  name={`end_date_${index}`}
                                  style={{ paddingLeft: "10px" }}
                                  type="date"
                                  value={section.end_date}
                                  onChange={(e) =>
                                    handleInputChange(
                                      index,
                                      "end_date",
                                      e.target.value
                                    )
                                  }
                                  required
                                />
                              </div>
                            </div>
                            <div className="col-12">
                              <input
                                style={{ paddingLeft: "10px" }}
                                type="text"
                                name={`company_${index}`}
                                placeholder="Enter Company"
                                value={section.company}
                                onChange={(e) =>
                                  handleInputChange(
                                    index,
                                    "company",
                                    e.target.value
                                  )
                                }
                                required
                              />
                            </div>
                            <div className="col-12">
                              <input
                                name={`role_${index}`}
                                style={{ paddingLeft: "10px" }}
                                type="text"
                                placeholder="Enter Your Roll"
                                value={section.role}
                                onChange={(e) =>
                                  handleInputChange(
                                    index,
                                    "role",
                                    e.target.value
                                  )
                                }
                                required
                              />
                            </div>
                          </div>
                        </div>

                        <div className="mt-2">
                          <label className="mb-2 text-white">
                            Enter What you done in this company
                          </label>
                          <div className="form-box">
                            <div className="flex-shrink-0">
                              <textarea
                                name={`company_done_${index}`}
                                required
                                style={{
                                  paddingLeft: "10px",
                                  borderRadius: "4px",
                                }}
                                placeholder="type here..."
                                rows="4"
                                value={section.company_done}
                                onChange={(e) =>
                                  handleInputChange(
                                    index,
                                    "company_done",
                                    e.target.value
                                  )
                                }
                              ></textarea>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}

                    <div className="d-flex justify-content-center">
                      <button
                        type="button"
                        style={{
                          paddingLeft: "10px",
                          border: "none",
                          borderRadius: "4px",
                          fontSize: "14px",
                        }}
                        onClick={addMoreDiv}
                      >
                        Add More <i className="fal fa-plus"></i>:
                      </button>
                    </div>
                    <div className="mt-2">
                      <label className="mb-2 text-white">
                        Previous Work in Mining
                      </label>
                      <div className="form-box">
                        <div className="flex-shrink-0">
                          <CreatableSelect
                            isMulti
                            formatCreateLabel={formatCreateLabel}
                            placeholder={placeholderText}
                            options={Previouswork}
                            defaultValue={Previouswork}
                            name="previous_work"
                            onChange={handleInputChange_previous_work}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="mt-2">
                      <label className="mb-2 text-white">Reference</label>
                      <div className="form-box">
                        <div className="flex-shrink-0">
                          <CreatableSelect
                            isMulti
                            formatCreateLabel={formatCreateLabel}
                            placeholder={placeholderText}
                            name="references"
                            onChange={handleInputChange_reference}
                          />
                        </div>
                      </div>
                    </div>

                    <div>
                      <button
                        type="submit"
                        disabled={isButtonDisabled}
                        className={`submit-btn mb-2 ${
                          isActivec ? "inactive-button" : ""
                        }`}
                      >
                        Submit
                      </button>
                      {textmessage && (
                        <div className="textmessage text-center mt-2">
                          <div class="spinner-border text-success"></div>
                        </div>
                      )}
                      <span className="textmessage"></span>
                    </div>
                  </div>
                </form>
                <Modal
                  isOpen={isModalOpenrol}
                  onRequestClose={() => setIsModalOpenrol(false)}
                  contentLabel="Add Text Modal"
                  className="d-flex flex-column gap-3 bg-light mt-5 p-3 align-item-center justify-content-center"
                >
                  {" "}
                  <form
                    action="javascript:void(0)"
                    onSubmit={handleAttendrole}
                    method="post"
                  >
                    {showAlert_s?.type === "error" && (
                      <Alert
                        type="error"
                        description="Already Exist"
                        closable
                      />
                    )}
                    {showAlert_s?.type === "success" && (
                      <Alert
                        type="success"
                        description="Successfully created!"
                        closable
                      />
                    )}

                    <div class="row mt-4">
                      <div class="col-md-12">
                        <label className="mb-2">Add New Skills</label>

                        <input
                          type="text"
                          name="skills_user"
                          required
                          placeholder="Enter skill name"
                          className="form-control mb-2"
                        />
                      </div>
                    </div>
                    <button className="btn btn-success" type="submit">
                      Submit
                    </button>
                  </form>
                </Modal>
                <Modal
                  isOpen={isModalOpenRole}
                  onRequestClose={() => setIsModalOpenRole(false)}
                  contentLabel="Add Text Modal"
                  className="address d-flex flex-column  bg-light mt-5 p-3 align-item-center justify-content-center"
                >
                  {" "}
                  <div className="d-flex align-item-center justify-content-between mb-4">
                    <h4> Your Address </h4>
                  </div>
                  {/* <textarea value={popupText} onChange={handlePopupTextChange} /> */}
                  <div class="row">
                    <div class="col-md-12"></div>
                  </div>
                </Modal>
                {showAlert?.type === "error" && (
                  <Alert
                    type="error"
                    description="Email is already exist"
                    closable
                  />
                )}
                {showAlert?.type === "success" && (
                  <Alert
                    type="success"
                    description="Register successfully"
                    closable
                  />
                )}
                <div className="d-flex justify-content-center align-items-center my-3">
                  <div className="flex flex-column gap-4 w-md-300">
                    <h3 className="fs-18 fw-bold text-center mytext-primary">
                      <Link to="/login" className="mytext-primary">
                        Login
                      </Link>
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <footer>
        <div className="ft-topbox d-block py-5">
          <div className="container-lg">
            <div className="row gy-4">
              <div className="col-md-3">
                <div className="d-flex flex-column gap-3 ft-boxes">
                  <h3>Contact us</h3>
                  <div className="d-flex flex-column gap-2 ft-links">
                    <p>Address: 8 Sparks Rd, Henderson WA 6166 </p>
                    <p>
                      <Link to="javascript:void(0)">Phone: +61 894375388</Link>{" "}
                    </p>
                    <p>Hours: 7:00am - 5:00pm Monday - Friday </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="d-flex flex-column gap-3 ft-boxes">
                  <h3>Help</h3>
                  <div className="row gy-4">
                    <div className="col-md-6">
                      <div className="d-flex flex-column gap-2 ft-links">
                        <p>
                          <Link to="/contact">Contact Information</Link>{" "}
                        </p>
                        <p>
                          <Link to="javascript:void(0)">How to Use</Link>{" "}
                        </p>
                        <p>
                          <Link to="/experthelp">Get Expert Help</Link>{" "}
                        </p>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="d-flex flex-column gap-2 ft-links">
                        <p>
                          <Link to="/terms&condition">
                            Terms and Conditions
                          </Link>{" "}
                        </p>
                        <p>
                          <Link to="/privacypolicy">Privacy Policy</Link>{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="d-flex flex-column gap-3 ft-boxes">
                  <h3>exprole us</h3>
                  <div className="d-flex flex-column gap-2 ft-links">
                    <p>
                      <Link to="www.hssonline.com.au">
                        www.hssonline.com.au
                      </Link>{" "}
                    </p>
                    <p>
                      <Link to="www.hssptyltd.com.au">
                        www.hssptyltd.com.au
                      </Link>{" "}
                    </p>
                    <p>
                      <Link to="www.jlme.com.au">www.jlme.com.au </Link>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="copy-ft py-2 text-center">
          <div className="container-lg">
            <p>
              Privacy | Site Terms | &copy; {currentYear}, JL Mining All rights
              reserved
            </p>
          </div>
        </div>
      </footer>
    </>
  );
};
