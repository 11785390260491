import React, { useRef, useState, useEffect } from "react";
import axios from "axios";
import { message, Form, Input, Button, Alert } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { Header } from "./Header";
import { Footer } from "./Footer";
import Modal from "react-modal";
import Select from "react-select";
import Slider from "react-slick";
import "./assets/ohs/newstyle.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
export const Elearninghub = () => {
  const inputFileRef = useRef(null);
  const currentYear = new Date().getFullYear();
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState("home-tab");
  const [getincidentData, setgetincidentData] = useState([]);
  const [getincidentDataLength, setgetincidentDataLength] = useState([]);
  const [getneartData, setgetnearData] = useState([]);
  const [getnearDataLength, setgetnearDataLength] = useState([]);
  const [issetgetLocations, setgetLocations] = useState([]);
  const [issetgetLocationsingle, setgetLocationsingle] = useState("");
  const [isgetallhazardl, setisgetallhazardl] = useState(0);
  const [isgetallhazard, setisgetallhazard] = useState([]);
  const [docscategory, setdocscategory] = useState([]);
  const [iscategoryvl, setcategoryvl] = useState("");
  const [diss, setdiss] = useState(false);
  const [issetgetalldocuments, setgetalldocuments] = useState([]);
  const [openDropdownIndex, setOpenDropdownIndex] = useState(null);
  const [openDropdownIndexMiss, setOpenDropdownIndexMiss] = useState(null);
  const [openDropdownIndexIncident, setOpenDropdownIndexIncident] = useState(
    null
  );
  const [openDropdownIndexeditMiss, setOpenDropdownIndexeditMiss] = useState(
    null
  );
  const [
    openDropdownIndexeditIncident,
    setOpenDropdownIndexeditIncident,
  ] = useState(null);
  const [isModalOpenDoc, setIsModalOpenDoc] = useState(false);
  const [openDropdownIndexedit, setOpenDropdownIndexedit] = useState(null);
  const [fileshow, setfilename] = useState("");
  const [docs_s, setdocs_s] = useState(false);
  const [issetiselarning, setiselarning] = useState([]);
  const changeFavicon = (url) => {
    const favicon = document.querySelector('link[rel="icon"]');
    if (favicon) {
      favicon.href = url;
    }
  };

  useEffect(() => {
    changeFavicon(require("./assets/admin/images/f-icon.png"));
    const userStr = JSON.parse(localStorage.getItem("users"));
    if (userStr === null) {
      window.location.href = "/login";
    }
    document.title = "E-Learning Page";
    let formdata = {
      user_id: userStr[0].id,
    };

    axios
      .post("https://jlmining.app/getalldocuments", formdata, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        setgetalldocuments(res.data.results);
      })
      .catch((err) => {});
    axios
      .post("https://jlmining.app/getlocationohs", formdata, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        console.log("locations");
        console.log(res.data.results);
        if (res.data.results.length > 0) {
          const options = res.data.results.map((item) => ({
            value: item.location_name,
            label: item.location_name,
          }));
          setgetLocations(options);
        }
      })
      .catch((err) => {});
    axios
      .post("https://jlmining.app/getAllincidentdata", formdata, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        setgetincidentDataLength(res.data.results.length);
        if (res.data.results.length > 0) {
          var indata = res.data.results;
          setgetincidentData(indata);
        }
      })
      .catch((err) => {});
    axios
      .post("https://jlmining.app/getAllneardata", formdata, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        setgetnearDataLength(res.data.results.length);
        if (res.data.results.length > 0) {
          var indata = res.data.results;
          setgetnearData(indata);
        }
      })
      .catch((err) => {});
    axios
      .post("https://jlmining.app/getAllreporthazard", formdata, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        console.log(res.data);
        console.log("getgg");
        setisgetallhazardl(res.data.results.length);

        setisgetallhazard(res.data.results);
      })
      .catch((err) => {});
    axios
      .post("https://jlmining.app/getallelearning", formdata, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        setiselarning(res.data.results);
      })
      .catch((err) => {});
    const options_category = [
      { value: "CHECKLIST", label: "Checklist" },
      { value: "FIRE_SAFETY", label: "Fire Safety" },
      { value: "FIRST_AID", label: "First Aid" },
      { value: "INCIDENT_RECORD", label: "Incident Record" },
      { value: "METHOD_STATEMENT", label: "Method Statement" },
      { value: "MISC", label: "Misc" },
      { value: "PHOTOGRAPH", label: "Photograph" },
      { value: "PROCEDURE", label: "Procedure" },
      { value: "REPORT", label: "Report" },
      { value: "RESPONSIBILITY", label: "Responsibility" },
      { value: "RISK_ASSESSMENT", label: "Risk Assessment" },
      { value: "SAFETY_DATA_SHEET", label: "Safety Data Sheet" },
      { value: "SAFETY_RECORD", label: "Safety Record" },
      { value: "SAFE_WORK_PROCEDURE", label: "Safe Work Procedure" },
      { value: "TOOLBOX_TALK", label: "Tool-box Talk" },
    ];
    setdocscategory(options_category);
  }, []);

  const handactivetabsection = (event, tabId) => {
    // Prevent default action of button click
    event.preventDefault();

    // Remove "active" class from all nav links
    document.querySelectorAll(".nav-link").forEach((link) => {
      link.classList.remove("active");
    });

    // Add "active" class to the clicked nav link
    event.target.classList.add("active");

    // Remove "show active" classes from all tab panes
    document.querySelectorAll(".tab-pane").forEach((pane) => {
      pane.classList.remove("show", "active");
    });

    // Add "show active" classes to the clicked tab pane
    const tabPane = document.getElementById(`${tabId}-pane`);
    if (tabPane) {
      tabPane.classList.add("show", "active");
    }

    // Update activeTab state
    setActiveTab(tabId);
  };
  var settings = {
    dots: false,
    infinite: true,
    centerMode: true,
    centerPadding: "40px",
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows: true,
  };
  function getdattime(dateString) {
    const options = {
      weekday: "short",
      day: "2-digit",
      month: "short",
      year: "numeric",
    };
    const formattedDate = new Date(dateString).toLocaleString("en-US", options);
    return formattedDate;
  }
  function formatDate_notes(dateString) {
    const options = {
      month: "long",
      day: "numeric",
      year: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };
    const date = new Date(dateString);
    return date.toLocaleDateString("en-US", options);
  }
  const hazardedit = (index) => {
    setOpenDropdownIndex(index === openDropdownIndex ? null : index);
  };
  const editr = (index) => {
    setOpenDropdownIndexedit(index === openDropdownIndexedit ? null : index);
  };
  const nearmissedit = (index) => {
    setOpenDropdownIndexMiss(index === openDropdownIndexMiss ? null : index);
  };
  const incidentedit = (index) => {
    setOpenDropdownIndexIncident(
      index === openDropdownIndexIncident ? null : index
    );
  };
  const remover = (event, index, code) => {
    const userStr = JSON.parse(localStorage.getItem("users"));
    let fdata = {
      id: event,
      user_id: userStr[0].id,
      code: code,
    };
    axios
      .post("https://jlmining.app/removehazard", fdata, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        console.log(res.data.results);

        setOpenDropdownIndexedit(index === openDropdownIndexedit ? null : null);
        setOpenDropdownIndex(index === openDropdownIndex ? null : null);
        setTimeout(() => {
          setisgetallhazardl(res.data.results.length);

          setisgetallhazard(res.data.results);
        }, 500);
      })
      .catch((err) => {});
  };
  const removeIncident = (event, index, code) => {
    const userStr = JSON.parse(localStorage.getItem("users"));
    let fdata = {
      id: event,
      user_id: userStr[0].id,
      code: code,
    };
    axios
      .post("https://jlmining.app/removeIncident", fdata, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        console.log(res.data.results);

        setOpenDropdownIndexeditIncident(
          index === openDropdownIndexeditIncident ? null : null
        );
        setOpenDropdownIndexIncident(
          index === openDropdownIndexIncident ? null : null
        );
        setTimeout(() => {
          setgetincidentDataLength(res.data.results.length);

          setgetincidentData(res.data.results);
        }, 500);
      })
      .catch((err) => {});
  };
  const removeMiss = (event, index, code) => {
    const userStr = JSON.parse(localStorage.getItem("users"));
    let fdata = {
      id: event,
      user_id: userStr[0].id,
      code: code,
    };
    axios
      .post("https://jlmining.app/removeNearmiss", fdata, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        console.log(res.data.results);

        setOpenDropdownIndexeditMiss(
          index === openDropdownIndexeditMiss ? null : null
        );
        setOpenDropdownIndexMiss(index === openDropdownIndexMiss ? null : null);
        setTimeout(() => {
          setgetnearDataLength(res.data.results.length);

          setgetnearData(res.data.results);
        }, 500);
      })
      .catch((err) => {});
  };
  const searchhazard = (event) => {
    const userStr = JSON.parse(localStorage.getItem("users"));
    let fdata = {
      search: event.value,
      user_id: userStr[0].id,
    };
    console.log(fdata);
    axios
      .post("https://jlmining.app/hazardreportfiltersite", fdata, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        setisgetallhazardl(res.data.results.length);

        setisgetallhazard(res.data.results);
      })
      .catch((err) => {});
  };
  const filterdate = (event) => {
    const userStr = JSON.parse(localStorage.getItem("users"));
    let fdata = {
      date: event.target.value,
      user_id: userStr[0].id,
    };
    console.log(fdata);
    axios
      .post("https://jlmining.app/hazardreportfilter", fdata, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        setisgetallhazardl(res.data.results.length);

        setisgetallhazard(res.data.results);
      })
      .catch((err) => {});
  };
  const uploaddoc = () => {
    setIsModalOpenDoc(true);
  };
  const closeDoc = () => {
    if (inputFileRef.current) {
      inputFileRef.current.value = "";
    }
    setfilename("");
    setIsModalOpenDoc(false);
  };
  const docfile = (event) => {
    const file = event.target.files[0]; // Get the first file from the input

    setfilename(file);
  };
  const handleRemoveFile = (event) => {
    if (inputFileRef.current) {
      inputFileRef.current.value = "";
    }
    setfilename("");
  };
  const assigntoall = (event) => {
    console.log(event.target.checked);
    if (event.target.checked === true) {
      setdiss(true);
    } else {
      setdiss(false);
    }
  };
  const handcategory = (event) => {
    console.log(event);
    setcategoryvl(event);
  };
  const formdite = (event) => {
    setgetLocationsingle({
      value: event.value,
      label: event.value,
    });
  };
  const handsubmitcategory = (event) => {
    const userStr = JSON.parse(localStorage.getItem("users"));
    var formData = new FormData();
    console.log(diss);
    if (diss == true) {
      var assign = "Yes";
      var fsite = event.target.form_site.value;
    } else {
      var assign = "No";
      var fsite = "";
    }
    formData.append("category", event.target.category.value);
    formData.append("user_id", userStr[0].id);
    formData.append("file", fileshow);
    formData.append("form_site", fsite);
    formData.append("assign", assign);
    console.log(formData);
    axios
      .post("https://jlmining.app/documentsave", formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        setdocs_s(true);
        setTimeout(() => {
          setdocs_s(false);
          setfilename("");
          setcategoryvl("");
          setgetLocationsingle("");
          if (inputFileRef.current) {
            inputFileRef.current.value = "";
          }
          event.target.reset();
          setIsModalOpenDoc(false);
        }, 4500);
      })
      .catch((err) => {});
  };
  const handleDownload = (m, file) => {
    const link = document.createElement("a");
    link.href = file;
    link.download = m;
    link.click();
  };
  const apiurl = "https://jlmining.app/uploads/elearning";
  return (
    <>
      <Header />
      <style>
        {`
            .truncate-text {
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 8;
              overflow: hidden;
            }
          `}
      </style>
      <section className="d-block pb-5">
        <div className="container-lg">
          <div className="row">
            <div className="col-12">
              <ul className="nav nav-tabs inci-tab" id="myTab" role="tablist">
                {/* <li className="nav-item" role="presentation">
                  <button
                    className={`nav-link ${
                      activeTab === "home-tab" ? "active" : ""
                    }`}
                    id="home-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#home-tab-pane"
                    type="button"
                    role="tab"
                    aria-controls="home-tab-pane"
                    aria-selected="true"
                  >
                    Incidents
                  </button>
                </li> */}
                {/* <li className="nav-item" role="presentation">
                  <button
                    className={`nav-link ${
                      activeTab === "profile-tab" ? "active" : ""
                    }`}
                    id="profile-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#profile-tab-pane"
                    type="button"
                    role="tab"
                    aria-controls="profile-tab-pane"
                    aria-selected="false"
                  >
                    Near Misses
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className={`nav-link ${
                      activeTab === "report-tab" ? "active" : ""
                    }`}
                    id="report-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#report-tab-pane"
                    type="button"
                    role="tab"
                    aria-controls="report-tab-pane"
                    aria-selected="false"
                  >
                    Report a hazard
                  </button>
                </li> */}
              </ul>
              <div className="tab-content" id="myTabContent">
                {/* <div
                  className="tab-pane fade show active p-3 border border-secondary border-opacity-25"
                  id="home-tab-pane"
                  role="tabpanel"
                  aria-labelledby="home-tab"
                  tabindex="0"
                >
                  <div className="d-flex flex-column gap-4">
                    <div className="d-flex flex-column flex-md-row gap-2 justify-content-between">
                      <div className="d-flex gap-2 align-items-center number-siz-title"></div>
                      <div>
                        <Link to="/incident" className="cbtn">
                          Create <i className="fal fa-plus"></i>
                        </Link>
                      </div>
                    </div>

                    <div id="mid-open" className="row gap-3">
                      <div className="open col-12">
                        <div className="d-flex gap-3 align-items-center">
                          <i className="fal fa-ellipsis-v"></i>
                          <p>Open</p>
                          <div className="six">
                            <p>{getincidentDataLength}</p>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        {Array.isArray(getincidentData) ? (
                          getincidentData.map((item, index) => (
                            <div className="col-md-6 mt-2">
                              <div className="test position-relative">
                                <div className="d-flex justify-content-between">
                                  <div className="d-flex flex-column gap-3">
                                    {item.status !== "Closed" && (
                                      <Link
                                        to={`/incident/about/${item.unique_code}`}
                                      >
                                        {item.reference}
                                      </Link>
                                    )}
                                    {item.status === "Closed" && (
                                      <h6>{item.reference}</h6>
                                    )}
                                    <div className="d-flex flex-column gap-2 test-info">
                                      <p>{item.site_step4}</p>
                                      <p>{item.locations}</p>
                                      <p>
                                        <strong>Status:</strong> {item.status}
                                      </p>
                                      <p>
                                        Date:{" "}
                                        {item.step4_date
                                          ? getdattime(item.step4_date)
                                          : ""}
                                      </p>
                                    </div>
                                  </div>
                                  {item.status !== "Closed" && (
                                    <div className="pop-up">
                                      <button
                                        className="btn dropdown-toggle"
                                        type="button"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false"
                                        onClick={() => incidentedit(index)}
                                      >
                                        <i className="fal fa-ellipsis-v"></i>
                                      </button>

                                      <ul
                                        className={`dropdown-menu ${
                                          openDropdownIndexIncident === index
                                            ? "show"
                                            : ""
                                        } dropdown-menu-end p-0`}
                                      >
                                        <li>
                                          <Link
                                            className="dropdown-item"
                                            style={{ fontSize: "12px" }}
                                            to={`/incident/about/${item.unique_code}`}
                                          >
                                            <i className="fal fa-edit"></i> Edit
                                          </Link>
                                        </li>
                                        <li>
                                          <Link
                                            className="dropdown-item text-danger"
                                            style={{ fontSize: "13px" }}
                                            to="javascript:void(0)"
                                            onClick={() =>
                                              removeIncident(
                                                item.id,
                                                index,
                                                item.unique_code
                                              )
                                            }
                                          >
                                            <i className="fal fa-trash-alt"></i>{" "}
                                            Delete
                                          </Link>
                                        </li>
                                      </ul>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          ))
                        ) : (
                          <Link> No data available </Link>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="tab-pane fade p-3 border border-secondary border-opacity-25"
                  id="profile-tab-pane"
                  role="tabpanel"
                  aria-labelledby="profile-tab"
                  tabindex="0"
                >
                  <div className="d-flex flex-column gap-4">
                    <div className="d-flex flex-column flex-md-row gap-2 justify-content-between">
                      <div className="d-flex gap-2 align-items-center number-siz-title">
                        <div className="flex-grow-1"></div>
                      </div>
                      <div>
                        <Link to="/near-miss/detail" className="cbtn">
                          Create <i className="fal fa-plus"></i>
                        </Link>
                      </div>
                    </div>

                    <div id="mid-open" className="row gap-3">
                      <div className="open col-12">
                        <div className="d-flex gap-3 align-items-center">
                          <i className="fal fa-ellipsis-v"></i>
                          <p>Open</p>
                          <div className="six">
                            <p>{getnearDataLength}</p>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        {Array.isArray(getneartData) ? (
                          getneartData.map((item, index) => (
                            <div className="col-md-6 mt-2">
                              <div className="test position-relative">
                                <div className="d-flex justify-content-between">
                                  <div className="d-flex flex-column gap-3">
                                    {item.status !== "Closed" && (
                                      <Link
                                        to={`/near-miss/detail/${item.unique_code}`}
                                      >
                                        {item.reference}
                                      </Link>
                                    )}
                                    {item.status === "Closed" && (
                                      <h6>{item.reference}</h6>
                                    )}
                                    <div className="d-flex flex-column gap-2 test-info">
                                      <p>{item.site_step4}</p>
                                      <p>{item.locations}</p>
                                      <p>
                                        <strong>Status:</strong> {item.status}
                                      </p>
                                      <p>
                                        Date:{" "}
                                        {item.step4_date
                                          ? getdattime(item.step4_date)
                                          : ""}
                                      </p>
                                    </div>
                                  </div>
                                  {item.status !== "Closed" && (
                                    <div className="pop-up">
                                      <button
                                        className="btn dropdown-toggle"
                                        type="button"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false"
                                        onClick={() => nearmissedit(index)}
                                      >
                                        <i className="fal fa-ellipsis-v"></i>
                                      </button>

                                      <ul
                                        className={`dropdown-menu ${
                                          openDropdownIndexMiss === index
                                            ? "show"
                                            : ""
                                        } dropdown-menu-end p-0`}
                                      >
                                        <li>
                                          <Link
                                            className="dropdown-item"
                                            style={{ fontSize: "12px" }}
                                            to={`/near-miss/detail/${item.unique_code}`}
                                          >
                                            <i className="fal fa-edit"></i> Edit
                                          </Link>
                                        </li>
                                        <li>
                                          <Link
                                            className="dropdown-item text-danger"
                                            style={{ fontSize: "13px" }}
                                            to="javascript:void(0)"
                                            onClick={() =>
                                              removeMiss(
                                                item.id,
                                                index,
                                                item.unique_code
                                              )
                                            }
                                          >
                                            <i className="fal fa-trash-alt"></i>{" "}
                                            Delete
                                          </Link>
                                        </li>
                                      </ul>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          ))
                        ) : (
                          <Link> No data available </Link>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="tab-pane fade p-3 border border-secondary border-opacity-25"
                  id="report-tab-pane"
                  role="tabpanel"
                  aria-labelledby="report-tab"
                  tabindex="0"
                >
                  <div className="d-flex flex-column gap-4">
                    <div className="d-flex flex-column flex-md-row gap-2 justify-content-between">
                      <div className="d-flex gap-2 align-items-center number-siz-title">
                        <div className="flex-grow-1"></div>
                      </div>
                      <div>
                        <Link to="/reporthazard" className="cbtn">
                          Create <i className="fal fa-plus"></i>
                        </Link>
                      </div>
                    </div>

                    <div className="top-input d-flex gap-3">
                      <div className="site">
                        <p>Site</p>

                        <Select
                          required
                          options={issetgetLocations}
                          name="hazard_site"
                          onChange={searchhazard}
                          id="site"
                        />
                      </div>
                      <div className="date">
                        <p>Date created</p>
                        <input type="date" onChange={filterdate} id="date" />
                      </div>
                    </div>
                    <div id="mid-open" className="row gap-3">
                      <div className="open col-12">
                        <div className="d-flex gap-3 align-items-center">
                          <i className="fal fa-ellipsis-v"></i>
                          <p>Open</p>
                          <div className="six">
                            <p>{isgetallhazardl}</p>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        {Array.isArray(isgetallhazard) ? (
                          isgetallhazard.map((item, index) => (
                            <div className="col-md-6 mt-2">
                              <div className="test position-relative">
                                <div className="d-flex justify-content-between">
                                  <div className="d-flex flex-column gap-3">
                                    <Link
                                      to={`/reporthazard/edit/${item.unique_code}`}
                                    >
                                      {item.name}
                                    </Link>
                                    <div className="d-flex flex-column gap-2 test-info">
                                      <p>
                                        <strong>Site:</strong>{" "}
                                        {item.hazard_site}
                                      </p>
                                      <p>
                                        <strong>Created date:</strong>{" "}
                                        {formatDate_notes(item.created_at)}
                                      </p>
                                      <p>
                                        <strong>Reporter:</strong>{" "}
                                        {item.reporter}
                                      </p>
                                      <p>
                                        <strong>Owner:</strong> Unassigned
                                      </p>
                                    </div>
                                  </div>
                                  <div className="pop-up">
                                    <button
                                      className="btn dropdown-toggle"
                                      type="button"
                                      data-bs-toggle="dropdown"
                                      aria-expanded="false"
                                      onClick={() => hazardedit(index)}
                                    >
                                      <i className="fal fa-ellipsis-v"></i>
                                    </button>

                                    <ul
                                      className={`dropdown-menu ${
                                        openDropdownIndex === index
                                          ? "show"
                                          : ""
                                      } dropdown-menu-end p-0`}
                                    >
                                      <li>
                                        <Link
                                          className="dropdown-item"
                                          style={{ fontSize: "12px" }}
                                          to={`/reporthazard/edit/${item.unique_code}`}
                                        >
                                          <i className="fal fa-edit"></i> Edit
                                        </Link>
                                      </li>
                                      <li>
                                        <Link
                                          className="dropdown-item text-danger"
                                          style={{ fontSize: "13px" }}
                                          to="javascript:void(0)"
                                          onClick={() =>
                                            remover(
                                              item.id,
                                              index,
                                              item.unique_code
                                            )
                                          }
                                        >
                                          <i className="fal fa-trash-alt"></i>{" "}
                                          Delete
                                        </Link>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))
                        ) : (
                          <Link> No data available </Link>
                        )}
                      </div>
                    </div>
                  </div>
                </div> */}

                <div
                  className="tab-pane fade p-3 border border-secondary border-opacity-25"
                  id="document-tab-pane"
                  role="tabpanel"
                  aria-labelledby="document-tab"
                  tabindex="0"
                >
                  <div className="d-flex flex-column gap-4">
                    <div className="d-flex flex-column flex-md-row gap-2 justify-content-between">
                      <div className="d-flex gap-2 align-items-center number-siz-title">
                        {/* <div className="flex-shrink-0">
                          <div className="num-six">
                            <div className="circle-small">
                              <div className="text">
                                {getincidentDataLength}
                                <br />
                              </div>
                            </div>
                          </div>
                        </div> */}
                        <div className="flex-grow-1"></div>
                      </div>
                      {/* <div>
                        <Link
                          to="javascript:void(0)"
                          onClick={uploaddoc}
                          className="cbtn"
                        >
                          Upload Document <i className="fal fa-plus"></i>
                        </Link>
                      </div> */}
                      <Modal
                        isOpen={isModalOpenDoc}
                        onRequestClose={() => setIsModalOpenDoc(false)}
                        contentLabel="Add Text Modal"
                        className="docs d-flex flex-column  bg-light mt-5 p-3 align-item-center justify-content-center"
                      >
                        <div style={{ width: "500px" }}>
                          <div className="d-flex flex-column file-pop">
                            <div className="d-flex justify-content-between align-items-center top-head">
                              <div className="flex-grow-1">
                                <h2>Upload</h2>
                              </div>
                              <div className="flex-shirnk-0">
                                <button
                                  type="button"
                                  onClick={() => closeDoc()}
                                  className="close-btn"
                                >
                                  <i
                                    className="fal fa-times"
                                    aria-hidden="true"
                                  ></i>
                                </button>
                              </div>
                            </div>
                            <div className="d-flex flex-column px-1 py-2">
                              <h3 className="text-black choose-text">
                                Choose thsssse document you would like to
                                upload.
                              </h3>
                              {fileshow && (
                                <div className="sceenshot-box my-1 d-flex align-items-center border-secondary border border-opacity-25 bg-secondary bg-opacity-25 py-1 px-3">
                                  <div className="flex-grow-1">
                                    <h4>{fileshow.name}</h4>{" "}
                                    {/* Display the file name */}
                                  </div>
                                  <div className="flex-shrink-0">
                                    <button
                                      type="button"
                                      className="scsbtn"
                                      onClick={() => handleRemoveFile()}
                                    >
                                      <i className="fal fa-trash-alt"></i>{" "}
                                      {/* Button to remove the file */}
                                    </button>
                                  </div>
                                </div>
                              )}
                              <form
                                action="javascript:void(0)"
                                method="post"
                                onSubmit={handsubmitcategory}
                              >
                                <div className="d-flex flex-column text-center gap-3 rel p-4 drop-box-pop mx-auto ">
                                  <i className="fal fa-arrow-alt-up"></i>
                                  <h1>
                                    Drag and drop your files here to upload
                                  </h1>
                                  <h6>Browse files...</h6>
                                  <input
                                    type="file"
                                    multiple
                                    onChange={docfile}
                                    ref={inputFileRef}
                                    className="file-name-block"
                                  />
                                </div>
                                <div class="d-flex flex-column gap-3 drag-box">
                                  <div class="d-flex flex-column gap-1">
                                    <label for="">Category</label>
                                    <Select
                                      options={docscategory}
                                      value={iscategoryvl}
                                      name="category"
                                      onChange={handcategory}
                                    />
                                  </div>
                                  <div class="d-flex flex-column gap-1">
                                    <label for="">Site</label>
                                    <Select
                                      required={diss ? false : true}
                                      disabled={diss}
                                      options={issetgetLocations}
                                      name="form_site"
                                      value={issetgetLocationsingle}
                                      onChange={formdite}
                                      className={` ${
                                        diss === true ? "sitesdocs" : ""
                                      } `}
                                    />
                                  </div>
                                  <div class="d-flex gap-2 check-box-list">
                                    <div class="flex-shrink-0">
                                      <input
                                        type="checkbox"
                                        name="assign"
                                        onChange={assigntoall}
                                      />
                                    </div>
                                    <div class="flex-grow-1">
                                      <h6>Assign to all sites</h6>
                                    </div>
                                  </div>
                                </div>
                                {docs_s && (
                                  <span class="text-success">
                                    Document has been successfully saved
                                  </span>
                                )}
                                <div className="d-flex flex-column gap-3 drag-box">
                                  <div className="d-flex gap-2 check-box-list">
                                    <div className="d-flex gap-2 align-items-center justify-content-end btns-box mt-4">
                                      <div className="flex-shrink-0">
                                        <button
                                          type="button"
                                          onClick={() => closeDoc()}
                                        >
                                          Cancel
                                        </button>
                                      </div>
                                      <div className="flex-shrink-0">
                                        <button type="submit">Upload</button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                      </Modal>
                    </div>
                    <div className="d-flex flex-column gap-2 align-items-start number-siz-title">
                      <table className="table tableprimary doctable">
                        <thead>
                          <th>Title</th>
                          <th>Category</th>
                          <th>Sites</th>
                          <th>Date Created</th>
                        </thead>
                        <tbody className="trbody">
                          {Array.isArray(issetgetalldocuments) ? (
                            issetgetalldocuments.map((item, index) => (
                              <tr className="even">
                                <td>
                                  <Link
                                    to="javascript:void(0)"
                                    onClick={() =>
                                      handleDownload(
                                        item.file,
                                        `https://jlmining.app/uploads/documents/${item.file}`
                                      )
                                    }
                                  >
                                    {item.file}
                                  </Link>
                                </td>
                                <td>{item.category}</td>
                                <td>
                                  {item.assign_to === "Yes" ? "All" : item.site}
                                </td>
                                <td>{formatDate_notes(item.created_at)}</td>
                              </tr>
                            ))
                          ) : (
                            <div> No data available </div>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="learning-block d-block py-5">
        <div className="container-lg">
          <div className="row gy-4">
            <div className="col-12">
              <Link to="/ohs" className="btn btn-secondary mb-4">
                Back
              </Link>
              <div className="d-flex align-items-center gap-2 elearn-title">
                <div className="d-flex gap-2">
                  <div className="d-flex justify-content-between">
                    <h3>E-Learning hub</h3>
                  </div>
                </div>
              </div>
            </div>
            {Array.isArray(issetiselarning) ? (
              issetiselarning.length > 0 ? (
                issetiselarning.map((item, index) => (
                  <React.Fragment key={index}>
                    <div className="col-md-3">
                      <Link
                        to={`/start-course/${item.unique_code}`}
                        target="_blank"
                        className="cur-block d-flex flex-column gap-1 p-2"
                      >
                        <div className="cur-img ofit">
                          <img src={apiurl + "/" + item.file} alt="image" />
                        </div>
                        <div className="d-flex flex-column gap-2 stext-block h-100">
                          <div className="d-flex gap-2 flex-column title-heading-box mt-2">
                            <h2>{item.title}</h2>
                            <p className="truncate-text">{item.description}</p>
                          </div>
                          <div className="d-flex mt-4">
                            <button type="button" className="startcourse">
                              Start Course
                            </button>
                          </div>
                        </div>
                      </Link>
                    </div>
                  </React.Fragment>
                ))
              ) : (
                <div className="no-data-message">No Courses Available</div>
              )
            ) : (
              <div className="no-data-message">Data is not an array</div>
            )}
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};
