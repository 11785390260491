import React, { useRef, useState, useEffect } from "react";
import axios from "axios";
import { message, Form, Input, Button, Alert } from "antd";
import { Link, useNavigate } from "react-router-dom";
import Modal from "react-modal";
import { FaPlus, FaTrash } from "react-icons/fa";
import { Headeronboarding } from "./Headeronboarding";
import { Footer } from "./Footer";
export const Onboardingfiles = () => {
  const inputFileRef = useRef(null);
  const currentYear = new Date().getFullYear();
  const navigate = useNavigate();
  const [docUp1, setdocUp1] = useState(false);
  const [docUp2, setdocUp2] = useState(false);
  const [Licsucc, set_Licsucc] = useState(false);
  const [SelectedFile_doc, setSelectedFile_doc] = useState(null);
  const [SelectedFile_docarray, setSelectedFile_docarray] = useState(null);
  const [docs_s, setdocs_s] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [valueUserDetail, setValuegetuser] = useState([]);
  const [openmannualModal, setopenmannualModal] = useState(false);
  const [fileshow, setfilename] = useState("");
  const [diss_s, setdiss_s] = useState(true);
  const [succ, setsucc] = useState(false);
  const [fields, setFields] = useState([
    { name: "", licence_number: "", expiryMonth: "", expiryYear: "" },
  ]);
  const handleLogout = () => {
    sessionStorage.removeItem("users");
    window.location.href = "/login";
  };
  const changeFavicon = (url) => {
    const favicon = document.querySelector('link[rel="icon"]');
    if (favicon) {
      favicon.href = url;
    }
  };

  useEffect(() => {
    changeFavicon(require("./assets/admin/images/f-icon.png"));
    const userStr = JSON.parse(localStorage.getItem("users"));
    console.log("ch");
    console.log(userStr);
    if (userStr === null) {
      console.log("cc");
      window.location.href = "/login";
    }
    document.title = "Files Page";
    let formdata = {
      user_id: userStr[0].id,
    };
    axios
      .post("https://jlmining.app/admin/getuserdetails", formdata, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        var alldata = res.data.row[0];

        setValuegetuser(alldata);
      })
      .catch((err) => {});
    console.log(SelectedFile_doc);
  }, [SelectedFile_doc]);
  const [values, setValues] = useState({
    name: "",
    email: "",
    password: "",
    contact: "",
    address: "",
  });
  const [showAlert, setShowAlert] = useState(false);
  const [showAlert_time, setShowAlert_time] = useState(false);
  const [showPass, setShowPass] = useState(false);
  const [val, setVal] = useState("");
  const handleInput = (event) => {
    setVal((v) => (event.target.validity.valid ? event.target.value : v));
    setValues((prev) => ({
      ...prev,
      [event.target.name]: [event.target.value],
    }));
  };
  const handleFileChange = (event) => {
    if (event.target.files[0] != null) {
      setSelectedFile(event.target.files[0]);
      handleprofile_update(event.target.files[0]);
    }
  };
  const handleprofile_update = (file) => {
    const userStr = JSON.parse(localStorage.getItem("users"));
    const formData = new FormData();
    formData.append("file", file);
    formData.append("user_id", userStr[0].id);
    axios
      .post("https://jlmining.app/admin/timesheetupload", formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        if (res.data.status == "1") {
          setShowAlert_time(true);
          setShowAlert_time({ type: "success" });
        } else {
          setShowAlert_time(true);
          setShowAlert_time({ type: "error" });
        }
        setTimeout(() => {
          setShowAlert_time(false);
          window.location.reload();
        }, 3500);
      })
      .catch((err) => {});
  };

  const fname = valueUserDetail.first_name;
  const mname = valueUserDetail.middle_name;
  const lname = valueUserDetail.last_name;
  const fLetter = fname ? fname.charAt(0) : "";
  const mLetter = mname ? mname.charAt(0) : "";
  const lLetter = lname ? lname.charAt(0) : "";

  const mannualModal = (event) => {
    setopenmannualModal(true);
  };
  const closeDoc = () => {
    if (inputFileRef.current) {
      inputFileRef.current.value = "";
    }
    setfilename("");
    setopenmannualModal(false);
  };
  const handsubmitcategory = (event) => {
    const userStr = JSON.parse(localStorage.getItem("users"));
    var formData = new FormData();

    formData.append("user_id", userStr[0].id);
    formData.append("file", fileshow);
    axios
      .post("https://jlmining.app/mannualDocumentsave", formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        setdocs_s(true);
        setdiss_s(true);

        if (inputFileRef.current) {
          inputFileRef.current.value = "";
        }
        setfilename("");
        setTimeout(() => {
          setdocs_s(false);
          setopenmannualModal(false);
          event.target.reset();
        }, 4500);
      })
      .catch((err) => {});
  };
  const docfile = (event) => {
    const file = event.target.files[0]; // Get the first file from the input
    setdiss_s(false);
    setfilename(file);
  };
  const handleRemoveFile = (event) => {
    if (inputFileRef.current) {
      inputFileRef.current.value = "";
    }
    setfilename("");
    setdiss_s(true);
  };
  const handleSubmit = (event) => {
    setdocUp1(true);
    event.preventDefault();
    console.log(SelectedFile_doc);
    const userStr = JSON.parse(localStorage.getItem("users"));
    const formDataa = new FormData();
    if (SelectedFile_doc !== null) {
      if (SelectedFile_doc.length > 0) {
        for (let i = 0; i < SelectedFile_doc.length; i++) {
          formDataa.append("files", SelectedFile_doc[i]);
        }
      }
    }
    formDataa.append("user_id", userStr[0].id);
    axios
      .post("https://jlmining.app/userUpdateDocfile", formDataa, {
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        console.log(res.data);
        if (res.data.status === 1) {
          setsucc(true);
          event.target.reset();
          setSelectedFile_doc([]);
          setSelectedFile_docarray([]);
        }
        setdocUp1(false);
      })
      .catch((err) => {});
  };
  const handleFileChange_doc = (event) => {
    if (event.target.files != null) {
      const files = event.target.files;
      setSelectedFile_doc(event.target.files);
      setSelectedFile_docarray(Array.from(files));
    }
  };
  const handleAddMore = () => {
    setFields([
      ...fields,
      { name: "", licence_number: "", expiryMonth: "", expiryYear: "" },
    ]);
  };
  const handleChange = (index, e) => {
    const { name, value } = e.target;
    const updatedFields = [...fields];
    updatedFields[index][name] = value;
    setFields(updatedFields);
  };
  const handleRemove = (indexToRemove) => {
    setFields(fields.filter((_, index) => index !== indexToRemove));
  };
  const handleSubmit_lic = (event) => {
    setdocUp2(true);
    event.preventDefault();

    const formData = new FormData();
    const userStr = JSON.parse(localStorage.getItem("users"));
    console.log(fields);
    fields.forEach((field) => {
      formData.append("name[]", field.name);

      formData.append("licence_number[]", field.licence_number);
      formData.append("expiryMonth[]", field.expiryMonth);
      formData.append("expiryYear[]", field.expiryYear);
      formData.append("file", field.selectedFile); // Append the selected file
    });
    formData.append("user_id", userStr[0].id);
    axios
      .post("https://jlmining.app/userlicence", formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        console.log(res.data);

        set_Licsucc(true);
        event.target.reset();
        setTimeout(function () {
          setdocUp2(false);
          window.location.reload();
        }, 3500);
      })
      .catch((err) => {});
  };
  const handleFileChange_muti = (event, index) => {
    const selectedFile = event.target.files[0];
    const updatedFields = [...fields];
    updatedFields[index]["selectedFile"] = selectedFile;
    setFields(updatedFields);
  };
  return (
    <>
      <Headeronboarding />
      <div className="fullscreen-onboarding-file ">
        <div className="container-lg">
          <div className="d-flex flex-column gap-5">
            <div className="d-flex flex-column gap-5 pt-5">
              <div className="d-flex align-items-center gap-4">
                <Link to="/onboarding">Back</Link>
                <h2 className="text-center w-100">Upload Documents</h2>
              </div>
              {succ && (
                <p className="text-success ">File uploaded successfully</p>
              )}
              <form
                action="javascript:void(0)"
                onSubmit={handleSubmit}
                method="post"
              >
                <div className="d-flex align-items-center justify-content-center gap-5 upload">
                  <div className="d-block">
                    <div className="overflow-hidden d-flex gap-2 position-relative flex-column justify-content-center align-items-center w-fit-content gap-2">
                      <img
                        src={require("./assets/images/263116.png")}
                        width="100"
                        alt="Image"
                      />

                      <input
                        required
                        className="files"
                        onChange={handleFileChange_doc}
                        type="file"
                        multiple
                        name="file"
                      />
                      <p>Upload Files</p>
                    </div>
                  </div>
                  <div className="d-flex flex-column gap-3">
                    <div className="d-flex flex-column gap-2">
                      {Array.isArray(SelectedFile_docarray) &&
                        SelectedFile_docarray.map((item, index) => (
                          <Link className="d-block w-fit-content">
                            {item.name}
                          </Link>
                        ))}
                    </div>
                    <div className="submit-btn-files">
                      <button
                        type="submit"
                        className={`${docUp1 === true ? "docup" : ""}`}
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div className="d-flex flex-column justify-content-center align-items-center gap-3 border-file py-5">
              <h3 className="text-center w-100">Upload Licence</h3>
              {Licsucc && (
                <p className="text-success">
                  Licence file uploaded successfully
                </p>
              )}
              <form
                action="javascript:void(0)"
                method="post"
                onSubmit={handleSubmit_lic}
              >
                <div className="d-flex flex-column gap-3">
                  {fields.map((field, index) => (
                    <div key={index} className="d-flex flex-column gap-3">
                      {index !== 0 && (
                        <Link
                          to="javascript:void(0)"
                          className="text-danger float-end"
                          onClick={() => handleRemove(index)}
                        >
                          <FaTrash />
                        </Link>
                      )}
                      <div className="d-flex align-items-center gap-3">
                        <label>Enter Name of the licence</label>
                        <input
                          type="text"
                          name="name"
                          required
                          value={field.name}
                          onChange={(e) => handleChange(index, e)}
                        />
                      </div>
                      <div className="d-flex align-items-center justify-content-between gap-3">
                        <label>Enter Licence Number</label>
                        <input
                          type="text"
                          name="licence_number"
                          required
                          defaultValue={field.number}
                          onChange={(e) => handleChange(index, e)}
                        />
                      </div>
                      <div className="d-flex align-items-center justify-content-between gap-3 ">
                        <label>Expiry Date</label>
                        <div className="d-flex align-items-center gap-3 date">
                          <input
                            type="text"
                            placeholder="MM"
                            name="expiryMonth"
                            required
                            pattern="[0-9]*"
                            min="1"
                            maxLength="2"
                            value={field.expiryMonth}
                            onChange={(e) => handleChange(index, e)}
                          />
                          <input
                            type="text"
                            placeholder="YYYY"
                            pattern="[0-9]*"
                            required
                            min="1"
                            maxLength="4"
                            name="expiryYear"
                            value={field.expiryYear}
                            onChange={(e) => handleChange(index, e)}
                          />
                        </div>
                      </div>
                      <div className="d-flex justify-content-center align-items-center">
                        <div className="upload-btn position-relative">
                          <p>Upload</p>
                          <input
                            type="file"
                            required
                            name="lic_file"
                            className="upload-btn-file"
                            onChange={(e) => handleFileChange_muti(e, index)}
                          />
                        </div>
                      </div>
                    </div>
                  ))}
                  <div className="d-flex flex-column gap-3">
                    <div className="d-block text-center  ">
                      <Link
                        className="aad-item-file"
                        href="javascript:void(0)"
                        onClick={handleAddMore}
                      >
                        Add More +
                      </Link>
                    </div>
                    <div className="sub-btn-file d-flex justify-content-center align-items-center ">
                      <button
                        type="submit"
                        className={`${docUp2 === true ? "docup" : ""}`}
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};
