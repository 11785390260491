import React, { useRef, useState, useEffect } from "react";
import axios from "axios";
import { message, Form, Input, Button, Alert } from "antd";
import { useNavigate, Link } from "react-router-dom";
import Select from "react-select";
import TextArea from "antd/es/input/TextArea";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";

import { FaTrash } from "react-icons/fa";
// import '@fullcalendar/core/main.css';
// import '@fullcalendar/daygrid/main.css';
import AdminHeader from "./AdminHeader";
import AdminFooter from "./AdminFooter";

export const Documents = () => {
  const inputFileRef = useRef(null);
  const navigate = useNavigate();
  const changeFavicon = (url) => {
    const favicon = document.querySelector('link[rel="icon"]');
    if (favicon) {
      favicon.href = url;
    }
  };
  const [diss_as, setdiss_as] = useState(false);
  const [diss_s, setdiss_s] = useState(true);
  const [docs_s, setdocs_s] = useState(false);
  const [issetgetLocations, setgetLocations] = useState([]);
  const [issetgetLocationsingle, setgetLocationsingle] = useState("");
  const [docscategory, setdocscategory] = useState([]);
  const [diss, setdiss] = useState(false);
  const [iscategoryvl, setcategoryvl] = useState("");
  const [values, setValues] = useState({});
  const [val, setVal] = useState("");
  const [fileshow, setfilename] = useState("");
  const [docsto, setdocsto] = useState([]);
  const [isaddocsto, setisaddocsto] = useState("");
  const handleInput = (event) => {
    setVal((v) => (event.target.validity.valid ? event.target.value : v));
    setValues((prev) => ({
      ...prev,
      [event.target.name]: [event.target.value],
    }));
  };

  useEffect(() => {
    changeFavicon(require("../assets/admin/images/f-icon.png"));
    document.title = "Previous Work Page";
    const userStr = JSON.parse(localStorage.getItem("admin"));
    console.log(userStr);
    if (userStr == null) {
      navigate("/admin/login");
    }
    let formdata = {
      user_id: "",
    };

    axios
      .post("https://jlmining.app/getlocationohs", formdata, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        console.log("locations");
        console.log(res.data.results);
        if (res.data.results.length > 0) {
          const options = res.data.results.map((item) => ({
            value: item.location_name,
            label: item.location_name,
          }));
          setgetLocations(options);
        }
      })
      .catch((err) => {});
    const options_category = [
      { value: "CHECKLIST", label: "Checklist" },
      { value: "FIRE_SAFETY", label: "Fire Safety" },
      { value: "FIRST_AID", label: "First Aid" },
      { value: "INCIDENT_RECORD", label: "Incident Record" },
      { value: "METHOD_STATEMENT", label: "Method Statement" },
      { value: "MISC", label: "Misc" },
      { value: "PHOTOGRAPH", label: "Photograph" },
      { value: "PROCEDURE", label: "Procedure" },
      { value: "REPORT", label: "Report" },
      { value: "RESPONSIBILITY", label: "Responsibility" },
      { value: "RISK_ASSESSMENT", label: "Risk Assessment" },
      { value: "SAFETY_DATA_SHEET", label: "Safety Data Sheet" },
      { value: "SAFETY_RECORD", label: "Safety Record" },
      { value: "SAFE_WORK_PROCEDURE", label: "Safe Work Procedure" },
      { value: "TOOLBOX_TALK", label: "Tool-box Talk" },
    ];
    setdocscategory(options_category);
    const addto = [
      { value: "OHS", label: "Document" },
      { value: "User Mannual", label: "User Manual" },
      { value: "Site Access", label: "Site Access" },
    ];
    setdocsto(addto);
  }, []);
  const handleRemoveFile = (event) => {
    if (inputFileRef.current) {
      inputFileRef.current.value = "";
    }
    setfilename("");
    setdiss_s(true);
  };
  //Get User Time Sheet
  const assigntoall = (event) => {
    console.log(event.target.checked);
    if (event.target.checked === true) {
      setdiss(true);
    } else {
      setdiss(false);
    }
  };
  const formdite = (event) => {
    setgetLocationsingle({
      value: event.value,
      label: event.value,
    });
    console.log(event.value);
    if (event.value !== "") {
      setdiss_as(true);
    } else {
      setdiss_as(false);
    }
  };
  const handcategory = (event) => {
    console.log(event);
    setcategoryvl(event);
  };
  const docfile = (event) => {
    const file = event.target.files[0]; // Get the first file from the input
    setdiss_s(false);
    setfilename(file);
  };
  const handdocsto = (event) => {
    console.log(event.value);
    setisaddocsto(event);
  };
  const handsubmitcategory = (event) => {
    var formData = new FormData();
    console.log(diss);
    if (diss == true) {
      var assign = "Yes";
      var fsite = event.target.form_site.value;
    } else {
      var assign = "No";
      var fsite = event.target.form_site.value;
    }
    formData.append("category", event.target.category.value);
    formData.append("file", fileshow);
    formData.append("form_site", fsite);
    formData.append("type", event.target.type.value);
    formData.append("assign", assign);
    console.log(formData);
    axios
      .post("https://jlmining.app/documentsave", formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        setdocs_s(true);
        setTimeout(() => {
          setdocs_s(false);
          setfilename("");
          setcategoryvl("");
          setgetLocationsingle("");
          if (inputFileRef.current) {
            inputFileRef.current.value = "";
          }
          event.target.reset();
          window.location.reload();
        }, 4500);
      })
      .catch((err) => {});
  };
  return (
    <>
      <div>
        <AdminHeader />
        <div className="main-right-body">
          <div className="block-full">
            <div className="row gy-4">
              <div className="col-md-6">
                <div className="right-form-box bg-white shadow p-2">
                  <div className="d-flex flex-column file-pop">
                    <div className="d-flex justify-content-between align-items-center top-head">
                      <div className="flex-grow-1">
                        <h2 className="text-white">Upload</h2>
                      </div>
                      <div className="flex-shirnk-0"></div>
                    </div>
                    <div className="d-flex flex-column px-1 py-2">
                      <h3 className="text-black choose-text">
                        Choose the document you would like to upload.
                      </h3>
                      {fileshow && (
                        <div className="sceenshot-box my-1 d-flex align-items-center border-secondary border border-opacity-25 bg-secondary bg-opacity-25 py-1 px-3">
                          <div className="flex-grow-1">
                            <h4>{fileshow.name}</h4>{" "}
                            {/* Display the file name */}
                          </div>
                          <div className="flex-shrink-0">
                            <button
                              type="button"
                              className="scsbtn"
                              onClick={() => handleRemoveFile()}
                            >
                              <i className="fal fa-trash-alt"></i>{" "}
                              {/* Button to remove the file */}
                            </button>
                          </div>
                        </div>
                      )}
                      <form
                        action="javascript:void(0)"
                        method="post"
                        onSubmit={handsubmitcategory}
                      >
                        <div className="d-flex flex-column text-center gap-3 rel p-4 drop-box-pop mx-auto ">
                          <i className="fal fa-arrow-alt-up"></i>
                          <h1>Drag and drop your files here to upload</h1>
                          <h6>Browse files...</h6>
                          <input
                            type="file"
                            multiple
                            onChange={docfile}
                            ref={inputFileRef}
                            className="file-name-block"
                          />
                        </div>
                        <div class="d-flex flex-column gap-3 drag-box">
                          <div class="d-flex flex-column gap-1">
                            <label for="">Add Document to</label>
                            <Select
                              options={docsto}
                              value={isaddocsto}
                              name="type"
                              onChange={handdocsto}
                            />
                          </div>
                          <div class="d-flex flex-column gap-1">
                            <label for="">Category</label>
                            <Select
                              options={docscategory}
                              value={iscategoryvl}
                              name="category"
                              onChange={handcategory}
                            />
                          </div>
                          <div class="d-flex flex-column gap-1">
                            <label for="">Site</label>
                            <Select
                              required={diss ? false : true}
                              disabled={diss}
                              options={issetgetLocations}
                              name="form_site"
                              value={issetgetLocationsingle}
                              onChange={formdite}
                              className={` ${
                                diss === true ? "sitesdocs" : ""
                              } `}
                            />
                          </div>
                          <div class="d-flex gap-2 check-box-list">
                            <div class="flex-shrink-0">
                              <input
                                type="checkbox"
                                name="assign"
                                className={` ${
                                  diss_as === true ? "assitesdocs" : ""
                                } `}
                                onChange={assigntoall}
                              />
                            </div>
                            <div class="flex-grow-1">
                              <h6>Assign to all sites</h6>
                            </div>
                          </div>
                        </div>
                        {docs_s && (
                          <span class="text-success">
                            Document has been successfully saved
                          </span>
                        )}
                        <div className="d-flex flex-column gap-3 drag-box">
                          <div className="d-flex gap-2 check-box-list">
                            <div className="d-flex gap-2 align-items-center justify-content-end btns-box mt-4">
                              <div className="flex-shrink-0">
                                <button
                                  type="submit"
                                  disabled={diss_s}
                                  className={`${
                                    diss_s === true ? "sites_docs" : ""
                                  }`}
                                >
                                  Upload
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <AdminFooter />
      </div>
    </>
  );
};
