import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link, useParams } from "react-router-dom";
import "../assets/ohs/admin.css";
export const Startcourse_leftmenu = () => {
  const [unicode, setunicode] = useState("");
  const [isCourse, setCourse] = useState("");
  const { id } = useParams();
  const changeFavicon = (url) => {
    const favicon = document.querySelector('link[rel="icon"]');
    if (favicon) {
      favicon.href = url;
    }
  };

  useEffect(() => {
    const userStr = JSON.parse(localStorage.getItem("users"));

    if (userStr === null) {
      window.location.href = "/login";
    }
    changeFavicon(require("../assets/admin/images/f-icon.png"));

    document.title = "Start Course";
    if (id === undefined) {
      setunicode("");
      window.location.href = "/login";
    } else {
      setunicode(id);
    }
    console.log("sd");
    console.log(id);
    let formdata = {
      code: id,
    };
    axios
      .post("https://jlmining.app/getcourseDetail", formdata, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        console.log(res.data);
        console.log("cor");
        if (res.data.row.length > 0) {
          setCourse(res.data.row[0]);
        }
      })
      .catch((err) => {});
  }, [id]);

  return (
    <>
      {isCourse.slug === "worker-induction-emergency-preparedness" && (
        <div className="d-flex flex-column mt-5">
          <Link
            to="javascript:void(0)"
            className="d-flex align-items-center gap-3 list-link anilink"
          >
            <div className="flex-shrink-0">
              <i className="far fa-align-left"></i>
            </div>
            <div className="flex-grow-1">
              <h4>Introduction And Legislation</h4>
            </div>
            <div className="flex-shrink-0">
              <div className="circle-small">
                <svg>
                  <circle className="bg" cx="40" cy="40" r="37"></circle>
                  <circle
                    className="progress two"
                    cx="40"
                    cy="40"
                    r="37"
                  ></circle>
                </svg>
              </div>
            </div>
          </Link>
          <Link
            to="javascript:void(0)"
            className="d-flex align-items-center gap-3 list-link disable-box rel"
            data-bs-toggle="tooltip"
            data-bs-title="Lessons must be completed in order"
          >
            <div className="flex-shrink-0">
              <i className="far fa-align-left"></i>
            </div>
            <div className="flex-grow-1">
              <h4>Worker Responsibilities</h4>
            </div>
            <div className="flex-shrink-0">
              <div className="circle-small">
                <svg>
                  <circle className="bg" cx="40" cy="40" r="37"></circle>
                  <circle
                    className="progress two"
                    cx="40"
                    cy="40"
                    r="37"
                  ></circle>
                </svg>
              </div>
            </div>
          </Link>
          <Link
            to="javascript:void(0)"
            className="d-flex align-items-center gap-3 list-link disable-box rel"
            data-bs-toggle="tooltip"
            data-bs-title="Lessons must be completed in order"
          >
            <div className="flex-shrink-0">
              <i className="far fa-align-left"></i>
            </div>
            <div className="flex-grow-1">
              <h4>Emergency Planning and Procedures Awareness</h4>
            </div>
            <div className="flex-shrink-0">
              <div className="circle-small">
                <svg>
                  <circle className="bg" cx="40" cy="40" r="37"></circle>
                  <circle
                    className="progress two"
                    cx="40"
                    cy="40"
                    r="37"
                  ></circle>
                </svg>
              </div>
            </div>
          </Link>
          <Link
            to="javascript:void(0)"
            className="d-flex align-items-center gap-3 list-link disable-box rel"
            data-bs-toggle="tooltip"
            data-bs-title="Lessons must be completed in order"
          >
            <div className="flex-shrink-0">
              <i className="far fa-align-left"></i>
            </div>
            <div className="flex-grow-1">
              <h4>The Fire Tetrahedron And Extinguisher Types</h4>
            </div>
            <div className="flex-shrink-0">
              <div className="circle-small">
                <svg>
                  <circle className="bg" cx="40" cy="40" r="37"></circle>
                  <circle
                    className="progress two"
                    cx="40"
                    cy="40"
                    r="37"
                  ></circle>
                </svg>
              </div>
            </div>
          </Link>
          <Link
            to="javascript:void(0)"
            className="d-flex align-items-center gap-3 list-link disable-box rel"
            data-bs-toggle="tooltip"
            data-bs-title="Lessons must be completed in order"
          >
            <div className="flex-shrink-0">
              <i className="far fa-align-left"></i>
            </div>
            <div className="flex-grow-1">
              <h4>How to response to an emergency</h4>
            </div>
            <div className="flex-shrink-0">
              <div className="circle-small">
                <svg>
                  <circle className="bg" cx="40" cy="40" r="37"></circle>
                  <circle
                    className="progress two"
                    cx="40"
                    cy="40"
                    r="37"
                  ></circle>
                </svg>
              </div>
            </div>
          </Link>
          <Link
            to="javascript:void(0)"
            className="d-flex align-items-center gap-3 list-link disable-box rel"
            data-bs-toggle="tooltip"
            data-bs-title="Lessons must be completed in order"
          >
            <div className="flex-shrink-0">
              <i className="far fa-align-left"></i>
            </div>
            <div className="flex-grow-1">
              <h4>Final Assessment</h4>
            </div>
            <div className="flex-shrink-0">
              <div className="circle-small">
                <svg>
                  <circle className="bg" cx="40" cy="40" r="37"></circle>
                  <circle
                    className="progress two"
                    cx="40"
                    cy="40"
                    r="37"
                  ></circle>
                </svg>
              </div>
            </div>
          </Link>
        </div>
      )}
      {isCourse.slug === "health-and-safety-responsibilities" && (
        <div className="d-flex flex-column mt-5">
          <Link
            to="javascript:void(0)"
            className="d-flex align-items-center gap-3 list-link anilink"
          >
            <div className="flex-shrink-0">
              <i className="far fa-align-left"></i>
            </div>
            <div className="flex-grow-1">
              <h4>Starting Question</h4>
            </div>
            <div className="flex-shrink-0">
              <div className="circle-small">
                <svg>
                  <circle className="bg" cx="40" cy="40" r="37"></circle>
                  <circle
                    className="progress two"
                    cx="40"
                    cy="40"
                    r="37"
                  ></circle>
                </svg>
              </div>
            </div>
          </Link>
          <Link
            to="javascript:void(0)"
            className="d-flex align-items-center gap-3 list-link disable-box rel"
            data-bs-toggle="tooltip"
            data-bs-title="Lessons must be completed in order"
          >
            <div className="flex-shrink-0">
              <i className="far fa-align-left"></i>
            </div>
            <div className="flex-grow-1">
              <h4>Overview of Health & Safety Legislation</h4>
            </div>
            <div className="flex-shrink-0">
              <div className="circle-small">
                <svg>
                  <circle className="bg" cx="40" cy="40" r="37"></circle>
                  <circle
                    className="progress two"
                    cx="40"
                    cy="40"
                    r="37"
                  ></circle>
                </svg>
              </div>
            </div>
          </Link>
          <Link
            to="javascript:void(0)"
            className="d-flex align-items-center gap-3 list-link disable-box rel"
            data-bs-toggle="tooltip"
            data-bs-title="Lessons must be completed in order"
          >
            <div className="flex-shrink-0">
              <i className="far fa-align-left"></i>
            </div>
            <div className="flex-grow-1">
              <h4>Organisation Responsibilities</h4>
            </div>
            <div className="flex-shrink-0">
              <div className="circle-small">
                <svg>
                  <circle className="bg" cx="40" cy="40" r="37"></circle>
                  <circle
                    className="progress two"
                    cx="40"
                    cy="40"
                    r="37"
                  ></circle>
                </svg>
              </div>
            </div>
          </Link>
          <Link
            to="javascript:void(0)"
            className="d-flex align-items-center gap-3 list-link disable-box rel"
            data-bs-toggle="tooltip"
            data-bs-title="Lessons must be completed in order"
          >
            <div className="flex-shrink-0">
              <i className="far fa-align-left"></i>
            </div>
            <div className="flex-grow-1">
              <h4>Worker Responsibilities</h4>
            </div>
            <div className="flex-shrink-0">
              <div className="circle-small">
                <svg>
                  <circle className="bg" cx="40" cy="40" r="37"></circle>
                  <circle
                    className="progress two"
                    cx="40"
                    cy="40"
                    r="37"
                  ></circle>
                </svg>
              </div>
            </div>
          </Link>
          <Link
            to="javascript:void(0)"
            className="d-flex align-items-center gap-3 list-link disable-box rel"
            data-bs-toggle="tooltip"
            data-bs-title="Lessons must be completed in order"
          >
            <div className="flex-shrink-0">
              <i className="far fa-align-left"></i>
            </div>
            <div className="flex-grow-1">
              <h4>Final Assessment</h4>
            </div>
            <div className="flex-shrink-0">
              <div className="circle-small">
                <svg>
                  <circle className="bg" cx="40" cy="40" r="37"></circle>
                  <circle
                    className="progress two"
                    cx="40"
                    cy="40"
                    r="37"
                  ></circle>
                </svg>
              </div>
            </div>
          </Link>
        </div>
      )}
      {isCourse.slug === "health-and-safety-awareness" && (
        <div className="d-flex flex-column mt-5">
          <Link
            to="javascript:void(0)"
            className="d-flex align-items-center gap-3 list-link anilink"
          >
            <div className="flex-shrink-0">
              <i className="far fa-align-left"></i>
            </div>
            <div className="flex-grow-1">
              <h4>Inroduction</h4>
            </div>
            <div className="flex-shrink-0">
              <div className="circle-small">
                <svg>
                  <circle className="bg" cx="40" cy="40" r="37"></circle>
                  <circle
                    className="progress two"
                    cx="40"
                    cy="40"
                    r="37"
                  ></circle>
                </svg>
              </div>
            </div>
          </Link>
          <Link
            to="javascript:void(0)"
            className="d-flex align-items-center gap-3 list-link disable-box rel"
            data-bs-toggle="tooltip"
            data-bs-title="Lessons must be completed in order"
          >
            <div className="flex-shrink-0">
              <i className="far fa-align-left"></i>
            </div>
            <div className="flex-grow-1">
              <h4>Laws</h4>
            </div>
            <div className="flex-shrink-0">
              <div className="circle-small">
                <svg>
                  <circle className="bg" cx="40" cy="40" r="37"></circle>
                  <circle
                    className="progress two"
                    cx="40"
                    cy="40"
                    r="37"
                  ></circle>
                </svg>
              </div>
            </div>
          </Link>
          <Link
            to="javascript:void(0)"
            className="d-flex align-items-center gap-3 list-link disable-box rel"
            data-bs-toggle="tooltip"
            data-bs-title="Lessons must be completed in order"
          >
            <div className="flex-shrink-0">
              <i className="far fa-align-left"></i>
            </div>
            <div className="flex-grow-1">
              <h4>Human Aspects</h4>
            </div>
            <div className="flex-shrink-0">
              <div className="circle-small">
                <svg>
                  <circle className="bg" cx="40" cy="40" r="37"></circle>
                  <circle
                    className="progress two"
                    cx="40"
                    cy="40"
                    r="37"
                  ></circle>
                </svg>
              </div>
            </div>
          </Link>
          <Link
            to="javascript:void(0)"
            className="d-flex align-items-center gap-3 list-link disable-box rel"
            data-bs-toggle="tooltip"
            data-bs-title="Lessons must be completed in order"
          >
            <div className="flex-shrink-0">
              <i className="far fa-align-left"></i>
            </div>
            <div className="flex-grow-1">
              <h4>Safety Signs</h4>
            </div>
            <div className="flex-shrink-0">
              <div className="circle-small">
                <svg>
                  <circle className="bg" cx="40" cy="40" r="37"></circle>
                  <circle
                    className="progress two"
                    cx="40"
                    cy="40"
                    r="37"
                  ></circle>
                </svg>
              </div>
            </div>
          </Link>
          <Link
            to="javascript:void(0)"
            className="d-flex align-items-center gap-3 list-link disable-box rel"
            data-bs-toggle="tooltip"
            data-bs-title="Lessons must be completed in order"
          >
            <div className="flex-shrink-0">
              <i className="far fa-align-left"></i>
            </div>
            <div className="flex-grow-1">
              <h4>Quiz at Mid-Point</h4>
            </div>
            <div className="flex-shrink-0">
              <div className="circle-small">
                <svg>
                  <circle className="bg" cx="40" cy="40" r="37"></circle>
                  <circle
                    className="progress two"
                    cx="40"
                    cy="40"
                    r="37"
                  ></circle>
                </svg>
              </div>
            </div>
          </Link>
          <Link
            to="javascript:void(0)"
            className="d-flex align-items-center gap-3 list-link disable-box rel"
            data-bs-toggle="tooltip"
            data-bs-title="Lessons must be completed in order"
          >
            <div className="flex-shrink-0">
              <i className="far fa-align-left"></i>
            </div>
            <div className="flex-grow-1">
              <h4>Workplace Incidents</h4>
            </div>
            <div className="flex-shrink-0">
              <div className="circle-small">
                <svg>
                  <circle className="bg" cx="40" cy="40" r="37"></circle>
                  <circle
                    className="progress two"
                    cx="40"
                    cy="40"
                    r="37"
                  ></circle>
                </svg>
              </div>
            </div>
          </Link>
          <Link
            to="javascript:void(0)"
            className="d-flex align-items-center gap-3 list-link disable-box rel"
            data-bs-toggle="tooltip"
            data-bs-title="Lessons must be completed in order"
          >
            <div className="flex-shrink-0">
              <i className="far fa-align-left"></i>
            </div>
            <div className="flex-grow-1">
              <h4>How to Report an Injury</h4>
            </div>
            <div className="flex-shrink-0">
              <div className="circle-small">
                <svg>
                  <circle className="bg" cx="40" cy="40" r="37"></circle>
                  <circle
                    className="progress two"
                    cx="40"
                    cy="40"
                    r="37"
                  ></circle>
                </svg>
              </div>
            </div>
          </Link>
          <Link
            to="javascript:void(0)"
            className="d-flex align-items-center gap-3 list-link disable-box rel"
            data-bs-toggle="tooltip"
            data-bs-title="Lessons must be completed in order"
          >
            <div className="flex-shrink-0">
              <i className="far fa-align-left"></i>
            </div>
            <div className="flex-grow-1">
              <h4>Some Common Workplace Hazards</h4>
            </div>
            <div className="flex-shrink-0">
              <div className="circle-small">
                <svg>
                  <circle className="bg" cx="40" cy="40" r="37"></circle>
                  <circle
                    className="progress two"
                    cx="40"
                    cy="40"
                    r="37"
                  ></circle>
                </svg>
              </div>
            </div>
          </Link>
          <Link
            to="javascript:void(0)"
            className="d-flex align-items-center gap-3 list-link disable-box rel"
            data-bs-toggle="tooltip"
            data-bs-title="Lessons must be completed in order"
          >
            <div className="flex-shrink-0">
              <i className="far fa-align-left"></i>
            </div>
            <div className="flex-grow-1">
              <h4>Final Assessment</h4>
            </div>
            <div className="flex-shrink-0">
              <div className="circle-small">
                <svg>
                  <circle className="bg" cx="40" cy="40" r="37"></circle>
                  <circle
                    className="progress two"
                    cx="40"
                    cy="40"
                    r="37"
                  ></circle>
                </svg>
              </div>
            </div>
          </Link>
        </div>
      )}
      {isCourse.slug === "workplace-mental-health-awareness" && (
        <div className="d-flex flex-column mt-5">
          <Link
            to="javascript:void(0)"
            className="d-flex align-items-center gap-3 list-link anilink"
          >
            <div className="flex-shrink-0">
              <i className="far fa-align-left"></i>
            </div>
            <div className="flex-grow-1">
              <h4>Inroduction</h4>
            </div>
            <div className="flex-shrink-0">
              <div className="circle-small">
                <svg>
                  <circle className="bg" cx="40" cy="40" r="37"></circle>
                  <circle
                    className="progress two"
                    cx="40"
                    cy="40"
                    r="37"
                  ></circle>
                </svg>
              </div>
            </div>
          </Link>
          <Link
            to="javascript:void(0)"
            className="d-flex align-items-center gap-3 list-link disable-box rel"
            data-bs-toggle="tooltip"
            data-bs-title="Lessons must be completed in order"
          >
            <div className="flex-shrink-0">
              <i className="far fa-align-left"></i>
            </div>
            <div className="flex-grow-1">
              <h4>Recognizing Psychosocial Hazards</h4>
            </div>
            <div className="flex-shrink-0">
              <div className="circle-small">
                <svg>
                  <circle className="bg" cx="40" cy="40" r="37"></circle>
                  <circle
                    className="progress two"
                    cx="40"
                    cy="40"
                    r="37"
                  ></circle>
                </svg>
              </div>
            </div>
          </Link>
          <Link
            to="javascript:void(0)"
            className="d-flex align-items-center gap-3 list-link disable-box rel"
            data-bs-toggle="tooltip"
            data-bs-title="Lessons must be completed in order"
          >
            <div className="flex-shrink-0">
              <i className="far fa-align-left"></i>
            </div>
            <div className="flex-grow-1">
              <h4>Evaluating the Risks Linked to Psychosocial Hazards</h4>
            </div>
            <div className="flex-shrink-0">
              <div className="circle-small">
                <svg>
                  <circle className="bg" cx="40" cy="40" r="37"></circle>
                  <circle
                    className="progress two"
                    cx="40"
                    cy="40"
                    r="37"
                  ></circle>
                </svg>
              </div>
            </div>
          </Link>
          <Link
            to="javascript:void(0)"
            className="d-flex align-items-center gap-3 list-link disable-box rel"
            data-bs-toggle="tooltip"
            data-bs-title="Lessons must be completed in order"
          >
            <div className="flex-shrink-0">
              <i className="far fa-align-left"></i>
            </div>
            <div className="flex-grow-1">
              <h4>Managing the Hazards</h4>
            </div>
            <div className="flex-shrink-0">
              <div className="circle-small">
                <svg>
                  <circle className="bg" cx="40" cy="40" r="37"></circle>
                  <circle
                    className="progress two"
                    cx="40"
                    cy="40"
                    r="37"
                  ></circle>
                </svg>
              </div>
            </div>
          </Link>
          <Link
            to="javascript:void(0)"
            className="d-flex align-items-center gap-3 list-link disable-box rel"
            data-bs-toggle="tooltip"
            data-bs-title="Lessons must be completed in order"
          >
            <div className="flex-shrink-0">
              <i className="far fa-align-left"></i>
            </div>
            <div className="flex-grow-1">
              <h4>Monitoring and Evaluating Control Measures</h4>
            </div>
            <div className="flex-shrink-0">
              <div className="circle-small">
                <svg>
                  <circle className="bg" cx="40" cy="40" r="37"></circle>
                  <circle
                    className="progress two"
                    cx="40"
                    cy="40"
                    r="37"
                  ></circle>
                </svg>
              </div>
            </div>
          </Link>
          <Link
            to="javascript:void(0)"
            className="d-flex align-items-center gap-3 list-link disable-box rel"
            data-bs-toggle="tooltip"
            data-bs-title="Lessons must be completed in order"
          >
            <div className="flex-shrink-0">
              <i className="far fa-align-left"></i>
            </div>
            <div className="flex-grow-1">
              <h4>Quiz</h4>
            </div>
            <div className="flex-shrink-0">
              <div className="circle-small">
                <svg>
                  <circle className="bg" cx="40" cy="40" r="37"></circle>
                  <circle
                    className="progress two"
                    cx="40"
                    cy="40"
                    r="37"
                  ></circle>
                </svg>
              </div>
            </div>
          </Link>
          <Link
            to="javascript:void(0)"
            className="d-flex align-items-center gap-3 list-link disable-box rel"
            data-bs-toggle="tooltip"
            data-bs-title="Lessons must be completed in order"
          >
            <div className="flex-shrink-0">
              <i className="far fa-align-left"></i>
            </div>
            <div className="flex-grow-1">
              <h4>Addressing a Report of a Psychosocial Risk or Incident</h4>
            </div>
            <div className="flex-shrink-0">
              <div className="circle-small">
                <svg>
                  <circle className="bg" cx="40" cy="40" r="37"></circle>
                  <circle
                    className="progress two"
                    cx="40"
                    cy="40"
                    r="37"
                  ></circle>
                </svg>
              </div>
            </div>
          </Link>
          <Link
            to="javascript:void(0)"
            className="d-flex align-items-center gap-3 list-link disable-box rel"
            data-bs-toggle="tooltip"
            data-bs-title="Lessons must be completed in order"
          >
            <div className="flex-shrink-0">
              <i className="far fa-align-left"></i>
            </div>
            <div className="flex-grow-1">
              <h4>Supporting Recovery</h4>
            </div>
            <div className="flex-shrink-0">
              <div className="circle-small">
                <svg>
                  <circle className="bg" cx="40" cy="40" r="37"></circle>
                  <circle
                    className="progress two"
                    cx="40"
                    cy="40"
                    r="37"
                  ></circle>
                </svg>
              </div>
            </div>
          </Link>
          <Link
            to="javascript:void(0)"
            className="d-flex align-items-center gap-3 list-link disable-box rel"
            data-bs-toggle="tooltip"
            data-bs-title="Lessons must be completed in order"
          >
            <div className="flex-shrink-0">
              <i className="far fa-align-left"></i>
            </div>
            <div className="flex-grow-1">
              <h4>Final Assessment</h4>
            </div>
            <div className="flex-shrink-0">
              <div className="circle-small">
                <svg>
                  <circle className="bg" cx="40" cy="40" r="37"></circle>
                  <circle
                    className="progress two"
                    cx="40"
                    cy="40"
                    r="37"
                  ></circle>
                </svg>
              </div>
            </div>
          </Link>
        </div>
      )}
      {isCourse.slug === "display-screen-equipment" && (
        <div className="d-flex flex-column mt-5">
          <Link
            to="javascript:void(0)"
            className="d-flex align-items-center gap-3 list-link anilink"
          >
            <div className="flex-shrink-0">
              <i className="far fa-align-left"></i>
            </div>
            <div className="flex-grow-1">
              <h4>Inroduction</h4>
            </div>
            <div className="flex-shrink-0">
              <div className="circle-small">
                <svg>
                  <circle className="bg" cx="40" cy="40" r="37"></circle>
                  <circle
                    className="progress two"
                    cx="40"
                    cy="40"
                    r="37"
                  ></circle>
                </svg>
              </div>
            </div>
          </Link>
          <Link
            to="javascript:void(0)"
            className="d-flex align-items-center gap-3 list-link disable-box rel"
            data-bs-toggle="tooltip"
            data-bs-title="Lessons must be completed in order"
          >
            <div className="flex-shrink-0">
              <i className="far fa-align-left"></i>
            </div>
            <div className="flex-grow-1">
              <h4>Health Effects</h4>
            </div>
            <div className="flex-shrink-0">
              <div className="circle-small">
                <svg>
                  <circle className="bg" cx="40" cy="40" r="37"></circle>
                  <circle
                    className="progress two"
                    cx="40"
                    cy="40"
                    r="37"
                  ></circle>
                </svg>
              </div>
            </div>
          </Link>
          <Link
            to="javascript:void(0)"
            className="d-flex align-items-center gap-3 list-link disable-box rel"
            data-bs-toggle="tooltip"
            data-bs-title="Lessons must be completed in order"
          >
            <div className="flex-shrink-0">
              <i className="far fa-align-left"></i>
            </div>
            <div className="flex-grow-1">
              <h4>Workstations</h4>
            </div>
            <div className="flex-shrink-0">
              <div className="circle-small">
                <svg>
                  <circle className="bg" cx="40" cy="40" r="37"></circle>
                  <circle
                    className="progress two"
                    cx="40"
                    cy="40"
                    r="37"
                  ></circle>
                </svg>
              </div>
            </div>
          </Link>
          <Link
            to="javascript:void(0)"
            className="d-flex align-items-center gap-3 list-link disable-box rel"
            data-bs-toggle="tooltip"
            data-bs-title="Lessons must be completed in order"
          >
            <div className="flex-shrink-0">
              <i className="far fa-align-left"></i>
            </div>
            <div className="flex-grow-1">
              <h4>Display Screen Equipment - Fatigue Management</h4>
            </div>
            <div className="flex-shrink-0">
              <div className="circle-small">
                <svg>
                  <circle className="bg" cx="40" cy="40" r="37"></circle>
                  <circle
                    className="progress two"
                    cx="40"
                    cy="40"
                    r="37"
                  ></circle>
                </svg>
              </div>
            </div>
          </Link>

          <Link
            to="javascript:void(0)"
            className="d-flex align-items-center gap-3 list-link disable-box rel"
            data-bs-toggle="tooltip"
            data-bs-title="Lessons must be completed in order"
          >
            <div className="flex-shrink-0">
              <i className="far fa-align-left"></i>
            </div>
            <div className="flex-grow-1">
              <h4>Final Assessment</h4>
            </div>
            <div className="flex-shrink-0">
              <div className="circle-small">
                <svg>
                  <circle className="bg" cx="40" cy="40" r="37"></circle>
                  <circle
                    className="progress two"
                    cx="40"
                    cy="40"
                    r="37"
                  ></circle>
                </svg>
              </div>
            </div>
          </Link>
        </div>
      )}
      {isCourse.slug === "hazardous-chemicals" && (
        <div className="d-flex flex-column mt-5">
          <Link
            to="javascript:void(0)"
            className="d-flex align-items-center gap-3 list-link anilink"
          >
            <div className="flex-shrink-0">
              <i className="far fa-align-left"></i>
            </div>
            <div className="flex-grow-1">
              <h4>Inroduction</h4>
            </div>
            <div className="flex-shrink-0">
              <div className="circle-small">
                <svg>
                  <circle className="bg" cx="40" cy="40" r="37"></circle>
                  <circle
                    className="progress two"
                    cx="40"
                    cy="40"
                    r="37"
                  ></circle>
                </svg>
              </div>
            </div>
          </Link>
          <Link
            to="javascript:void(0)"
            className="d-flex align-items-center gap-3 list-link disable-box rel"
            data-bs-toggle="tooltip"
            data-bs-title="Lessons must be completed in order"
          >
            <div className="flex-shrink-0">
              <i className="far fa-align-left"></i>
            </div>
            <div className="flex-grow-1">
              <h4>Legislation</h4>
            </div>
            <div className="flex-shrink-0">
              <div className="circle-small">
                <svg>
                  <circle className="bg" cx="40" cy="40" r="37"></circle>
                  <circle
                    className="progress two"
                    cx="40"
                    cy="40"
                    r="37"
                  ></circle>
                </svg>
              </div>
            </div>
          </Link>
          <Link
            to="javascript:void(0)"
            className="d-flex align-items-center gap-3 list-link disable-box rel"
            data-bs-toggle="tooltip"
            data-bs-title="Lessons must be completed in order"
          >
            <div className="flex-shrink-0">
              <i className="far fa-align-left"></i>
            </div>
            <div className="flex-grow-1">
              <h4>Workplace Exposure Standards</h4>
            </div>
            <div className="flex-shrink-0">
              <div className="circle-small">
                <svg>
                  <circle className="bg" cx="40" cy="40" r="37"></circle>
                  <circle
                    className="progress two"
                    cx="40"
                    cy="40"
                    r="37"
                  ></circle>
                </svg>
              </div>
            </div>
          </Link>
          <Link
            to="javascript:void(0)"
            className="d-flex align-items-center gap-3 list-link disable-box rel"
            data-bs-toggle="tooltip"
            data-bs-title="Lessons must be completed in order"
          >
            <div className="flex-shrink-0">
              <i className="far fa-align-left"></i>
            </div>
            <div className="flex-grow-1">
              <h4>Identifying Hazardous Chemicals</h4>
            </div>
            <div className="flex-shrink-0">
              <div className="circle-small">
                <svg>
                  <circle className="bg" cx="40" cy="40" r="37"></circle>
                  <circle
                    className="progress two"
                    cx="40"
                    cy="40"
                    r="37"
                  ></circle>
                </svg>
              </div>
            </div>
          </Link>

          <Link
            to="javascript:void(0)"
            className="d-flex align-items-center gap-3 list-link disable-box rel"
            data-bs-toggle="tooltip"
            data-bs-title="Lessons must be completed in order"
          >
            <div className="flex-shrink-0">
              <i className="far fa-align-left"></i>
            </div>
            <div className="flex-grow-1">
              <h4>Managing The Risk</h4>
            </div>
            <div className="flex-shrink-0">
              <div className="circle-small">
                <svg>
                  <circle className="bg" cx="40" cy="40" r="37"></circle>
                  <circle
                    className="progress two"
                    cx="40"
                    cy="40"
                    r="37"
                  ></circle>
                </svg>
              </div>
            </div>
          </Link>
          <Link
            to="javascript:void(0)"
            className="d-flex align-items-center gap-3 list-link disable-box rel"
            data-bs-toggle="tooltip"
            data-bs-title="Lessons must be completed in order"
          >
            <div className="flex-shrink-0">
              <i className="far fa-align-left"></i>
            </div>
            <div className="flex-grow-1">
              <h4>Health Monitoring</h4>
            </div>
            <div className="flex-shrink-0">
              <div className="circle-small">
                <svg>
                  <circle className="bg" cx="40" cy="40" r="37"></circle>
                  <circle
                    className="progress two"
                    cx="40"
                    cy="40"
                    r="37"
                  ></circle>
                </svg>
              </div>
            </div>
          </Link>
          <Link
            to="javascript:void(0)"
            className="d-flex align-items-center gap-3 list-link disable-box rel"
            data-bs-toggle="tooltip"
            data-bs-title="Lessons must be completed in order"
          >
            <div className="flex-shrink-0">
              <i className="far fa-align-left"></i>
            </div>
            <div className="flex-grow-1">
              <h4>Further Control Measures</h4>
            </div>
            <div className="flex-shrink-0">
              <div className="circle-small">
                <svg>
                  <circle className="bg" cx="40" cy="40" r="37"></circle>
                  <circle
                    className="progress two"
                    cx="40"
                    cy="40"
                    r="37"
                  ></circle>
                </svg>
              </div>
            </div>
          </Link>
          <Link
            to="javascript:void(0)"
            className="d-flex align-items-center gap-3 list-link disable-box rel"
            data-bs-toggle="tooltip"
            data-bs-title="Lessons must be completed in order"
          >
            <div className="flex-shrink-0">
              <i className="far fa-align-left"></i>
            </div>
            <div className="flex-grow-1">
              <h4>Fire and Explosion Risks</h4>
            </div>
            <div className="flex-shrink-0">
              <div className="circle-small">
                <svg>
                  <circle className="bg" cx="40" cy="40" r="37"></circle>
                  <circle
                    className="progress two"
                    cx="40"
                    cy="40"
                    r="37"
                  ></circle>
                </svg>
              </div>
            </div>
          </Link>
          <Link
            to="javascript:void(0)"
            className="d-flex align-items-center gap-3 list-link disable-box rel"
            data-bs-toggle="tooltip"
            data-bs-title="Lessons must be completed in order"
          >
            <div className="flex-shrink-0">
              <i className="far fa-align-left"></i>
            </div>
            <div className="flex-grow-1">
              <h4>Final Assessment</h4>
            </div>
            <div className="flex-shrink-0">
              <div className="circle-small">
                <svg>
                  <circle className="bg" cx="40" cy="40" r="37"></circle>
                  <circle
                    className="progress two"
                    cx="40"
                    cy="40"
                    r="37"
                  ></circle>
                </svg>
              </div>
            </div>
          </Link>
        </div>
      )}
      {isCourse.slug === "manual-handling" && (
        <div className="d-flex flex-column mt-5">
          <Link
            to="javascript:void(0)"
            className="d-flex align-items-center gap-3 list-link anilink"
          >
            <div className="flex-shrink-0">
              <i className="far fa-align-left"></i>
            </div>
            <div className="flex-grow-1">
              <h4>Inroduction</h4>
            </div>
            <div className="flex-shrink-0">
              <div className="circle-small">
                <svg>
                  <circle className="bg" cx="40" cy="40" r="37"></circle>
                  <circle
                    className="progress two"
                    cx="40"
                    cy="40"
                    r="37"
                  ></circle>
                </svg>
              </div>
            </div>
          </Link>
          <Link
            to="javascript:void(0)"
            className="d-flex align-items-center gap-3 list-link disable-box rel"
            data-bs-toggle="tooltip"
            data-bs-title="Lessons must be completed in order"
          >
            <div className="flex-shrink-0">
              <i className="far fa-align-left"></i>
            </div>
            <div className="flex-grow-1">
              <h4>Legislation</h4>
            </div>
            <div className="flex-shrink-0">
              <div className="circle-small">
                <svg>
                  <circle className="bg" cx="40" cy="40" r="37"></circle>
                  <circle
                    className="progress two"
                    cx="40"
                    cy="40"
                    r="37"
                  ></circle>
                </svg>
              </div>
            </div>
          </Link>
          <Link
            to="javascript:void(0)"
            className="d-flex align-items-center gap-3 list-link disable-box rel"
            data-bs-toggle="tooltip"
            data-bs-title="Lessons must be completed in order"
          >
            <div className="flex-shrink-0">
              <i className="far fa-align-left"></i>
            </div>
            <div className="flex-grow-1">
              <h4>Common Postures And Movements That Can Lead To Injury</h4>
            </div>
            <div className="flex-shrink-0">
              <div className="circle-small">
                <svg>
                  <circle className="bg" cx="40" cy="40" r="37"></circle>
                  <circle
                    className="progress two"
                    cx="40"
                    cy="40"
                    r="37"
                  ></circle>
                </svg>
              </div>
            </div>
          </Link>
          <Link
            to="javascript:void(0)"
            className="d-flex align-items-center gap-3 list-link disable-box rel"
            data-bs-toggle="tooltip"
            data-bs-title="Lessons must be completed in order"
          >
            <div className="flex-shrink-0">
              <i className="far fa-align-left"></i>
            </div>
            <div className="flex-grow-1">
              <h4>Common Manual Handling Injuries</h4>
            </div>
            <div className="flex-shrink-0">
              <div className="circle-small">
                <svg>
                  <circle className="bg" cx="40" cy="40" r="37"></circle>
                  <circle
                    className="progress two"
                    cx="40"
                    cy="40"
                    r="37"
                  ></circle>
                </svg>
              </div>
            </div>
          </Link>

          <Link
            to="javascript:void(0)"
            className="d-flex align-items-center gap-3 list-link disable-box rel"
            data-bs-toggle="tooltip"
            data-bs-title="Lessons must be completed in order"
          >
            <div className="flex-shrink-0">
              <i className="far fa-align-left"></i>
            </div>
            <div className="flex-grow-1">
              <h4>Before Undertaking A Manual Handling Task</h4>
            </div>
            <div className="flex-shrink-0">
              <div className="circle-small">
                <svg>
                  <circle className="bg" cx="40" cy="40" r="37"></circle>
                  <circle
                    className="progress two"
                    cx="40"
                    cy="40"
                    r="37"
                  ></circle>
                </svg>
              </div>
            </div>
          </Link>
          <Link
            to="javascript:void(0)"
            className="d-flex align-items-center gap-3 list-link disable-box rel"
            data-bs-toggle="tooltip"
            data-bs-title="Lessons must be completed in order"
          >
            <div className="flex-shrink-0">
              <i className="far fa-align-left"></i>
            </div>
            <div className="flex-grow-1">
              <h4>Correct Lifting Techniques</h4>
            </div>
            <div className="flex-shrink-0">
              <div className="circle-small">
                <svg>
                  <circle className="bg" cx="40" cy="40" r="37"></circle>
                  <circle
                    className="progress two"
                    cx="40"
                    cy="40"
                    r="37"
                  ></circle>
                </svg>
              </div>
            </div>
          </Link>
          <Link
            to="javascript:void(0)"
            className="d-flex align-items-center gap-3 list-link disable-box rel"
            data-bs-toggle="tooltip"
            data-bs-title="Lessons must be completed in order"
          >
            <div className="flex-shrink-0">
              <i className="far fa-align-left"></i>
            </div>
            <div className="flex-grow-1">
              <h4>Final Assessment</h4>
            </div>
            <div className="flex-shrink-0">
              <div className="circle-small">
                <svg>
                  <circle className="bg" cx="40" cy="40" r="37"></circle>
                  <circle
                    className="progress two"
                    cx="40"
                    cy="40"
                    r="37"
                  ></circle>
                </svg>
              </div>
            </div>
          </Link>
        </div>
      )}
      {isCourse.slug === "asbestos-awareness" && (
        <div className="d-flex flex-column mt-5">
          <Link
            to="javascript:void(0)"
            className="d-flex align-items-center gap-3 list-link anilink"
          >
            <div className="flex-shrink-0">
              <i className="far fa-align-left"></i>
            </div>
            <div className="flex-grow-1">
              <h4>Inroduction</h4>
            </div>
            <div className="flex-shrink-0">
              <div className="circle-small">
                <svg>
                  <circle className="bg" cx="40" cy="40" r="37"></circle>
                  <circle
                    className="progress two"
                    cx="40"
                    cy="40"
                    r="37"
                  ></circle>
                </svg>
              </div>
            </div>
          </Link>
          <Link
            to="javascript:void(0)"
            className="d-flex align-items-center gap-3 list-link disable-box rel"
            data-bs-toggle="tooltip"
            data-bs-title="Lessons must be completed in order"
          >
            <div className="flex-shrink-0">
              <i className="far fa-align-left"></i>
            </div>
            <div className="flex-grow-1">
              <h4>Types Of Asbestos</h4>
            </div>
            <div className="flex-shrink-0">
              <div className="circle-small">
                <svg>
                  <circle className="bg" cx="40" cy="40" r="37"></circle>
                  <circle
                    className="progress two"
                    cx="40"
                    cy="40"
                    r="37"
                  ></circle>
                </svg>
              </div>
            </div>
          </Link>
          <Link
            to="javascript:void(0)"
            className="d-flex align-items-center gap-3 list-link disable-box rel"
            data-bs-toggle="tooltip"
            data-bs-title="Lessons must be completed in order"
          >
            <div className="flex-shrink-0">
              <i className="far fa-align-left"></i>
            </div>
            <div className="flex-grow-1">
              <h4>Health Effects</h4>
            </div>
            <div className="flex-shrink-0">
              <div className="circle-small">
                <svg>
                  <circle className="bg" cx="40" cy="40" r="37"></circle>
                  <circle
                    className="progress two"
                    cx="40"
                    cy="40"
                    r="37"
                  ></circle>
                </svg>
              </div>
            </div>
          </Link>
          <Link
            to="javascript:void(0)"
            className="d-flex align-items-center gap-3 list-link disable-box rel"
            data-bs-toggle="tooltip"
            data-bs-title="Lessons must be completed in order"
          >
            <div className="flex-shrink-0">
              <i className="far fa-align-left"></i>
            </div>
            <div className="flex-grow-1">
              <h4>Legislation</h4>
            </div>
            <div className="flex-shrink-0">
              <div className="circle-small">
                <svg>
                  <circle className="bg" cx="40" cy="40" r="37"></circle>
                  <circle
                    className="progress two"
                    cx="40"
                    cy="40"
                    r="37"
                  ></circle>
                </svg>
              </div>
            </div>
          </Link>

          <Link
            to="javascript:void(0)"
            className="d-flex align-items-center gap-3 list-link disable-box rel"
            data-bs-toggle="tooltip"
            data-bs-title="Lessons must be completed in order"
          >
            <div className="flex-shrink-0">
              <i className="far fa-align-left"></i>
            </div>
            <div className="flex-grow-1">
              <h4>Asbestos Surveys</h4>
            </div>
            <div className="flex-shrink-0">
              <div className="circle-small">
                <svg>
                  <circle className="bg" cx="40" cy="40" r="37"></circle>
                  <circle
                    className="progress two"
                    cx="40"
                    cy="40"
                    r="37"
                  ></circle>
                </svg>
              </div>
            </div>
          </Link>
          <Link
            to="javascript:void(0)"
            className="d-flex align-items-center gap-3 list-link disable-box rel"
            data-bs-toggle="tooltip"
            data-bs-title="Lessons must be completed in order"
          >
            <div className="flex-shrink-0">
              <i className="far fa-align-left"></i>
            </div>
            <div className="flex-grow-1">
              <h4>Emergency Procedures</h4>
            </div>
            <div className="flex-shrink-0">
              <div className="circle-small">
                <svg>
                  <circle className="bg" cx="40" cy="40" r="37"></circle>
                  <circle
                    className="progress two"
                    cx="40"
                    cy="40"
                    r="37"
                  ></circle>
                </svg>
              </div>
            </div>
          </Link>
          <Link
            to="javascript:void(0)"
            className="d-flex align-items-center gap-3 list-link disable-box rel"
            data-bs-toggle="tooltip"
            data-bs-title="Lessons must be completed in order"
          >
            <div className="flex-shrink-0">
              <i className="far fa-align-left"></i>
            </div>
            <div className="flex-grow-1">
              <h4>Final Assessment</h4>
            </div>
            <div className="flex-shrink-0">
              <div className="circle-small">
                <svg>
                  <circle className="bg" cx="40" cy="40" r="37"></circle>
                  <circle
                    className="progress two"
                    cx="40"
                    cy="40"
                    r="37"
                  ></circle>
                </svg>
              </div>
            </div>
          </Link>
        </div>
      )}
      {isCourse.slug === "risk-assessment" && (
        <div className="d-flex flex-column mt-5">
          <Link
            to="javascript:void(0)"
            className="d-flex align-items-center gap-3 list-link anilink"
          >
            <div className="flex-shrink-0">
              <i className="far fa-align-left"></i>
            </div>
            <div className="flex-grow-1">
              <h4>Inroduction</h4>
            </div>
            <div className="flex-shrink-0">
              <div className="circle-small">
                <svg>
                  <circle className="bg" cx="40" cy="40" r="37"></circle>
                  <circle
                    className="progress two"
                    cx="40"
                    cy="40"
                    r="37"
                  ></circle>
                </svg>
              </div>
            </div>
          </Link>
          <Link
            to="javascript:void(0)"
            className="d-flex align-items-center gap-3 list-link disable-box rel"
            data-bs-toggle="tooltip"
            data-bs-title="Lessons must be completed in order"
          >
            <div className="flex-shrink-0">
              <i className="far fa-align-left"></i>
            </div>
            <div className="flex-grow-1">
              <h4>Hazard Identification</h4>
            </div>
            <div className="flex-shrink-0">
              <div className="circle-small">
                <svg>
                  <circle className="bg" cx="40" cy="40" r="37"></circle>
                  <circle
                    className="progress two"
                    cx="40"
                    cy="40"
                    r="37"
                  ></circle>
                </svg>
              </div>
            </div>
          </Link>
          <Link
            to="javascript:void(0)"
            className="d-flex align-items-center gap-3 list-link disable-box rel"
            data-bs-toggle="tooltip"
            data-bs-title="Lessons must be completed in order"
          >
            <div className="flex-shrink-0">
              <i className="far fa-align-left"></i>
            </div>
            <div className="flex-grow-1">
              <h4>Risk Evaluation</h4>
            </div>
            <div className="flex-shrink-0">
              <div className="circle-small">
                <svg>
                  <circle className="bg" cx="40" cy="40" r="37"></circle>
                  <circle
                    className="progress two"
                    cx="40"
                    cy="40"
                    r="37"
                  ></circle>
                </svg>
              </div>
            </div>
          </Link>
          <Link
            to="javascript:void(0)"
            className="d-flex align-items-center gap-3 list-link disable-box rel"
            data-bs-toggle="tooltip"
            data-bs-title="Lessons must be completed in order"
          >
            <div className="flex-shrink-0">
              <i className="far fa-align-left"></i>
            </div>
            <div className="flex-grow-1">
              <h4>Control Measures</h4>
            </div>
            <div className="flex-shrink-0">
              <div className="circle-small">
                <svg>
                  <circle className="bg" cx="40" cy="40" r="37"></circle>
                  <circle
                    className="progress two"
                    cx="40"
                    cy="40"
                    r="37"
                  ></circle>
                </svg>
              </div>
            </div>
          </Link>

          <Link
            to="javascript:void(0)"
            className="d-flex align-items-center gap-3 list-link disable-box rel"
            data-bs-toggle="tooltip"
            data-bs-title="Lessons must be completed in order"
          >
            <div className="flex-shrink-0">
              <i className="far fa-align-left"></i>
            </div>
            <div className="flex-grow-1">
              <h4>Monitor And Review</h4>
            </div>
            <div className="flex-shrink-0">
              <div className="circle-small">
                <svg>
                  <circle className="bg" cx="40" cy="40" r="37"></circle>
                  <circle
                    className="progress two"
                    cx="40"
                    cy="40"
                    r="37"
                  ></circle>
                </svg>
              </div>
            </div>
          </Link>
          <Link
            to="javascript:void(0)"
            className="d-flex align-items-center gap-3 list-link disable-box rel"
            data-bs-toggle="tooltip"
            data-bs-title="Lessons must be completed in order"
          >
            <div className="flex-shrink-0">
              <i className="far fa-align-left"></i>
            </div>
            <div className="flex-grow-1">
              <h4>Final Assessment</h4>
            </div>
            <div className="flex-shrink-0">
              <div className="circle-small">
                <svg>
                  <circle className="bg" cx="40" cy="40" r="37"></circle>
                  <circle
                    className="progress two"
                    cx="40"
                    cy="40"
                    r="37"
                  ></circle>
                </svg>
              </div>
            </div>
          </Link>
        </div>
      )}

      {isCourse.slug === "driving-for-work" && (
        <div className="d-flex flex-column mt-5">
          <Link
            to="javascript:void(0)"
            className="d-flex align-items-center gap-3 list-link anilink"
          >
            <div className="flex-shrink-0">
              <i className="far fa-align-left"></i>
            </div>
            <div className="flex-grow-1">
              <h4>Inroduction</h4>
            </div>
            <div className="flex-shrink-0">
              <div className="circle-small">
                <svg>
                  <circle className="bg" cx="40" cy="40" r="37"></circle>
                  <circle
                    className="progress two"
                    cx="40"
                    cy="40"
                    r="37"
                  ></circle>
                </svg>
              </div>
            </div>
          </Link>
          <Link
            to="javascript:void(0)"
            className="d-flex align-items-center gap-3 list-link disable-box rel"
            data-bs-toggle="tooltip"
            data-bs-title="Lessons must be completed in order"
          >
            <div className="flex-shrink-0">
              <i className="far fa-align-left"></i>
            </div>
            <div className="flex-grow-1">
              <h4>What Causes Road Accidents?</h4>
            </div>
            <div className="flex-shrink-0">
              <div className="circle-small">
                <svg>
                  <circle className="bg" cx="40" cy="40" r="37"></circle>
                  <circle
                    className="progress two"
                    cx="40"
                    cy="40"
                    r="37"
                  ></circle>
                </svg>
              </div>
            </div>
          </Link>
          <Link
            to="javascript:void(0)"
            className="d-flex align-items-center gap-3 list-link disable-box rel"
            data-bs-toggle="tooltip"
            data-bs-title="Lessons must be completed in order"
          >
            <div className="flex-shrink-0">
              <i className="far fa-align-left"></i>
            </div>
            <div className="flex-grow-1">
              <h4>PCBU's Responsibilities</h4>
            </div>
            <div className="flex-shrink-0">
              <div className="circle-small">
                <svg>
                  <circle className="bg" cx="40" cy="40" r="37"></circle>
                  <circle
                    className="progress two"
                    cx="40"
                    cy="40"
                    r="37"
                  ></circle>
                </svg>
              </div>
            </div>
          </Link>
          <Link
            to="javascript:void(0)"
            className="d-flex align-items-center gap-3 list-link disable-box rel"
            data-bs-toggle="tooltip"
            data-bs-title="Lessons must be completed in order"
          >
            <div className="flex-shrink-0">
              <i className="far fa-align-left"></i>
            </div>
            <div className="flex-grow-1">
              <h4> Worker's Responsibilities</h4>
            </div>
            <div className="flex-shrink-0">
              <div className="circle-small">
                <svg>
                  <circle className="bg" cx="40" cy="40" r="37"></circle>
                  <circle
                    className="progress two"
                    cx="40"
                    cy="40"
                    r="37"
                  ></circle>
                </svg>
              </div>
            </div>
          </Link>

          <Link
            to="javascript:void(0)"
            className="d-flex align-items-center gap-3 list-link disable-box rel"
            data-bs-toggle="tooltip"
            data-bs-title="Lessons must be completed in order"
          >
            <div className="flex-shrink-0">
              <i className="far fa-align-left"></i>
            </div>
            <div className="flex-grow-1">
              <h4>Managing The Risks</h4>
            </div>
            <div className="flex-shrink-0">
              <div className="circle-small">
                <svg>
                  <circle className="bg" cx="40" cy="40" r="37"></circle>
                  <circle
                    className="progress two"
                    cx="40"
                    cy="40"
                    r="37"
                  ></circle>
                </svg>
              </div>
            </div>
          </Link>
          <Link
            to="javascript:void(0)"
            className="d-flex align-items-center gap-3 list-link disable-box rel"
            data-bs-toggle="tooltip"
            data-bs-title="Lessons must be completed in order"
          >
            <div className="flex-shrink-0">
              <i className="far fa-align-left"></i>
            </div>
            <div className="flex-grow-1">
              <h4>Safer Driving</h4>
            </div>
            <div className="flex-shrink-0">
              <div className="circle-small">
                <svg>
                  <circle className="bg" cx="40" cy="40" r="37"></circle>
                  <circle
                    className="progress two"
                    cx="40"
                    cy="40"
                    r="37"
                  ></circle>
                </svg>
              </div>
            </div>
          </Link>
          <Link
            to="javascript:void(0)"
            className="d-flex align-items-center gap-3 list-link disable-box rel"
            data-bs-toggle="tooltip"
            data-bs-title="Lessons must be completed in order"
          >
            <div className="flex-shrink-0">
              <i className="far fa-align-left"></i>
            </div>
            <div className="flex-grow-1">
              <h4>Vulnerable Road Users</h4>
            </div>
            <div className="flex-shrink-0">
              <div className="circle-small">
                <svg>
                  <circle className="bg" cx="40" cy="40" r="37"></circle>
                  <circle
                    className="progress two"
                    cx="40"
                    cy="40"
                    r="37"
                  ></circle>
                </svg>
              </div>
            </div>
          </Link>
          <Link
            to="javascript:void(0)"
            className="d-flex align-items-center gap-3 list-link disable-box rel"
            data-bs-toggle="tooltip"
            data-bs-title="Lessons must be completed in order"
          >
            <div className="flex-shrink-0">
              <i className="far fa-align-left"></i>
            </div>
            <div className="flex-grow-1">
              <h4>Final Assessment</h4>
            </div>
            <div className="flex-shrink-0">
              <div className="circle-small">
                <svg>
                  <circle className="bg" cx="40" cy="40" r="37"></circle>
                  <circle
                    className="progress two"
                    cx="40"
                    cy="40"
                    r="37"
                  ></circle>
                </svg>
              </div>
            </div>
          </Link>
        </div>
      )}
      {isCourse.slug === "remote-isolated-work" && (
        <div className="d-flex flex-column mt-5">
          <Link
            to="javascript:void(0)"
            className="d-flex align-items-center gap-3 list-link anilink"
          >
            <div className="flex-shrink-0">
              <i className="far fa-align-left"></i>
            </div>
            <div className="flex-grow-1">
              <h4>Inroduction</h4>
            </div>
            <div className="flex-shrink-0">
              <div className="circle-small">
                <svg>
                  <circle className="bg" cx="40" cy="40" r="37"></circle>
                  <circle
                    className="progress two"
                    cx="40"
                    cy="40"
                    r="37"
                  ></circle>
                </svg>
              </div>
            </div>
          </Link>
          <Link
            to="javascript:void(0)"
            className="d-flex align-items-center gap-3 list-link disable-box rel"
            data-bs-toggle="tooltip"
            data-bs-title="Lessons must be completed in order"
          >
            <div className="flex-shrink-0">
              <i className="far fa-align-left"></i>
            </div>
            <div className="flex-grow-1">
              <h4>Health and Safety Responsibilities</h4>
            </div>
            <div className="flex-shrink-0">
              <div className="circle-small">
                <svg>
                  <circle className="bg" cx="40" cy="40" r="37"></circle>
                  <circle
                    className="progress two"
                    cx="40"
                    cy="40"
                    r="37"
                  ></circle>
                </svg>
              </div>
            </div>
          </Link>
          <Link
            to="javascript:void(0)"
            className="d-flex align-items-center gap-3 list-link disable-box rel"
            data-bs-toggle="tooltip"
            data-bs-title="Lessons must be completed in order"
          >
            <div className="flex-shrink-0">
              <i className="far fa-align-left"></i>
            </div>
            <div className="flex-grow-1">
              <h4>Identifying The Hazards Of Remote / Isolated Work</h4>
            </div>
            <div className="flex-shrink-0">
              <div className="circle-small">
                <svg>
                  <circle className="bg" cx="40" cy="40" r="37"></circle>
                  <circle
                    className="progress two"
                    cx="40"
                    cy="40"
                    r="37"
                  ></circle>
                </svg>
              </div>
            </div>
          </Link>
          <Link
            to="javascript:void(0)"
            className="d-flex align-items-center gap-3 list-link disable-box rel"
            data-bs-toggle="tooltip"
            data-bs-title="Lessons must be completed in order"
          >
            <div className="flex-shrink-0">
              <i className="far fa-align-left"></i>
            </div>
            <div className="flex-grow-1">
              <h4>Safety Arrangements</h4>
            </div>
            <div className="flex-shrink-0">
              <div className="circle-small">
                <svg>
                  <circle className="bg" cx="40" cy="40" r="37"></circle>
                  <circle
                    className="progress two"
                    cx="40"
                    cy="40"
                    r="37"
                  ></circle>
                </svg>
              </div>
            </div>
          </Link>

          <Link
            to="javascript:void(0)"
            className="d-flex align-items-center gap-3 list-link disable-box rel"
            data-bs-toggle="tooltip"
            data-bs-title="Lessons must be completed in order"
          >
            <div className="flex-shrink-0">
              <i className="far fa-align-left"></i>
            </div>
            <div className="flex-grow-1">
              <h4>Final Assessment</h4>
            </div>
            <div className="flex-shrink-0">
              <div className="circle-small">
                <svg>
                  <circle className="bg" cx="40" cy="40" r="37"></circle>
                  <circle
                    className="progress two"
                    cx="40"
                    cy="40"
                    r="37"
                  ></circle>
                </svg>
              </div>
            </div>
          </Link>
        </div>
      )}
      {isCourse.slug === "working-at-heights" && (
        <div className="d-flex flex-column mt-5">
          <Link
            to="javascript:void(0)"
            className="d-flex align-items-center gap-3 list-link anilink"
          >
            <div className="flex-shrink-0">
              <i className="far fa-align-left"></i>
            </div>
            <div className="flex-grow-1">
              <h4>Inroduction</h4>
            </div>
            <div className="flex-shrink-0">
              <div className="circle-small">
                <svg>
                  <circle className="bg" cx="40" cy="40" r="37"></circle>
                  <circle
                    className="progress two"
                    cx="40"
                    cy="40"
                    r="37"
                  ></circle>
                </svg>
              </div>
            </div>
          </Link>
          <Link
            to="javascript:void(0)"
            className="d-flex align-items-center gap-3 list-link disable-box rel"
            data-bs-toggle="tooltip"
            data-bs-title="Lessons must be completed in order"
          >
            <div className="flex-shrink-0">
              <i className="far fa-align-left"></i>
            </div>
            <div className="flex-grow-1">
              <h4>Legislation</h4>
            </div>
            <div className="flex-shrink-0">
              <div className="circle-small">
                <svg>
                  <circle className="bg" cx="40" cy="40" r="37"></circle>
                  <circle
                    className="progress two"
                    cx="40"
                    cy="40"
                    r="37"
                  ></circle>
                </svg>
              </div>
            </div>
          </Link>
          <Link
            to="javascript:void(0)"
            className="d-flex align-items-center gap-3 list-link disable-box rel"
            data-bs-toggle="tooltip"
            data-bs-title="Lessons must be completed in order"
          >
            <div className="flex-shrink-0">
              <i className="far fa-align-left"></i>
            </div>
            <div className="flex-grow-1">
              <h4>
                <h4>Managing The Risks</h4>
              </h4>
            </div>
            <div className="flex-shrink-0">
              <div className="circle-small">
                <svg>
                  <circle className="bg" cx="40" cy="40" r="37"></circle>
                  <circle
                    className="progress two"
                    cx="40"
                    cy="40"
                    r="37"
                  ></circle>
                </svg>
              </div>
            </div>
          </Link>
          <Link
            to="javascript:void(0)"
            className="d-flex align-items-center gap-3 list-link disable-box rel"
            data-bs-toggle="tooltip"
            data-bs-title="Lessons must be completed in order"
          >
            <div className="flex-shrink-0">
              <i className="far fa-align-left"></i>
            </div>
            <div className="flex-grow-1">
              <h4>Work Surfaces</h4>
            </div>
            <div className="flex-shrink-0">
              <div className="circle-small">
                <svg>
                  <circle className="bg" cx="40" cy="40" r="37"></circle>
                  <circle
                    className="progress two"
                    cx="40"
                    cy="40"
                    r="37"
                  ></circle>
                </svg>
              </div>
            </div>
          </Link>

          <Link
            to="javascript:void(0)"
            className="d-flex align-items-center gap-3 list-link disable-box rel"
            data-bs-toggle="tooltip"
            data-bs-title="Lessons must be completed in order"
          >
            <div className="flex-shrink-0">
              <i className="far fa-align-left"></i>
            </div>
            <div className="flex-grow-1">
              <h4>Fall Prevention Devices</h4>
            </div>
            <div className="flex-shrink-0">
              <div className="circle-small">
                <svg>
                  <circle className="bg" cx="40" cy="40" r="37"></circle>
                  <circle
                    className="progress two"
                    cx="40"
                    cy="40"
                    r="37"
                  ></circle>
                </svg>
              </div>
            </div>
          </Link>
          <Link
            to="javascript:void(0)"
            className="d-flex align-items-center gap-3 list-link disable-box rel"
            data-bs-toggle="tooltip"
            data-bs-title="Lessons must be completed in order"
          >
            <div className="flex-shrink-0">
              <i className="far fa-align-left"></i>
            </div>
            <div className="flex-grow-1">
              <h4>Fall Protection Equipment</h4>
            </div>
            <div className="flex-shrink-0">
              <div className="circle-small">
                <svg>
                  <circle className="bg" cx="40" cy="40" r="37"></circle>
                  <circle
                    className="progress two"
                    cx="40"
                    cy="40"
                    r="37"
                  ></circle>
                </svg>
              </div>
            </div>
          </Link>

          <Link
            to="javascript:void(0)"
            className="d-flex align-items-center gap-3 list-link disable-box rel"
            data-bs-toggle="tooltip"
            data-bs-title="Lessons must be completed in order"
          >
            <div className="flex-shrink-0">
              <i className="far fa-align-left"></i>
            </div>
            <div className="flex-grow-1">
              <h4>Ladder Safety</h4>
            </div>
            <div className="flex-shrink-0">
              <div className="circle-small">
                <svg>
                  <circle className="bg" cx="40" cy="40" r="37"></circle>
                  <circle
                    className="progress two"
                    cx="40"
                    cy="40"
                    r="37"
                  ></circle>
                </svg>
              </div>
            </div>
          </Link>
          <Link
            to="javascript:void(0)"
            className="d-flex align-items-center gap-3 list-link disable-box rel"
            data-bs-toggle="tooltip"
            data-bs-title="Lessons must be completed in order"
          >
            <div className="flex-shrink-0">
              <i className="far fa-align-left"></i>
            </div>
            <div className="flex-grow-1">
              <h4>Final Assessment</h4>
            </div>
            <div className="flex-shrink-0">
              <div className="circle-small">
                <svg>
                  <circle className="bg" cx="40" cy="40" r="37"></circle>
                  <circle
                    className="progress two"
                    cx="40"
                    cy="40"
                    r="37"
                  ></circle>
                </svg>
              </div>
            </div>
          </Link>
        </div>
      )}
      {isCourse.slug === "noise-awareness" && (
        <div className="d-flex flex-column mt-5">
          <Link
            to="javascript:void(0)"
            className="d-flex align-items-center gap-3 list-link anilink"
          >
            <div className="flex-shrink-0">
              <i className="far fa-align-left"></i>
            </div>
            <div className="flex-grow-1">
              <h4>Inroduction</h4>
            </div>
            <div className="flex-shrink-0">
              <div className="circle-small">
                <svg>
                  <circle className="bg" cx="40" cy="40" r="37"></circle>
                  <circle
                    className="progress two"
                    cx="40"
                    cy="40"
                    r="37"
                  ></circle>
                </svg>
              </div>
            </div>
          </Link>
          <Link
            to="javascript:void(0)"
            className="d-flex align-items-center gap-3 list-link disable-box rel"
            data-bs-toggle="tooltip"
            data-bs-title="Lessons must be completed in order"
          >
            <div className="flex-shrink-0">
              <i className="far fa-align-left"></i>
            </div>
            <div className="flex-grow-1">
              <h4>Legislation</h4>
            </div>
            <div className="flex-shrink-0">
              <div className="circle-small">
                <svg>
                  <circle className="bg" cx="40" cy="40" r="37"></circle>
                  <circle
                    className="progress two"
                    cx="40"
                    cy="40"
                    r="37"
                  ></circle>
                </svg>
              </div>
            </div>
          </Link>
          <Link
            to="javascript:void(0)"
            className="d-flex align-items-center gap-3 list-link disable-box rel"
            data-bs-toggle="tooltip"
            data-bs-title="Lessons must be completed in order"
          >
            <div className="flex-shrink-0">
              <i className="far fa-align-left"></i>
            </div>
            <div className="flex-grow-1">
              <h4>
                <h4>Managing The Risks</h4>
              </h4>
            </div>
            <div className="flex-shrink-0">
              <div className="circle-small">
                <svg>
                  <circle className="bg" cx="40" cy="40" r="37"></circle>
                  <circle
                    className="progress two"
                    cx="40"
                    cy="40"
                    r="37"
                  ></circle>
                </svg>
              </div>
            </div>
          </Link>
          <Link
            to="javascript:void(0)"
            className="d-flex align-items-center gap-3 list-link disable-box rel"
            data-bs-toggle="tooltip"
            data-bs-title="Lessons must be completed in order"
          >
            <div className="flex-shrink-0">
              <i className="far fa-align-left"></i>
            </div>
            <div className="flex-grow-1">
              <h4>The Ear</h4>
            </div>
            <div className="flex-shrink-0">
              <div className="circle-small">
                <svg>
                  <circle className="bg" cx="40" cy="40" r="37"></circle>
                  <circle
                    className="progress two"
                    cx="40"
                    cy="40"
                    r="37"
                  ></circle>
                </svg>
              </div>
            </div>
          </Link>

          <Link
            to="javascript:void(0)"
            className="d-flex align-items-center gap-3 list-link disable-box rel"
            data-bs-toggle="tooltip"
            data-bs-title="Lessons must be completed in order"
          >
            <div className="flex-shrink-0">
              <i className="far fa-align-left"></i>
            </div>
            <div className="flex-grow-1">
              <h4>Noise-induced Hearing Loss(NIHL)</h4>
            </div>
            <div className="flex-shrink-0">
              <div className="circle-small">
                <svg>
                  <circle className="bg" cx="40" cy="40" r="37"></circle>
                  <circle
                    className="progress two"
                    cx="40"
                    cy="40"
                    r="37"
                  ></circle>
                </svg>
              </div>
            </div>
          </Link>
          <Link
            to="javascript:void(0)"
            className="d-flex align-items-center gap-3 list-link disable-box rel"
            data-bs-toggle="tooltip"
            data-bs-title="Lessons must be completed in order"
          >
            <div className="flex-shrink-0">
              <i className="far fa-align-left"></i>
            </div>
            <div className="flex-grow-1">
              <h4>Noise Exposure Standards</h4>
            </div>
            <div className="flex-shrink-0">
              <div className="circle-small">
                <svg>
                  <circle className="bg" cx="40" cy="40" r="37"></circle>
                  <circle
                    className="progress two"
                    cx="40"
                    cy="40"
                    r="37"
                  ></circle>
                </svg>
              </div>
            </div>
          </Link>

          <Link
            to="javascript:void(0)"
            className="d-flex align-items-center gap-3 list-link disable-box rel"
            data-bs-toggle="tooltip"
            data-bs-title="Lessons must be completed in order"
          >
            <div className="flex-shrink-0">
              <i className="far fa-align-left"></i>
            </div>
            <div className="flex-grow-1">
              <h4>Assessment of Noise Levels</h4>
            </div>
            <div className="flex-shrink-0">
              <div className="circle-small">
                <svg>
                  <circle className="bg" cx="40" cy="40" r="37"></circle>
                  <circle
                    className="progress two"
                    cx="40"
                    cy="40"
                    r="37"
                  ></circle>
                </svg>
              </div>
            </div>
          </Link>
          <Link
            to="javascript:void(0)"
            className="d-flex align-items-center gap-3 list-link disable-box rel"
            data-bs-toggle="tooltip"
            data-bs-title="Lessons must be completed in order"
          >
            <div className="flex-shrink-0">
              <i className="far fa-align-left"></i>
            </div>
            <div className="flex-grow-1">
              <h4>Noise Control and Management </h4>
            </div>
            <div className="flex-shrink-0">
              <div className="circle-small">
                <svg>
                  <circle className="bg" cx="40" cy="40" r="37"></circle>
                  <circle
                    className="progress two"
                    cx="40"
                    cy="40"
                    r="37"
                  ></circle>
                </svg>
              </div>
            </div>
          </Link>
          <Link
            to="javascript:void(0)"
            className="d-flex align-items-center gap-3 list-link disable-box rel"
            data-bs-toggle="tooltip"
            data-bs-title="Lessons must be completed in order"
          >
            <div className="flex-shrink-0">
              <i className="far fa-align-left"></i>
            </div>
            <div className="flex-grow-1">
              <h4>Personal Protective Equipment</h4>
            </div>
            <div className="flex-shrink-0">
              <div className="circle-small">
                <svg>
                  <circle className="bg" cx="40" cy="40" r="37"></circle>
                  <circle
                    className="progress two"
                    cx="40"
                    cy="40"
                    r="37"
                  ></circle>
                </svg>
              </div>
            </div>
          </Link>
          <Link
            to="javascript:void(0)"
            className="d-flex align-items-center gap-3 list-link disable-box rel"
            data-bs-toggle="tooltip"
            data-bs-title="Lessons must be completed in order"
          >
            <div className="flex-shrink-0">
              <i className="far fa-align-left"></i>
            </div>
            <div className="flex-grow-1">
              <h4>Final Assessment</h4>
            </div>
            <div className="flex-shrink-0">
              <div className="circle-small">
                <svg>
                  <circle className="bg" cx="40" cy="40" r="37"></circle>
                  <circle
                    className="progress two"
                    cx="40"
                    cy="40"
                    r="37"
                  ></circle>
                </svg>
              </div>
            </div>
          </Link>
        </div>
      )}

      {isCourse.slug === "first-aid-awareness" && (
        <div className="d-flex flex-column mt-5">
          <Link
            to="javascript:void(0)"
            className="d-flex align-items-center gap-3 list-link anilink"
          >
            <div className="flex-shrink-0">
              <i className="far fa-align-left"></i>
            </div>
            <div className="flex-grow-1">
              <h4>Inroduction</h4>
            </div>
            <div className="flex-shrink-0">
              <div className="circle-small">
                <svg>
                  <circle className="bg" cx="40" cy="40" r="37"></circle>
                  <circle
                    className="progress two"
                    cx="40"
                    cy="40"
                    r="37"
                  ></circle>
                </svg>
              </div>
            </div>
          </Link>
          <Link
            to="javascript:void(0)"
            className="d-flex align-items-center gap-3 list-link disable-box rel"
            data-bs-toggle="tooltip"
            data-bs-title="Lessons must be completed in order"
          >
            <div className="flex-shrink-0">
              <i className="far fa-align-left"></i>
            </div>
            <div className="flex-grow-1">
              <h4>First Aiders And Assessment Of Needs</h4>
            </div>
            <div className="flex-shrink-0">
              <div className="circle-small">
                <svg>
                  <circle className="bg" cx="40" cy="40" r="37"></circle>
                  <circle
                    className="progress two"
                    cx="40"
                    cy="40"
                    r="37"
                  ></circle>
                </svg>
              </div>
            </div>
          </Link>
          <Link
            to="javascript:void(0)"
            className="d-flex align-items-center gap-3 list-link disable-box rel"
            data-bs-toggle="tooltip"
            data-bs-title="Lessons must be completed in order"
          >
            <div className="flex-shrink-0">
              <i className="far fa-align-left"></i>
            </div>
            <div className="flex-grow-1">
              <h4>
                <h4>First Aid Equipment, Facilities And Training</h4>
              </h4>
            </div>
            <div className="flex-shrink-0">
              <div className="circle-small">
                <svg>
                  <circle className="bg" cx="40" cy="40" r="37"></circle>
                  <circle
                    className="progress two"
                    cx="40"
                    cy="40"
                    r="37"
                  ></circle>
                </svg>
              </div>
            </div>
          </Link>
          <Link
            to="javascript:void(0)"
            className="d-flex align-items-center gap-3 list-link disable-box rel"
            data-bs-toggle="tooltip"
            data-bs-title="Lessons must be completed in order"
          >
            <div className="flex-shrink-0">
              <i className="far fa-align-left"></i>
            </div>
            <div className="flex-grow-1">
              <h4>Providing Information On First Aid</h4>
            </div>
            <div className="flex-shrink-0">
              <div className="circle-small">
                <svg>
                  <circle className="bg" cx="40" cy="40" r="37"></circle>
                  <circle
                    className="progress two"
                    cx="40"
                    cy="40"
                    r="37"
                  ></circle>
                </svg>
              </div>
            </div>
          </Link>

          <Link
            to="javascript:void(0)"
            className="d-flex align-items-center gap-3 list-link disable-box rel"
            data-bs-toggle="tooltip"
            data-bs-title="Lessons must be completed in order"
          >
            <div className="flex-shrink-0">
              <i className="far fa-align-left"></i>
            </div>
            <div className="flex-grow-1">
              <h4>Final Assessment</h4>
            </div>
            <div className="flex-shrink-0">
              <div className="circle-small">
                <svg>
                  <circle className="bg" cx="40" cy="40" r="37"></circle>
                  <circle
                    className="progress two"
                    cx="40"
                    cy="40"
                    r="37"
                  ></circle>
                </svg>
              </div>
            </div>
          </Link>
        </div>
      )}

      {isCourse.slug === "preparing-safe-work-method-statements" && (
        <div className="d-flex flex-column mt-5">
          <Link
            to="javascript:void(0)"
            className="d-flex align-items-center gap-3 list-link anilink"
          >
            <div className="flex-shrink-0">
              <i className="far fa-align-left"></i>
            </div>
            <div className="flex-grow-1">
              <h4>
                What Is The Difference Between A Risk Assessment And A Safe Work
                Method Statement?
              </h4>
            </div>
            <div className="flex-shrink-0">
              <div className="circle-small">
                <svg>
                  <circle className="bg" cx="40" cy="40" r="37"></circle>
                  <circle
                    className="progress two"
                    cx="40"
                    cy="40"
                    r="37"
                  ></circle>
                </svg>
              </div>
            </div>
          </Link>
          <Link
            to="javascript:void(0)"
            className="d-flex align-items-center gap-3 list-link disable-box rel"
            data-bs-toggle="tooltip"
            data-bs-title="Lessons must be completed in order"
          >
            <div className="flex-shrink-0">
              <i className="far fa-align-left"></i>
            </div>
            <div className="flex-grow-1">
              <h4>What Is A Safe Work Method Statement?</h4>
            </div>
            <div className="flex-shrink-0">
              <div className="circle-small">
                <svg>
                  <circle className="bg" cx="40" cy="40" r="37"></circle>
                  <circle
                    className="progress two"
                    cx="40"
                    cy="40"
                    r="37"
                  ></circle>
                </svg>
              </div>
            </div>
          </Link>
          <Link
            to="javascript:void(0)"
            className="d-flex align-items-center gap-3 list-link disable-box rel"
            data-bs-toggle="tooltip"
            data-bs-title="Lessons must be completed in order"
          >
            <div className="flex-shrink-0">
              <i className="far fa-align-left"></i>
            </div>
            <div className="flex-grow-1">
              <h4>
                <h4>
                  What Information Must Be Included In A Safe Work Method
                  Statement?
                </h4>
              </h4>
            </div>
            <div className="flex-shrink-0">
              <div className="circle-small">
                <svg>
                  <circle className="bg" cx="40" cy="40" r="37"></circle>
                  <circle
                    className="progress two"
                    cx="40"
                    cy="40"
                    r="37"
                  ></circle>
                </svg>
              </div>
            </div>
          </Link>
          <Link
            to="javascript:void(0)"
            className="d-flex align-items-center gap-3 list-link disable-box rel"
            data-bs-toggle="tooltip"
            data-bs-title="Lessons must be completed in order"
          >
            <div className="flex-shrink-0">
              <i className="far fa-align-left"></i>
            </div>
            <div className="flex-grow-1">
              <h4>Where Do You Keep A Safe Work Method Statement?</h4>
            </div>
            <div className="flex-shrink-0">
              <div className="circle-small">
                <svg>
                  <circle className="bg" cx="40" cy="40" r="37"></circle>
                  <circle
                    className="progress two"
                    cx="40"
                    cy="40"
                    r="37"
                  ></circle>
                </svg>
              </div>
            </div>
          </Link>

          <Link
            to="javascript:void(0)"
            className="d-flex align-items-center gap-3 list-link disable-box rel"
            data-bs-toggle="tooltip"
            data-bs-title="Lessons must be completed in order"
          >
            <div className="flex-shrink-0">
              <i className="far fa-align-left"></i>
            </div>
            <div className="flex-grow-1">
              <h4>An Example Of A Safe Work Method Statement</h4>
            </div>
            <div className="flex-shrink-0">
              <div className="circle-small">
                <svg>
                  <circle className="bg" cx="40" cy="40" r="37"></circle>
                  <circle
                    className="progress two"
                    cx="40"
                    cy="40"
                    r="37"
                  ></circle>
                </svg>
              </div>
            </div>
          </Link>
          <Link
            to="javascript:void(0)"
            className="d-flex align-items-center gap-3 list-link disable-box rel"
            data-bs-toggle="tooltip"
            data-bs-title="Lessons must be completed in order"
          >
            <div className="flex-shrink-0">
              <i className="far fa-align-left"></i>
            </div>
            <div className="flex-grow-1">
              <h4>Final Assessment</h4>
            </div>
            <div className="flex-shrink-0">
              <div className="circle-small">
                <svg>
                  <circle className="bg" cx="40" cy="40" r="37"></circle>
                  <circle
                    className="progress two"
                    cx="40"
                    cy="40"
                    r="37"
                  ></circle>
                </svg>
              </div>
            </div>
          </Link>
        </div>
      )}
      {isCourse.slug === "silica-awareness" && (
        <div className="d-flex flex-column mt-5">
          <Link
            to="javascript:void(0)"
            className="d-flex align-items-center gap-3 list-link anilink"
          >
            <div className="flex-shrink-0">
              <i className="far fa-align-left"></i>
            </div>
            <div className="flex-grow-1">
              <h4>Introduction</h4>
            </div>
            <div className="flex-shrink-0">
              <div className="circle-small">
                <svg>
                  <circle className="bg" cx="40" cy="40" r="37"></circle>
                  <circle
                    className="progress two"
                    cx="40"
                    cy="40"
                    r="37"
                  ></circle>
                </svg>
              </div>
            </div>
          </Link>
          <Link
            to="javascript:void(0)"
            className="d-flex align-items-center gap-3 list-link disable-box rel"
            data-bs-toggle="tooltip"
            data-bs-title="Lessons must be completed in order"
          >
            <div className="flex-shrink-0">
              <i className="far fa-align-left"></i>
            </div>
            <div className="flex-grow-1">
              <h4>Exposure To Silica</h4>
            </div>
            <div className="flex-shrink-0">
              <div className="circle-small">
                <svg>
                  <circle className="bg" cx="40" cy="40" r="37"></circle>
                  <circle
                    className="progress two"
                    cx="40"
                    cy="40"
                    r="37"
                  ></circle>
                </svg>
              </div>
            </div>
          </Link>
          <Link
            to="javascript:void(0)"
            className="d-flex align-items-center gap-3 list-link disable-box rel"
            data-bs-toggle="tooltip"
            data-bs-title="Lessons must be completed in order"
          >
            <div className="flex-shrink-0">
              <i className="far fa-align-left"></i>
            </div>
            <div className="flex-grow-1">
              <h4>
                <h4>How To Control Silica Dust Exposure</h4>
              </h4>
            </div>
            <div className="flex-shrink-0">
              <div className="circle-small">
                <svg>
                  <circle className="bg" cx="40" cy="40" r="37"></circle>
                  <circle
                    className="progress two"
                    cx="40"
                    cy="40"
                    r="37"
                  ></circle>
                </svg>
              </div>
            </div>
          </Link>
          <Link
            to="javascript:void(0)"
            className="d-flex align-items-center gap-3 list-link disable-box rel"
            data-bs-toggle="tooltip"
            data-bs-title="Lessons must be completed in order"
          >
            <div className="flex-shrink-0">
              <i className="far fa-align-left"></i>
            </div>
            <div className="flex-grow-1">
              <h4>Monitoring Silica Dust</h4>
            </div>
            <div className="flex-shrink-0">
              <div className="circle-small">
                <svg>
                  <circle className="bg" cx="40" cy="40" r="37"></circle>
                  <circle
                    className="progress two"
                    cx="40"
                    cy="40"
                    r="37"
                  ></circle>
                </svg>
              </div>
            </div>
          </Link>

          <Link
            to="javascript:void(0)"
            className="d-flex align-items-center gap-3 list-link disable-box rel"
            data-bs-toggle="tooltip"
            data-bs-title="Lessons must be completed in order"
          >
            <div className="flex-shrink-0">
              <i className="far fa-align-left"></i>
            </div>
            <div className="flex-grow-1">
              <h4>Final Assessment</h4>
            </div>
            <div className="flex-shrink-0">
              <div className="circle-small">
                <svg>
                  <circle className="bg" cx="40" cy="40" r="37"></circle>
                  <circle
                    className="progress two"
                    cx="40"
                    cy="40"
                    r="37"
                  ></circle>
                </svg>
              </div>
            </div>
          </Link>
        </div>
      )}
    </>
  );
};
