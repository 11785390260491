import React, { useState, useEffect } from "react";
import axios from "axios";
import { Calendar } from "@fullcalendar/core";

export const Syn = () => {
  const [data, setData] = useState();
  useEffect(() => {
    let formdata = {};

    axios
      .get(
        "https://www.airbnb.com/calendar/ical/939675435307466873.ics?s=5c5e875a469f468891f3b40d180b8ebe",
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        console.log("ss");
        console.log(res.data);
        setData(res.data);
      })
      .catch((err) => {});
  }, []);

  return <></>;
};
