import React, { useState, useEffect } from "react";
import axios from "axios";
import { message, Form, Input, Button, Alert } from "antd";
import { Link, useNavigate } from "react-router-dom";
import TextArea from "antd/es/input/TextArea";
import AdminHeader from "./AdminHeader";
import AdminFooter from "./AdminFooter";
export const AdminLogout = () => {
  const navigate = useNavigate();
  const [valuenotifications, setValuegetnotifications] = useState([]);
  const changeFavicon = (url) => {
    const favicon = document.querySelector('link[rel="icon"]');
    if (favicon) {
      favicon.href = url;
    }
  };
  useEffect(() => {
    changeFavicon(require("../assets/admin/images/f-icon.png"));
    document.title = "Notification Page";
    const userStr = JSON.parse(localStorage.getItem("admin"));
    localStorage.removeItem("admin");
    window.location.href = "/admin/login";

    let formdata = {};
    axios
      .post("https://jlmining.app/admin/getallnotifications", formdata, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        console.log(res.data);
        var alldata = res.data.data;
        setValuegetnotifications(alldata);
      })
      .catch((err) => {});
  }, []);
  return <></>;
};
