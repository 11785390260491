import React, { useState, useEffect } from "react";
import axios from "axios";
import { message, Form, Input, Button, Alert } from "antd";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Header } from "../Header";
import { Footer } from "../Footer";
import moment from "moment-timezone";
import { Documentsnotes } from "./Documentsnotes";
import { Documents } from "./Documents";
import Select from "react-select";
import "../assets/ohs/newstyle.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
export const Reporter = () => {
  const currentYear = new Date().getFullYear();
  const navigate = useNavigate();
  const [timezones, setTimezones] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [activeTab, setActiveTab] = useState("home-tab");
  const [activeTabMenu, setactiveTabMenu] = useState("details");
  const [activetab1, setactivetab1] = useState(true);
  const [activetab2, setactivetab2] = useState(false);
  const [isDisabledstep2, setisDisabledstep2] = useState(false);
  const [isDisabledtomenu, setisDisabledtomenu] = useState(false);
  const [activetab3, setactivetab3] = useState(false);
  const [activetab4, setactivetab4] = useState(false);
  const [activecounrty, setactivecounrty] = useState(true);
  const [isDisabled, setIsDisabled] = useState(false);
  const [perdetailstep2, setperdetailstep2] = useState(false);
  const [iscountryChecked, setIscountryChecked] = useState("au");
  const [isyesNoChecked, setyesNoChecked] = useState("Yes");
  const [isStateChecked, setStateChecked] = useState("");
  const [isSet3employee, setisSet3employee] = useState("");
  const [isstep2employee, setisstep2employee] = useState("Employee");
  const [persondetail, setpersondetail] = useState("");
  const [isreferences, setreferences] = useState("");
  const [isstep2_persondetail, setisstep2_persondetail] = useState("Yes");
  const [iswasChecked, setiswasChecked] = useState("No");
  const [iswasfirst, setwasfirst] = useState(false);
  const [iswhereotherChecked, setiswhereotherChecked] = useState("No");
  const [iswhereother, setwhereother] = useState(false);
  const [uniquecode, setuniquecode] = useState("");

  const [iswitnessChecked, setiswitnessChecked] = useState("No");
  const [iswitness, setwitness] = useState(false);
  const [countries, setCountries] = useState([]);
  const [isetcounty, setisetcounty] = useState("");
  const [isetcountystep3, setisetcountystep3] = useState("");
  const [getincidentData, setgetincidentData] = useState([]);
  const [isincident_safeChecked, setisincident_safeChecked] = useState("No");
  const [isincidentafe, setisincidentafe] = useState(false);
  const changeFavicon = (url) => {
    const favicon = document.querySelector('link[rel="icon"]');
    if (favicon) {
      favicon.href = url;
    }
  };
  const { id } = useParams();

  useEffect(() => {
    const userStr = JSON.parse(localStorage.getItem("users"));
    if (userStr === null) {
      window.location.href = "/login";
    }
    document.title = "Reporter Page";
    if (id === undefined) {
      setuniquecode("");
    } else {
      setuniquecode(id);
    }

    axios
      .get("https://jlmining.app/country", {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        const options = res.data.results.map((country) => ({
          value: country.country_name,
          label: country.country_name,
        }));
        setCountries(options);
      })
      .catch((err) => {});
    const australiaTimezones = moment.tz
      .names()
      .filter((zone) => zone.startsWith("Australia/"))
      .map((zone) => ({ value: zone, label: zone }));
    setTimezones(australiaTimezones);
    let formdata = {
      user_id: userStr[0].id,
      code: id,
    };
    axios
      .post("https://jlmining.app/getincidentdata", formdata, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        if (res.data.row.length > 0) {
          var indata = res.data.row[0];
          if (indata.status === "Closed") {
            const newUrl = "/incident/";
            navigate(newUrl);
          }
          setgetincidentData(indata);
          setSelectedCountry({
            value: indata.country_step3,
            label: indata.country_step3,
          });
          setisetcountystep3({
            value: indata.country_step3,
            label: indata.country_step3,
          });
        }
      })
      .catch((err) => {});
  }, [id]);
  console.log(uniquecode);
  const step2Country = (event) => {
    if (event !== "") {
      setisetcounty(event.value);
    }
  };
  const step3Country = (event) => {
    console.log(event);
    if (event !== "") {
      setisetcountystep3({
        value: event.value,
        label: event.value,
      });
    }
  };
  const handinjured_check = (event) => {
    if (event === "Yes") {
      setyesNoChecked("Yes");
      setIsDisabled(false);
    } else {
      setyesNoChecked("No");
      setIsDisabled(true);
    }
    console.log(event);
  };
  const handcountry = (event) => {
    if (event === "au") {
      setIscountryChecked("au");
      setactivecounrty(true);
    } else {
      setIscountryChecked("nz");
      setactivecounrty(false);
    }
  };
  const handstate = (event) => {
    setStateChecked(event);
  };
  const handstep2employee = (event) => {
    setisstep2employee(event);
    setisDisabledstep2(false);
    if (event !== "Employee") {
      setperdetailstep2(true);
    } else {
      setpersondetail("");
      setisstep2_persondetail("Yes");
      setperdetailstep2(false);
    }
  };
  const handstep2persondetail = (event) => {
    setisstep2_persondetail(event);
    setpersondetail("");
    if (event === "Yes") {
      setisDisabledstep2(false);
      setpersondetail("");
    } else {
      setisDisabledstep2(true);
      setpersondetail(event);
    }
  };
  const backbuttonStep2 = (event) => {
    setactivetab1(true);
    setactivetab2(false);
    setactiveTabMenu("details");
  };
  const backbuttonStep3 = (event) => {
    setactivetab1(false);
    setactivetab3(false);
    setactivetab2(true);
    setactiveTabMenu("injuredperson");
  };
  const backbuttonStep4 = (event) => {
    setactivetab4(false);
    setactivetab3(true);
    setactiveTabMenu("reporter");
  };
  const step2fullname = (e) => {
    console.log(persondetail);
    console.log(e.target.value);
    setpersondetail(e.target.value);
  };
  const handmenu_check = (e) => {
    if (uniquecode !== undefined || uniquecode !== "") {
      if (e === "details") {
        const newUrl = "/incident/detail/" + uniquecode;
        navigate(newUrl);
      }

      if (e === "injuredperson") {
        const newUrl = "/incident/victim/" + uniquecode;
        navigate(newUrl);
      }
    }
  };
  const handincident_safe = (event) => {
    setisincident_safeChecked(event);
    if (event === "Yes") {
      setisincidentafe(true);
    } else {
      setisincidentafe(false);
    }
  };
  const handwasfirst = (event) => {
    setiswasChecked(event);
    if (event === "Yes") {
      setwasfirst(true);
    } else {
      setwasfirst(false);
    }
  };
  const handwhereother = (event) => {
    setiswhereotherChecked(event);
    if (event === "Yes") {
      setwhereother(true);
    } else {
      setwhereother(false);
    }
  };
  const handwitness = (event) => {
    setiswitnessChecked(event);
    if (event === "Yes") {
      setwitness(true);
    } else {
      setwitness(false);
    }
  };
  const references = (event) => {
    setreferences(event.target.value);
  };

  const handbuttonStep3 = (event) => {
    // if (isSet3employee !== "") {
    setactiveTabMenu("about");
    setactivetab4(true);
    setactivetab3(false);
    var vll = event.target;
    const userStr = JSON.parse(localStorage.getItem("users"));
    let formdata = {
      check_code: uniquecode,
      user_id: userStr[0].id,
      employee_name: vll.employee_name.value,
      occupation_step3: vll.occupation_step3.value,
      address1_step3: vll.address1_step3.value,
      address2_step3: vll.address2_step3.value,
      town_city_step3: vll.town_city_step3.value,
      state_step3: vll.state_step3.value,
      postcode_step3: vll.postcode_step3.value,
      phone_step3: vll.phone_step3.value,
      email_step3: vll.email_step3.value,
      country_step3: isetcountystep3.value,
    };
    axios
      .post("https://jlmining.app/incident_step3", formdata, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        console.log(res.data.code);
        if (res.data.code !== "") {
          const newUrl = "/incident/about/" + uniquecode;
          navigate(newUrl);
        }
      })
      .catch((err) => {});
    //}
  };

  const step3_employeename = (e) => {
    setisSet3employee(e.target.value);
  };
  const handleincident = (event) => {
    console.log("test");
    console.log(event.target);
  };
  return (
    <>
      <Header />
      <section className="d-block py-5">
        <div className="container-lg">
          <div className="row gy-4">
            <div className="col-md-8">
              {/* <Documents /> */}
              <div className="d-block">
                <div className="row gy-3 mb-4">
                  <div className="col-12 hazard-form-block">
                    <ul className="brow-nav">
                      <li>
                        <Link
                          to="javascript:void(0)"
                          onClick={() => handmenu_check("details")}
                        >
                          Details
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="javascript:void(0)"
                          onClick={() => handmenu_check("injuredperson")}
                        >
                          Injured person
                        </Link>
                      </li>
                      <li className="active">
                        <Link
                          to="javascript:void(0)"
                          onClick={() => handmenu_check("reporter")}
                        >
                          Reporter
                        </Link>
                      </li>
                      <li>
                        <Link
                          disabled={isDisabledtomenu}
                          to="javascript:void(0)"
                          onClick={() => handmenu_check("about")}
                        >
                          About
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <form
                action="javascript:void(0)"
                onSubmit={handbuttonStep3}
                method="post"
              >
                <div className="row gy-3">
                  <div className="col-12 hazard-form-block">
                    <div className="row gy-4">
                      <div className="col-12">
                        <div className="d-flex flex-column gap-2">
                          <h1 className="h-title">Near miss reported by?</h1>
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="row gy-4">
                          <div className="col-12">
                            <h5 className="in-title">Reporter Details</h5>
                          </div>

                          <div className="col-md-6">
                            <div className="d-flex flex-column gap-2">
                              <label for="">
                                Employee name
                                <span className="text-danger">*</span>
                              </label>
                              <input
                                type="text"
                                required
                                defaultValue={getincidentData.employee_name}
                                onInput={step3_employeename}
                                name="employee_name"
                                placeholder=""
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="d-flex flex-column gap-2">
                              <label for="">Occupation</label>
                              <input
                                type="text"
                                name="occupation_step3"
                                defaultValue={getincidentData.occupation_step3}
                                placeholder=""
                              />
                            </div>
                          </div>
                          <div className="col-12">
                            <h5 className="in-title">Address</h5>
                          </div>
                          <div className="col-md-6">
                            <div className="d-flex flex-column gap-2">
                              <label for="">Address 1 </label>
                              <input
                                type="text"
                                name="address1_step3"
                                placeholder=""
                                defaultValue={getincidentData.address1_step3}
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="d-flex flex-column gap-2">
                              <label for="">Address 2 </label>
                              <input
                                type="text"
                                name="address2_step3"
                                placeholder=""
                                defaultValue={getincidentData.address2_step3}
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="d-flex flex-column gap-2">
                              <label for="">Town/ City </label>
                              <input
                                type="text"
                                name="town_city_step3"
                                placeholder=""
                                defaultValue={getincidentData.town_city_step3}
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="d-flex flex-column gap-2">
                              <label for="">State/ Territory </label>
                              <input
                                type="text"
                                name="state_step3"
                                placeholder=""
                                defaultValue={getincidentData.state_step3}
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="d-flex flex-column gap-2">
                              <label for="">Country </label>
                              <Select
                                options={countries}
                                value={isetcountystep3}
                                onChange={step3Country}
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="d-flex flex-column gap-2">
                              <label for="">Postcode </label>
                              <input
                                type="text"
                                name="postcode_step3"
                                placeholder=""
                                defaultValue={getincidentData.postcode_step3}
                              />
                            </div>
                          </div>
                          <div className="col-12">
                            <h5 className="in-title">Contact Details</h5>
                          </div>
                          <div className="col-md-6">
                            <div className="d-flex flex-column gap-2">
                              <label for="">Telephone number </label>
                              <input
                                type="tel"
                                name="phone_step3"
                                placeholder=""
                                pattern="[0-9]*"
                                minlength="10"
                                defaultValue={getincidentData.phone_step3}
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="d-flex flex-column gap-2">
                              <label for="">Email </label>
                              <input
                                type="email"
                                name="email_step3"
                                placeholder=""
                                defaultValue={getincidentData.email_step3}
                              />
                            </div>
                          </div>
                          <div className="col-12">
                            <div className="d-flex gap-2 align-items-center buttons-two">
                              <Link
                                to={`/incident/victim/${uniquecode}`}
                                className="cel-btn"
                              >
                                Back
                              </Link>
                              <button type="submit" className="rep-btn">
                                Save and Continue
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div className="col-md-4">
              <div className="d-flex flex-column gap-3">
                <div className="d-flex flex-column need-block border border-secondary border-opacity-25 rounded-2 overflow-hidden">
                  <div className="d-block fulw">
                    <img
                      src={require("../assets/ohs/images/need.png")}
                      alt="image"
                    />
                  </div>
                  <div className="d-block px-3 pb-3">
                    <p>
                      Need help with the hazard report process? Call the Health
                      & Safety Advice Line on
                      <Link to="tel:1300 029 198">
                        0431 619 015,0406 400 994
                      </Link>
                    </p>
                  </div>
                </div>
                {/* <Documentsnotes /> */}
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};
