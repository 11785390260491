import React, { useRef, useState, useEffect } from "react";
import axios from "axios";
import { message, Form, Input, Button, Alert } from "antd";
import { Link, useNavigate } from "react-router-dom";
import Modal from "react-modal";
import { Header } from "./Header";
import { Footer } from "./Footer";
export const Home = () => {
  const inputFileRef = useRef(null);
  const currentYear = new Date().getFullYear();
  const navigate = useNavigate();
  const [docs_s, setdocs_s] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [valueUserDetail, setValuegetuser] = useState([]);
  const [openmannualModal, setopenmannualModal] = useState(false);
  const [fileshow, setfilename] = useState("");
  const [diss_s, setdiss_s] = useState(true);
  const handleLogout = () => {
    sessionStorage.removeItem("users");
    window.location.href = "/login";
  };
  const changeFavicon = (url) => {
    const favicon = document.querySelector('link[rel="icon"]');
    if (favicon) {
      favicon.href = url;
    }
  };

  useEffect(() => {
    changeFavicon(require("./assets/admin/images/f-icon.png"));
    const userStr = JSON.parse(localStorage.getItem("users"));
    console.log("ch");
    console.log(userStr);
    if (userStr === null) {
      console.log("cc");
      window.location.href = "/login";
    }
    document.title = "Home Page";
    let formdata = {
      user_id: userStr[0].id,
    };
    axios
      .post("https://jlmining.app/admin/getuserdetails", formdata, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        var alldata = res.data.row[0];

        setValuegetuser(alldata);
      })
      .catch((err) => {});
  }, []);
  const [values, setValues] = useState({
    name: "",
    email: "",
    password: "",
    contact: "",
    address: "",
  });
  const [showAlert, setShowAlert] = useState(false);
  const [showAlert_time, setShowAlert_time] = useState(false);
  const [showPass, setShowPass] = useState(false);
  const [val, setVal] = useState("");
  const handleInput = (event) => {
    setVal((v) => (event.target.validity.valid ? event.target.value : v));
    setValues((prev) => ({
      ...prev,
      [event.target.name]: [event.target.value],
    }));
  };
  const handleFileChange = (event) => {
    if (event.target.files[0] != null) {
      setSelectedFile(event.target.files[0]);
      handleprofile_update(event.target.files[0]);
    }
  };
  const handleprofile_update = (file) => {
    const userStr = JSON.parse(localStorage.getItem("users"));
    const formData = new FormData();
    formData.append("file", file);
    formData.append("user_id", userStr[0].id);
    axios
      .post("https://jlmining.app/admin/timesheetupload", formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        if (res.data.status == "1") {
          setShowAlert_time(true);
          setShowAlert_time({ type: "success" });
        } else {
          setShowAlert_time(true);
          setShowAlert_time({ type: "error" });
        }
        setTimeout(() => {
          setShowAlert_time(false);
          window.location.reload();
        }, 3500);
      })
      .catch((err) => {});
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    var vll = event.target;
    let formdata = {
      first_name: vll.first_name.value,
      middle_name: vll.middle_name.value,
      last_name: vll.last_name.value,
      email: vll.email.value,
      password: vll.password.value,
      contact: vll.contact.value,
      address: vll.address.value,
      skills: vll.skills.value,
      years: vll.years.value,
    };
    if (vll.password.value != vll.confirm_password.value) {
      setShowPass(true);
      setShowPass({ type: "error" });
    } else {
      //console.log(formdata);
      axios
        .post("https://jlmining.app/register", formdata, {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "POST,PUT",
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          console.log(res.data);
          if (res.data.status == "2") {
            setShowAlert(true);
            setShowAlert({ type: "error" });
          } else {
            setShowAlert(true);
            setShowAlert({ type: "success" });
            event.target.reset();
            event.target.text();
          }
          setTimeout(() => {
            setShowAlert(false);
          }, 4500);
        })
        .catch((err) => {
          //console.log(err.response.data);
        });
    }
  };
  const fname = valueUserDetail.first_name;
  const mname = valueUserDetail.middle_name;
  const lname = valueUserDetail.last_name;
  const fLetter = fname ? fname.charAt(0) : "";
  const mLetter = mname ? mname.charAt(0) : "";
  const lLetter = lname ? lname.charAt(0) : "";

  const mannualModal = (event) => {
    setopenmannualModal(true);
  };
  const closeDoc = () => {
    if (inputFileRef.current) {
      inputFileRef.current.value = "";
    }
    setfilename("");
    setopenmannualModal(false);
  };
  const handsubmitcategory = (event) => {
    const userStr = JSON.parse(localStorage.getItem("users"));
    var formData = new FormData();

    formData.append("user_id", userStr[0].id);
    formData.append("file", fileshow);
    axios
      .post("https://jlmining.app/mannualDocumentsave", formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        setdocs_s(true);
        setdiss_s(true);

        if (inputFileRef.current) {
          inputFileRef.current.value = "";
        }
        setfilename("");
        setTimeout(() => {
          setdocs_s(false);
          setopenmannualModal(false);
          event.target.reset();
        }, 4500);
      })
      .catch((err) => {});
  };
  const docfile = (event) => {
    const file = event.target.files[0]; // Get the first file from the input
    setdiss_s(false);
    setfilename(file);
  };
  const handleRemoveFile = (event) => {
    if (inputFileRef.current) {
      inputFileRef.current.value = "";
    }
    setfilename("");
    setdiss_s(true);
  };
  return (
    <>
      <Header />
      <div className="fullscreen h-100">
        {/* <div className="d-flex justify-content-between">
          <div className="text-danger" style={{ width: "200px" }}>
            <img
              src={require("./assets/images/logo_home1.png")}
              style={{ width: "100%" }}
              alt=""
            />
          </div>
          <div className="text-danger">
            <img
              src={require("./assets/images/logo_home2.png")}
              width={100}
              alt=""
            />
          </div>
        </div> */}
        <div className=" d-flex justify-content-center align-items-center h-100">
          <div className="d-flex justify-content-center align-items-center flex-column gap-3">
            <div className="reg-box d-flex flex-column gap-3 px-3 px-md-5 pt-40 pb-60 w-300 w-md-600">
              <div className="row justify-content-center">
                <div className="col-md-9">
                  <div className="d-flex flex-column  gap-3 mt-4">
                    <div className="btn-red-block">
                      <div className="d-flex justify-content-between gap-2">
                        <Link to="/currentroster">Current Roster</Link>
                      </div>
                    </div>
                    <div className="btn-red-block">
                      <div className="d-flex justify-content-between gap-2">
                        <Link to="/profile">Profile</Link>
                        <Link to="/timesheet">Signature</Link>
                      </div>
                    </div>
                  </div>
                  <div className="d-flex justify-content-between mt-4 gap-2">
                    <div className="btn-red-block w-50 m-auto">
                      <Link to="/ohs">OHS</Link>
                    </div>
                    <div className="btn-red-block w-50 m-auto">
                      <Link to="/clinetinformation">Client Info</Link>
                    </div>
                  </div>
                  <div className="d-flex justify-content-between mt-4 gap-2">
                    <div className="btn-red-block w-50 m-auto">
                      <Link to="/site_access">Site Access</Link>
                    </div>
                    <div className="btn-red-block w-50 m-auto">
                      <Link to="/user_mannual">Manual</Link>
                    </div>
                    <Modal
                      isOpen={openmannualModal}
                      onRequestClose={() => setopenmannualModal(false)}
                      contentLabel="Add Text Modal"
                      className="docs d-flex flex-column  bg-light mt-5 p-3 align-item-center justify-content-center"
                    >
                      <div style={{ width: "500px" }}>
                        <div className="d-flex flex-column file-pop">
                          <div className="d-flex justify-content-between align-items-center top-head">
                            <div className="flex-grow-1">
                              <h2>Upload</h2>
                            </div>
                            <div className="flex-shirnk-0">
                              <button
                                type="button"
                                onClick={() => closeDoc()}
                                className="close-btn"
                              >
                                <i
                                  className="fal fa-times"
                                  aria-hidden="true"
                                ></i>
                              </button>
                            </div>
                          </div>
                          <div className="d-flex flex-column px-1 py-2">
                            <h3 className="text-black choose-text">
                              Choose the document you would like to upload.
                            </h3>
                            {fileshow && (
                              <div className="sceenshot-box my-1 d-flex align-items-center border-secondary border border-opacity-25 bg-secondary bg-opacity-25 py-1 px-3">
                                <div className="flex-grow-1">
                                  <h4>{fileshow.name}</h4>{" "}
                                  {/* Display the file name */}
                                </div>
                                <div className="flex-shrink-0">
                                  <button
                                    type="button"
                                    className="scsbtn"
                                    onClick={() => handleRemoveFile()}
                                  >
                                    <i className="fal fa-trash-alt"></i>{" "}
                                    {/* Button to remove the file */}
                                  </button>
                                </div>
                              </div>
                            )}
                            <form
                              action="javascript:void(0)"
                              method="post"
                              onSubmit={handsubmitcategory}
                            >
                              <div className="d-flex flex-column text-center gap-3 rel p-4 drop-box-pop mx-auto ">
                                <i className="fal fa-arrow-alt-up"></i>
                                <h1>Drag and drop your files here to upload</h1>
                                <h6>Browse files...</h6>
                                <input
                                  type="file"
                                  multiple
                                  onChange={docfile}
                                  ref={inputFileRef}
                                  className="file-name-block"
                                />
                              </div>

                              {docs_s && (
                                <span class="text-success">
                                  Document has been successfully saved
                                </span>
                              )}
                              <div className="d-flex flex-column gap-3 drag-box">
                                <div className="d-flex gap-2 check-box-list">
                                  <div className="d-flex gap-2 align-items-center justify-content-end btns-box mt-4">
                                    <div className="flex-shrink-0">
                                      <button
                                        type="button"
                                        onClick={() => closeDoc()}
                                      >
                                        Cancel
                                      </button>
                                    </div>
                                    <div className="flex-shrink-0">
                                      <button
                                        type="submit"
                                        className={`${
                                          diss_s === true ? "sites_docs" : ""
                                        }`}
                                      >
                                        Upload
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </Modal>
                  </div>
                  <div className="d-flex justify-content-between mt-4 gap-2">
                    <div className=" w-50 m-auto">
                      <Link
                        download
                        to="https://jlmining.app/uploads/timesheet_doc/jlme_timesheets_template.docx"
                        className="csvfilebtn"
                      >
                        Download Time Sheet
                      </Link>
                    </div>
                    <div className=" w-50 m-auto">
                      {/* <Link to="javascript:void(0)" className="csvfilebtn"></Link> */}
                      <div className="input-file-box">
                        <input
                          type="file"
                          name="timesheet"
                          className="timesheet"
                          onChange={handleFileChange}
                        />
                        <span className="text-danger">
                          Upload Time Sheet manualy
                        </span>
                      </div>

                      {showAlert_time?.type === "success" && (
                        <Alert
                          type="success"
                          description="Time sheet has been updated successfully!"
                          closable
                        />
                      )}
                      {showAlert_time?.type === "error" && (
                        <Alert
                          type="error"
                          description="Some things went wrong!"
                          closable
                        />
                      )}
                    </div>

                    <Modal
                      isOpen={openmannualModal}
                      onRequestClose={() => setopenmannualModal(false)}
                      contentLabel="Add Text Modal"
                      className="docs d-flex flex-column  bg-light mt-5 p-3 align-item-center justify-content-center"
                    >
                      <div style={{ width: "500px" }}>
                        <div className="d-flex flex-column file-pop">
                          <div className="d-flex justify-content-between align-items-center top-head">
                            <div className="flex-grow-1">
                              <h2>Upload</h2>
                            </div>
                            <div className="flex-shirnk-0">
                              <button
                                type="button"
                                onClick={() => closeDoc()}
                                className="close-btn"
                              >
                                <i
                                  className="fal fa-times"
                                  aria-hidden="true"
                                ></i>
                              </button>
                            </div>
                          </div>
                          <div className="d-flex flex-column px-1 py-2">
                            <h3 className="text-black choose-text">
                              Choose the document you would like to upload.
                            </h3>
                            {fileshow && (
                              <div className="sceenshot-box my-1 d-flex align-items-center border-secondary border border-opacity-25 bg-secondary bg-opacity-25 py-1 px-3">
                                <div className="flex-grow-1">
                                  <h4>{fileshow.name}</h4>{" "}
                                  {/* Display the file name */}
                                </div>
                                <div className="flex-shrink-0">
                                  <button
                                    type="button"
                                    className="scsbtn"
                                    onClick={() => handleRemoveFile()}
                                  >
                                    <i className="fal fa-trash-alt"></i>{" "}
                                    {/* Button to remove the file */}
                                  </button>
                                </div>
                              </div>
                            )}
                            <form
                              action="javascript:void(0)"
                              method="post"
                              onSubmit={handsubmitcategory}
                            >
                              <div className="d-flex flex-column text-center gap-3 rel p-4 drop-box-pop mx-auto ">
                                <i className="fal fa-arrow-alt-up"></i>
                                <h1>Drag and drop your files here to upload</h1>
                                <h6>Browse files...</h6>
                                <input
                                  type="file"
                                  multiple
                                  onChange={docfile}
                                  ref={inputFileRef}
                                  className="file-name-block"
                                />
                              </div>

                              {docs_s && (
                                <span class="text-success">
                                  Document has been successfully saved
                                </span>
                              )}
                              <div className="d-flex flex-column gap-3 drag-box">
                                <div className="d-flex gap-2 check-box-list">
                                  <div className="d-flex gap-2 align-items-center justify-content-end btns-box mt-4">
                                    <div className="flex-shrink-0">
                                      <button
                                        type="button"
                                        onClick={() => closeDoc()}
                                      >
                                        Cancel
                                      </button>
                                    </div>
                                    <div className="flex-shrink-0">
                                      <button
                                        type="submit"
                                        className={`${
                                          diss_s === true ? "sites_docs" : ""
                                        }`}
                                      >
                                        Upload
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </Modal>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};
