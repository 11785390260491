import React, { useRef, useState, useEffect } from "react";
import axios from "axios";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Header } from "../Header";
import { Footer } from "../Footer";
import { Leftmenu } from "./Leftmenu";
import "../assets/ohs/admin.css";
export const Lesson4 = () => {
  const stepTwo = useRef();
  const stepThree = useRef();
  const currentYear = new Date().getFullYear();
  const navigate = useNavigate();
  const [unicode, setunicode] = useState("");
  const [isCourse, setCourse] = useState("");
  const [istabreview, setistabreview] = useState([]);
  const [tabsectionView, settabsectionView] = useState("Tab1");

  const { id } = useParams();
  const changeFavicon = (url) => {
    const favicon = document.querySelector('link[rel="icon"]');
    if (favicon) {
      favicon.href = url;
    }
  };

  useEffect(() => {
    const userStr = JSON.parse(localStorage.getItem("users"));

    if (userStr === null) {
      window.location.href = "/login";
    }
    changeFavicon(require("../assets/admin/images/f-icon.png"));

    document.title = "Start Course Lesson 4";
    if (id === undefined) {
      setunicode("");
      window.location.href = "/login";
    } else {
      setunicode(id);
    }
    let formdata = {
      code: id,
    };
    axios
      .post("https://jlmining.app/getcourseDetail", formdata, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        console.log(res.data);
        if (res.data.row.length > 0) {
          setCourse(res.data.row[0]);
        }
      })
      .catch((err) => {});
    console.log(istabreview.length);
    if (istabreview.length === 5) {
    }
  }, [id, istabreview]);
  const tabReview = (event) => {
    if (!istabreview.includes(event)) {
      setistabreview((prevState) => [...prevState, event]);
    }
    settabsectionView(event);
  };
  const stepformlesson4 = (event) => {
    var lsteps = "Lesson_Step4";
    localStorage.setItem("LessonStep", JSON.stringify(lsteps));
    navigate(`/lesson5/${isCourse.slug}/${unicode}`);
  };
  return (
    <>
      <Header />
      <div className="d-flex main-admin-block align-items-stretch">
        <Leftmenu />
        <div className="flex-grow-1 rightpart">
          <section id="safety" className="Safety w-100">
            <div className="d-flex flex-column">
              <Link
                to="#hum"
                className="backpage text-center d-flex flex-column"
              >
                <i className="far fa-angle-up"></i>
                <h5>Lesson 3 Human Aspects</h5>
              </Link>
            </div>
            <div className="container-xxl px-0">
              <div className="row">
                <div className="col-md-10 offset-md-1">
                  <div className="d-flex flex-column gap-3 padding py-5 lesson-block intro">
                    <h4>Lesson 4 of 9</h4>
                    <h1>Safety Signs</h1>
                    <div className="line"></div>
                  </div>
                </div>
                <div className="col-12">
                  <div className="border-line">
                    <div className="line-per"></div>
                  </div>
                </div>
                <div className="col-md-10 offset-md-1 padding">
                  <div className="d-flex flex-column gap-4 py-3 py-md-3">
                    <p>
                      Health and safety regulations mandate the use of safety
                      signs by PCBUs in specific situations. Consequently,
                      safety signs can complement other control measures
                      effectively.
                    </p>
                    <p>
                      When utilizing signs, regulations dictate adherence to a
                      standardized convention regarding shape and color. This
                      standardization aims to enhance comprehensibility and
                      align with international norms.
                    </p>
                  </div>

                  <h2>
                    Please browse the following tabs to familiarize yourself
                    with the common types of signage currently employed:
                  </h2>
                  <div className="py-3 d-flex justify-content-center align-items-center bg-light">
                    <div
                      className="card card-1 p-0 shadow"
                      style={{ maxWidth: "100%" }}
                    >
                      <nav>
                        <div
                          className="nav nav-tabs mb-3 nav-fill"
                          id="nav-tab1"
                          role="tablist"
                        >
                          <button
                            className="nav-link active"
                            id="nav-home-tab1"
                            data-bs-toggle="tab"
                            data-bs-target="#nav-home1"
                            type="button"
                            role="tab"
                            aria-controls="nav-home"
                            aria-selected="true"
                            onClick={() => tabReview("Tab1")}
                          >
                            BLUE <br />( MANDATORY )
                          </button>
                          <button
                            className="nav-link"
                            id="nav-profile-tab2"
                            data-bs-toggle="tab"
                            data-bs-target="#nav-profile2"
                            type="button"
                            role="tab"
                            aria-controls="nav-profile"
                            aria-selected="false"
                            onClick={() => tabReview("Tab2")}
                          >
                            RED <br />
                            (PROHIBITIVE)
                          </button>
                          <button
                            className="nav-link"
                            id="nav-contact-tab3"
                            data-bs-toggle="tab"
                            data-bs-target="#nav-contact3"
                            type="button"
                            role="tab"
                            aria-controls="nav-contact"
                            aria-selected="false"
                            onClick={() => tabReview("Tab3")}
                          >
                            YELLOW <br />
                            (HAZARD)
                          </button>
                          <button
                            className="nav-link"
                            id="nav-green-tab4"
                            data-bs-toggle="tab"
                            data-bs-target="#nav-green4"
                            type="button"
                            role="tab"
                            aria-controls="nav-green4"
                            aria-selected="false"
                            onClick={() => tabReview("Tab4")}
                          >
                            GREEN <br />
                            (SAFE CONDITION)
                          </button>
                          <button
                            className="nav-link"
                            id="nav-red-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#nav-red"
                            type="button"
                            role="tab"
                            aria-controls="nav-red"
                            aria-selected="false"
                            onClick={() => tabReview("Tab5")}
                          >
                            Red <br />
                            (FIRE)
                          </button>
                        </div>
                      </nav>
                      <div
                        className="tab-content p-4 bg-light"
                        id="nav-tabContent"
                      >
                        <div
                          className={`tab-pane fade ${
                            tabsectionView === "Tab1" ? "active show" : ""
                          } `}
                          id="nav-home1"
                          role="tabpanel"
                          aria-labelledby="nav-home-tab1"
                        >
                          <p>
                            Mandatory signs feature this color to signify a
                            compulsory action or instruction, such as wearing a
                            hard hat or ear protection in designated areas. They
                            are typically circular in shape.
                          </p>
                          <div className="acc-img1 py-3">
                            <img
                              className="w-100"
                              src={require("../assets/ohs/images/ACC2.png")}
                              alt="img"
                            />
                          </div>
                        </div>
                        <div
                          className={`tab-pane fade ${
                            tabsectionView === "Tab2" ? "active show" : ""
                          } `}
                          id="nav-profile2"
                          role="tabpanel"
                          aria-labelledby="nav-profile-tab2"
                        >
                          <p>
                            The use of this color indicates a prohibition,
                            displaying actions that are not allowed, such as
                            stop signs and no smoking signs. These signs are
                            presented as a circle with a diagonal line.
                            Emergency stop devices are also colored red to
                            indicate the means of stopping a process or
                            equipment.
                          </p>
                          <div className="acc-img1 py-3">
                            <img
                              className="w-100"
                              src={require("../assets/ohs/images/red.png")}
                              alt="img"
                            />
                          </div>
                        </div>
                        <div
                          className={`tab-pane fade ${
                            tabsectionView === "Tab3" ? "active show" : ""
                          } `}
                          id="nav-contact3"
                          role="tabpanel"
                          aria-labelledby="nav-contact-tab3"
                        >
                          <p>
                            This color serves as a warning, indicating potential
                            danger or risk. It is utilized to highlight hazards
                            like fire, explosion, chemicals, or areas where
                            caution should be exercised to avoid potential
                            danger, such as obstacles or hazardous passages.
                            Typically, these signs feature an equilateral
                            triangle with rounded corners, although they may
                            also be rectangular in shape.
                          </p>
                          <div className="acc-img1 py-3">
                            <img
                              className="w-100"
                              src={require("../assets/ohs/images/yellow.png")}
                              alt="img"
                            />
                          </div>
                        </div>
                        <div
                          className={`tab-pane fade ${
                            tabsectionView === "Tab4" ? "active show" : ""
                          } `}
                          id="nav-green4"
                          role="tabpanel"
                          aria-labelledby="nav-green-tab4"
                        >
                          <p>
                            This color signifies a safe condition, indicating
                            features like emergency exits and the availability
                            of first aid. These signs are typically square or
                            rectangular in shape.
                          </p>
                          <div className="acc-img1 py-3">
                            <img
                              className="w-100"
                              src={require("../assets/ohs/images/green.png")}
                              alt="img"
                            />
                          </div>
                        </div>
                        <div
                          className={`tab-pane fade ${
                            tabsectionView === "Tab5" ? "active show" : ""
                          } `}
                          id="nav-red"
                          role="tabpanel"
                          aria-labelledby="nav-red-tab"
                        >
                          <p>
                            Red is also employed to indicate fire-fighting
                            equipment, including extinguishers, and to denote
                            the location of such equipment. These signs are
                            presented in either a square or rectangle shape.
                          </p>
                          <div className="acc-img1 py-3">
                            <img
                              className="w-100"
                              src={require("../assets/ohs/images/red1.png")}
                              alt="img"
                            />
                          </div>
                        </div>
                      </div>
                      <div
                        id="conti-btn"
                        onClick={() => stepformlesson4("Step4")}
                        className="d-flex py-4"
                      >
                        <button>CONTINUE</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
      <Footer />
    </>
  );
};
