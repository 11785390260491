import React, { useRef, useState, useEffect } from "react";
import axios from "axios";
import { message, Form, Input, Button, Alert } from "antd";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Header } from "../Header";
import { Footer } from "../Footer";
import { Documents } from "./Documents";
import { Documentsnotes } from "./Documentsnotes";
import moment from "moment-timezone";
import Modal from "react-modal";
import Select from "react-select";
import "../assets/ohs/newstyle.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
export const Victim = () => {
  const currentYear = new Date().getFullYear();
  const navigate = useNavigate();
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [timezones, setTimezones] = useState([]);
  const [activeTab, setActiveTab] = useState("home-tab");
  const [activeTabMenu, setactiveTabMenu] = useState("details");
  const [activetab1, setactivetab1] = useState(true);
  const [activetab2, setactivetab2] = useState(false);
  const [isDisabledstep2, setisDisabledstep2] = useState(false);
  const [isDisabledtomenu, setisDisabledtomenu] = useState(false);
  const [activetab3, setactivetab3] = useState(false);
  const [activetab4, setactivetab4] = useState(false);
  const [activecounrty, setactivecounrty] = useState(true);
  const [isDisabled, setIsDisabled] = useState(false);
  const [perdetailstep2, setperdetailstep2] = useState(false);
  const [iscountryChecked, setIscountryChecked] = useState("au");
  const [isyesNoChecked, setyesNoChecked] = useState("Yes");
  const [isStateChecked, setStateChecked] = useState("");
  const [isSet3employee, setisSet3employee] = useState("");
  const [isstep2employee, setisstep2employee] = useState("Employee");
  const [persondetail, setpersondetail] = useState("");
  const [isreferences, setreferences] = useState("");
  const [isstep2_persondetail, setisstep2_persondetail] = useState("Yes");
  const [iswasChecked, setiswasChecked] = useState("No");
  const [iswasfirst, setwasfirst] = useState(false);
  const [iswhereotherChecked, setiswhereotherChecked] = useState("No");
  const [iswhereother, setwhereother] = useState(false);
  const [uniquecode, setuniquecode] = useState("");

  const [iswitnessChecked, setiswitnessChecked] = useState("No");
  const [iswitness, setwitness] = useState(false);
  const [countries, setCountries] = useState([]);
  const [isetcounty, setisetcounty] = useState("");
  const [isetcountystep3, setisetcountystep3] = useState("");
  const [getincidentData, setgetincidentData] = useState([]);
  const [isincident_safeChecked, setisincident_safeChecked] = useState("No");
  const [isincidentafe, setisincidentafe] = useState(false);
  //Start
  const [filemain, setfilemain] = useState("");
  const [opacit, setopacit] = useState(true);
  const [dis, setdis] = useState(true);
  const inputFileRef = useRef(null);
  const [msgsuccess, setmsgsuccess] = useState(false);
  const [fileshow, setfilename] = useState("");
  const [documentupload, setdocumentupload] = useState("document-tab");
  const [isusernameall, setusernameall] = useState("");
  const [isalldocs, setalldocs] = useState([]);
  const [isalldocsl, setalldocsl] = useState(0);
  const [openDropdownIndex, setOpenDropdownIndex] = useState(null);
  const [openDropdownIndexedit, setOpenDropdownIndexedit] = useState(null);
  const [textedit, settextedit] = useState(null);
  const [remainingChars, setRemainingChars] = useState(1000);
  const [getallnotes, setgetallnotes] = useState([]);
  const [getallnotesl, setgetallnotesl] = useState([]);
  const [remainingCharsedit, setRemainingCharsedit] = useState(
    Array(getallnotes.length).fill(1000)
  );

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpenRole, setIsModalOpenRole] = useState(false);
  const [isusername, setusername] = useState("");
  //End
  const changeFavicon = (url) => {
    const favicon = document.querySelector('link[rel="icon"]');
    if (favicon) {
      favicon.href = url;
    }
  };
  const { id } = useParams();

  useEffect(() => {
    const userStr = JSON.parse(localStorage.getItem("users"));
    if (userStr === null) {
      window.location.href = "/login";
    }
    document.title = "Victim Page";
    if (id === undefined) {
      setuniquecode("");
    } else {
      setuniquecode(id);
    }

    axios
      .get("https://jlmining.app/country", {
        headers: {
          
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        const options = res.data.results.map((country) => ({
          value: country.country_name,
          label: country.country_name,
        }));
        setCountries(options);
      })
      .catch((err) => {});
    const australiaTimezones = moment.tz
      .names()
      .filter((zone) => zone.startsWith("Australia/"))
      .map((zone) => ({ value: zone, label: zone }));
    setTimezones(australiaTimezones);
    let formdata = {
      user_id: userStr[0].id,
      code: id,
    };
    axios
      .post("https://jlmining.app/getincidentdata", formdata, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "POST,PUT",
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        if (res.data.row.length > 0) {
          var indata = res.data.row[0];
          if (indata.status === "Closed") {
            const newUrl = "/incident/";
            navigate(newUrl);
          }
          setgetincidentData(indata);
          setisstep2employee(indata.employee_status);
          setpersondetail(indata.full_name);
          if (indata.employee_status === "Non-Employee") {
            setperdetailstep2(true);
            setisstep2_persondetail(indata.personal_details);
          }
          setSelectedCountry({
            value: indata.step2_country,
            label: indata.step2_country,
          });
          setisetcounty({
            value: indata.step2_country,
            label: indata.step2_country,
          });
        }
      })
      .catch((err) => {});
    axios
      .post("https://jlmining.app/getincidentDoc", formdata, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "POST,PUT",
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        setalldocsl(res.data.results.length);
        setalldocs(res.data.results);
      })
      .catch((err) => {});

    axios
      .post("https://jlmining.app/admin/getuserdetails", formdata, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "POST,PUT",
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        var alldata = res.data.row[0];

        if (alldata !== null) {
          var fset = alldata.first_name ? alldata.first_name.charAt(0) : "";
          var mset = alldata.middle_name ? alldata.middle_name.charAt(0) : "";
          var lset = alldata.last_name ? alldata.last_name.charAt(0) : "";
          var finalname = fset + mset + lset;
          setusername(finalname);
          var fsett = alldata.first_name;
          var msett = alldata.middle_name;
          var lsett = alldata.last_name;
          var fn = fsett + " " + msett + " " + lsett;
          setusernameall(fn);
        }
      })
      .catch((err) => {});

    axios
      .post("https://jlmining.app/getincident_notes", formdata, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "POST,PUT",
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        setgetallnotes(res.data.results);
        setgetallnotesl(res.data.results.length);
      })
      .catch((err) => {});
  }, [id]);
  console.log(uniquecode);
  const step2Country = (event) => {
    if (event !== "") {
      setisetcounty({
        value: event.value,
        label: event.value,
      });
    }
  };
  const step3Country = (event) => {
    if (event !== "") {
      setisetcountystep3(event.value);
    }
  };
  const handinjured_check = (event) => {
    if (event === "Yes") {
      setyesNoChecked("Yes");
      setIsDisabled(false);
    } else {
      setyesNoChecked("No");
      setIsDisabled(true);
    }
    console.log(event);
  };
  const handcountry = (event) => {
    if (event === "au") {
      setIscountryChecked("au");
      setactivecounrty(true);
    } else {
      setIscountryChecked("nz");
      setactivecounrty(false);
    }
  };
  const handstate = (event) => {
    setStateChecked(event);
  };
  const handstep2employee = (event) => {
    setisstep2employee(event);
    setisDisabledstep2(false);
    if (event !== "Employee") {
      setperdetailstep2(true);
    } else {
      setpersondetail("");
      setisstep2_persondetail("Yes");
      setperdetailstep2(false);
    }
  };
  const handstep2persondetail = (event) => {
    setisstep2_persondetail(event);
    setpersondetail("");
    if (event === "Yes") {
      setisDisabledstep2(false);
      setpersondetail("");
    } else {
      setisDisabledstep2(true);
      setpersondetail(event);
    }
  };
  const backbuttonStep2 = (event) => {
    setactivetab1(true);
    setactivetab2(false);
    setactiveTabMenu("details");
  };

  const step2fullname = (e) => {
    console.log(persondetail);
    console.log(e.target.value);
    setpersondetail(e.target.value);
  };
  const handmenu_check = (e) => {
    if (uniquecode !== undefined || uniquecode !== "") {
      if (e === "details") {
        const newUrl = "/incident/detail/" + uniquecode;
        navigate(newUrl);
      }
    }
  };

  const handbuttonStep2 = (event) => {
    console.log(isstep2employee);
    if (isstep2employee !== null) {
      setactiveTabMenu("reporter");
      setactivetab3(true);
      setactivetab2(false);

      var vll = event.target;
      var inju = "Yes";

      const userStr = JSON.parse(localStorage.getItem("users"));
      if (isstep2employee !== "Employee") {
        if (vll.personal_details !== "") {
          if (vll.personal_details.value === "on") {
            var inju = "Yes";
          } else {
            var inju = isstep2_persondetail;
          }
        }
      }

      let formdata = {
        check_code: uniquecode,
        user_id: userStr[0].id,
        full_name: vll.full_name.value,
        occupation: vll.occupation.value,
        address1: vll.address1.value,
        address2: vll.address2.value,
        town_city: vll.town_city.value,
        state_step2: vll.state_step2.value,
        step2_country: isetcounty.value,
        postcode_step2: vll.postcode_step2.value,
        phone_number_step2: vll.phone_number_step2.value,
        email_step2: vll.email_step2.value,
        employee_status: isstep2employee,
        personal_details: inju,
      };
      axios
        .post("https://jlmining.app/incident_step2", formdata, {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "POST,PUT",
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          console.log(res.data.code);
          if (res.data.code !== "") {
            var cod = res.data.code;
            setuniquecode(cod);
            const newUrl = "/incident/reporter/" + cod;

            // Use history.push to navigate to the new URL
            navigate(newUrl);
          }
        })
        .catch((err) => {});
    }
  };
  //start
  const docfile = (event) => {
    const file = event.target.files[0];
    const file_Name = file.name;
    if (file_Name != "") {
      setfilename(file_Name);
      setfilemain(file);
      setdis(false);
      setopacit(false);
    } else {
      setfilemain("");
      setopacit(true);
      setdis(true);
    }
  };
  const removefile = (event) => {
    setfilename("");
    setopacit(true);
    setdis(true);
    if (inputFileRef.current) {
      inputFileRef.current.value = "";
    }
  };
  const handlesubmitdoc = (event) => {
    var fr = event.target;
    const formData = new FormData();
    const userStr = JSON.parse(localStorage.getItem("users"));
    if (filemain !== "") {
      formData.append("code", uniquecode);
      formData.append("user_id", userStr[0].id);
      formData.append("file", filemain);
      axios
        .post("https://jlmining.app/incidentDocs", formData, {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "POST,PUT",
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          setalldocs(res.data.results);
          setalldocsl(res.data.results.length);
          setmsgsuccess(true);
          setTimeout(() => {
            setIsModalOpen(false);
            setfilename("");
            setopacit(true);
            setdis(true);
            if (inputFileRef.current) {
              inputFileRef.current.value = "";
            }
            setmsgsuccess(false);
          }, 3500);
        })
        .catch((err) => {});
    }
  };
  const handincidentNotes = (event) => {
    var vll = event.target;
    const userStr = JSON.parse(localStorage.getItem("users"));
    let formdata = {
      code: uniquecode,
      user_id: userStr[0].id,
      notes: vll.notes.value,
    };
    axios
      .post("https://jlmining.app/incident_notes", formdata, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "POST,PUT",
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        setgetallnotes(res.data.results);
        setgetallnotesl(res.data.results.length);
        setRemainingChars("1000");
        event.target.reset();
      })
      .catch((err) => {});
  };
  const docupload = () => {
    setIsModalOpen(true);
  };
  const docclose = () => {
    setIsModalOpen(false);
  };
  const handdocument = (event) => {
    setdocumentupload(event);
  };
  function formatDate(dateString) {
    const options = { year: "numeric", month: "short", day: "numeric" };
    const date = new Date(dateString);
    return date.toLocaleDateString("en-US", options);
  }
  const removedocs = (event) => {
    const userStr = JSON.parse(localStorage.getItem("users"));
    let rem = {
      id: event,
      user_id: userStr[0].id,
      code: uniquecode,
    };
    axios
      .post("https://jlmining.app/incidentremove", rem, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "POST,PUT",
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        setalldocs(res.data.results);
        setalldocsl(res.data.results.length);
      })
      .catch((err) => {});
  };
  function formatDate_notes(dateString) {
    const options = {
      month: "long",
      day: "numeric",
      year: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };
    const date = new Date(dateString);
    return date.toLocaleDateString("en-US", options);
  }
  const notestext = (event) => {
    const inputText = event.target.value;
    const remaining = 1000 - inputText.length;
    console.log(remaining);
    if (remaining > 0) {
      setRemainingChars(remaining);
    }
  };
  const notestextedit = (index, vl) => {
    const inputText = vl;
    const remaining = 1000 - inputText.length;
    setEditedNote(vl);
    if (remaining > 0) {
      setRemainingCharsedit(remaining);
    }
    settextedit(index === textedit ? null : index);
  };
  const removenotes = (event, index) => {
    const userStr = JSON.parse(localStorage.getItem("users"));
    let fdata = {
      id: event,
      user_id: userStr[0].id,
      code: uniquecode,
    };
    axios
      .post("https://jlmining.app/incidentnotesremove", fdata, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "POST,PUT",
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        console.log(res.data.results);

        setOpenDropdownIndexedit(index === openDropdownIndexedit ? null : null);
        setOpenDropdownIndex(index === openDropdownIndex ? null : null);
        setTimeout(() => {
          setgetallnotes(res.data.results);
          setgetallnotesl(res.data.results.length);
        }, 500);
      })
      .catch((err) => {});
  };
  const notesedit = (index) => {
    setOpenDropdownIndex(index === openDropdownIndex ? null : index);
  };
  const editnotes = (index) => {
    setOpenDropdownIndexedit(index === openDropdownIndexedit ? null : index);
  };
  const [editedNote, setEditedNote] = useState("");
  const handincidentNotesedit = (id, index) => {
    console.log(id);
    if (editedNote != "") {
      const userStr = JSON.parse(localStorage.getItem("users"));
      let fdata = {
        id: id,
        notes: editedNote,
        user_id: userStr[0].id,
        code: uniquecode,
      };
      axios
        .post("https://jlmining.app/incidentnotesedit", fdata, {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "POST,PUT",
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          console.log(res.data.results);

          setOpenDropdownIndexedit(
            index === openDropdownIndexedit ? null : null
          );
          setOpenDropdownIndex(index === openDropdownIndex ? null : null);
          setTimeout(() => {
            setgetallnotes(res.data.results);
            setgetallnotesl(res.data.results.length);
          }, 500);
        })
        .catch((err) => {});
    }
    console.log(editedNote);
  };
  const getvalue = (event) => {
    console.log(event.target.value);
    const userStr = JSON.parse(localStorage.getItem("users"));
    let fdata = {
      desc: event.target.value,
      user_id: userStr[0].id,
      code: uniquecode,
    };
    axios
      .post("https://jlmining.app/getincident_notesdesc", fdata, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "POST,PUT",
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        setgetallnotes(res.data.results);
        setgetallnotesl(res.data.results.length);
      })
      .catch((err) => {});
  };
  //End
  return (
    <>
      <Header />
      <section className="d-block py-5">
        <div className="container-lg">
          <div className="row gy-4">
            <div className="col-md-8">
              {/* <Documents /> */}
              <div className="d-block">
                <div className="row gy-3 mb-4">
                  <div className="col-12 hazard-form-block">
                    <ul className="brow-nav">
                      <li>
                        <Link
                          to="javascript:void(0)"
                          onClick={() => handmenu_check("details")}
                        >
                          Details
                        </Link>
                      </li>
                      <li className="active">
                        <Link
                          to="javascript:void(0)"
                          onClick={() => handmenu_check("injuredperson")}
                        >
                          Injured person
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="javascript:void(0)"
                          onClick={() => handmenu_check("reporter")}
                        >
                          Reporter
                        </Link>
                      </li>
                      <li>
                        <Link
                          disabled={isDisabledtomenu}
                          to="javascript:void(0)"
                          onClick={() => handmenu_check("about")}
                        >
                          About
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <form
                action="javascript:void(0)"
                onSubmit={handbuttonStep2}
                method="post"
              >
                <div className="row gy-3">
                  <div className="col-12 hazard-form-block">
                    <div className="row gy-4">
                      <div className="col-12">
                        <div className="d-flex flex-column gap-2">
                          <h1 className="h-title">Who had the incident?</h1>
                        </div>
                      </div>

                      <div className="col-12">
                        <div className="row gy-4">
                          <div className="col-12">
                            <div className="d-flex flex-column gap-2 rel ">
                              <label for="">
                                Who was involved in the incident?{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <div className="d-flex flex-wrap gap-4 align-items-center">
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    required
                                    name="employee_status"
                                    id="non1"
                                    onClick={() =>
                                      handstep2employee("Employee")
                                    }
                                    checked={isstep2employee === "Employee"}
                                  />
                                  <label
                                    className="form-check-label rabutton"
                                    for="non1"
                                  >
                                    Employee
                                  </label>
                                </div>
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="employee_status"
                                    onClick={() =>
                                      handstep2employee("Non-Employee")
                                    }
                                    checked={isstep2employee === "Non-Employee"}
                                    id="non2"
                                  />
                                  <label
                                    className="form-check-label rabutton"
                                    for="non2"
                                  >
                                    Non-Employee
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                          {perdetailstep2 && (
                            <div className="col-12">
                              <div className="d-flex flex-column gap-2 rel ">
                                <label for="">
                                  Do you know the personal details?
                                </label>
                                <div className="d-flex flex-wrap gap-4 align-items-center">
                                  <div className="form-check">
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      name="personal_details"
                                      id="per1"
                                      onClick={() =>
                                        handstep2persondetail("Yes")
                                      }
                                      checked={isstep2_persondetail === "Yes"}
                                    />
                                    <label
                                      className="form-check-label rabutton"
                                      for="per1"
                                    >
                                      Yes
                                    </label>
                                  </div>
                                  <div className="form-check">
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      name="personal_details"
                                      onClick={() =>
                                        handstep2persondetail(
                                          "Not known at present"
                                        )
                                      }
                                      checked={
                                        isstep2_persondetail ===
                                        "Not known at present"
                                      }
                                      id="per2"
                                    />
                                    <label
                                      className="form-check-label rabutton"
                                      for="per2"
                                    >
                                      Not known at present
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                          <div className="col-12">
                            <h5 className="in-title">Person Details</h5>
                          </div>

                          <div className="col-md-6">
                            <div className="d-flex flex-column gap-2 rel search-icon">
                              <label for="">
                                Full name
                                <span className="text-danger">*</span>
                              </label>
                              <input
                                type="text"
                                required
                                disabled={isDisabledstep2}
                                onInput={step2fullname}
                                value={persondetail}
                                name="full_name"
                                placeholder=""
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="d-flex flex-column gap-2">
                              <label for="">Occupation</label>
                              <input
                                type="text"
                                name="occupation"
                                placeholder=""
                                defaultValue={getincidentData.occupation}
                              />
                            </div>
                          </div>
                          <div className="col-12">
                            <h5 className="in-title">Address</h5>
                          </div>
                          <div className="col-md-6">
                            <div className="d-flex flex-column gap-2">
                              <label for="">Address 1 </label>
                              <input
                                type="text"
                                name="address1"
                                placeholder=""
                                defaultValue={getincidentData.address1}
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="d-flex flex-column gap-2">
                              <label for="">Address 2 </label>
                              <input
                                type="text"
                                name="address2"
                                placeholder=""
                                defaultValue={getincidentData.address2}
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="d-flex flex-column gap-2">
                              <label for="">Town/ City </label>
                              <input
                                type="text"
                                name="town_city"
                                placeholder=""
                                defaultValue={getincidentData.town_city}
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="d-flex flex-column gap-2">
                              <label for="">State/ Territory </label>
                              <input
                                type="text"
                                name="state_step2"
                                placeholder=""
                                defaultValue={getincidentData.state_step2}
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="d-flex flex-column gap-2">
                              <label for="">Country </label>
                              {/* <input type="text" placeholder="" /> */}
                              <Select
                                options={countries}
                                value={isetcounty}
                                onChange={step2Country}
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="d-flex flex-column gap-2">
                              <label for="">Postcode </label>
                              <input
                                type="text"
                                name="postcode_step2"
                                placeholder=""
                                defaultValue={getincidentData.postcode_step2}
                              />
                            </div>
                          </div>
                          <div className="col-12">
                            <h5 className="in-title">Contact Details</h5>
                          </div>
                          <div className="col-md-6">
                            <div className="d-flex flex-column gap-2">
                              <label for="">Telephone number </label>
                              <input
                                type="tel"
                                name="phone_number_step2"
                                placeholder=""
                                pattern="[0-9]*"
                                minlength="10"
                                defaultValue={
                                  getincidentData.phone_number_step2
                                }
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="d-flex flex-column gap-2">
                              <label for="">Email </label>
                              <input
                                type="email"
                                name="email_step2"
                                placeholder=""
                                defaultValue={getincidentData.email_step2}
                              />
                            </div>
                          </div>
                          <div className="col-12">
                            <div className="d-flex gap-2 align-items-center buttons-two">
                              <Link
                                to={`/incident/detail/${uniquecode}`}
                                className="cel-btn"
                              >
                                Back
                              </Link>
                              <button type="submit" className="rep-btn">
                                Save and Continue
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div className="col-md-4">
              <div className="d-flex flex-column gap-3">
                <div className="d-flex flex-column need-block border border-secondary border-opacity-25 rounded-2 overflow-hidden">
                  <div className="d-block fulw">
                    <img
                      src={require("../assets/ohs/images/need.png")}
                      alt="image"
                    />
                  </div>
                  <div className="d-block px-3 pb-3">
                    <p>
                      Need help with the hazard report process? Call the Health
                      & Safety Advice Line on
                      <Link to="tel:1300 029 198">
                        0431 619 015,0406 400 994
                      </Link>
                    </p>
                  </div>
                </div>
                {/* <Documentsnotes /> */}
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};
